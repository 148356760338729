import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-typeahead-select',
	template:
		`
         <ng-select
           [ngStyle]="{'width': params.column.actualWidth + 'px', 'line-height': '20px'}"
           id="typeahead-cell"
           class=""
           [items]="params.values"
           [clearable]="false"
           placeholder="Select an Option"
           [virtualScroll]="params.values.length > 100"
           [(ngModel)]="params.value"
           (ngModelChange)="handleChangedValue($event)"
           (close)="onValueSelected()">
         </ng-select>
       `,
	styles: [
		`
         ng-select.ng-select {
           position: fixed;
         }
       `
	]
})
export class TypeaheadSelectRendererComponent implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
		this.params.newValue = '';
	}

	public handleChangedValue(event: string) {
		this.params.newValue = event;
		this.params.context.componentParent.onCellValueChanged(this.params);
	}

	onValueSelected() {
		this.params.context.componentParent.agGridApi.stopEditing();
		this.params.node.setDataValue(this.params.colDef.field, this.params.newValue);
	}

	refresh(): boolean {
		return false;
	}
}
