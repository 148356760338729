export class ShareLink {
	id: number;
	sharedBy: string;
	sharedTo: string;
	sharedOn: Date;
	sharedUntil: Date;
	permissions: string;
	type: string;
	resourceId: number;
	accessCode: string;
	token: string;
	hasAccessCode: boolean;
}
