import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import HelloSign from 'hellosign-embedded';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SessionStorage } from 'ngx-store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommentObject } from '../../../models/comment.model';
import { AuthService } from '../../../services/auth.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { MFilesService } from '../../../services/mfiles.service';
import { PageService } from '../../../services/page.service';

declare var $: any;

@Component({
	selector: 'app-widget-viewer',
	templateUrl: './viewer.component.html',
	styleUrls: ['./viewer.component.css']
})
export class WidgetViewerComponent implements OnInit, OnDestroy, OnChanges {
	modalRef: BsModalRef;
	@ViewChild('workflowCommentDialog') private workflowCommentDialog: TemplateRef<any>;
	@ViewChild('annotationModal') private annotationModal: TemplateRef<any>;

	@Input() pageAction: string;
	@Input() widgetEditMode = false;
	@Input() widgetConfigureMode = false;
	@Input() widgetData: any;
	@Input() widgetSettings: any;
	@Input() editSaved = false;
	@Input() configureSaved = false;
	@Input() pageId: number;
	@Input() widgetId: string;

	@Output() updateData = new EventEmitter<string>();
	@Output() updateSettings = new EventEmitter<string>();
	@Output() updateDraft = new EventEmitter<any>();
	@Output() changesOccurred = new EventEmitter();
	@Output() changesReverted = new EventEmitter();

	documentSelectionSubscription: any;

	widgetDataRevert: any;
	widgetSettingsRevert: any;

	@SessionStorage('viewer') viewer;
	viewerEnabled = false;
	viewerProxy = '';
	enableAnnotations = false;
	annotateButtonLabel = 'Annotate';
	saveAnnotationsButtonLabel = 'Save Annotations';
	allowRedactions = false;
	prizmviewer: any;
	annotationviewer: any;
	xViewSessionId: any;
	query: string;
	properties = [];
	class = -1;
	workflowInfo: any;
	extension: string;

	loading = false;

	displayMetadata = false;
	displayComments = false;
	commentsEnabled = false;

	comments: CommentObject[];
	commentsCount = 0;
	loadingComments = false;

	helloSignEnabled = false;
	helloSignApiKey = '';
	helloSignMessage = '';
	helloSignRejectLabel = '';
	helloSignSignLabel = '';
	helloSignSignSuccess = '';

	rejecting = false;
	signing = false;
	helloSignRedactReject = false;
	helloSignRedactSign = false;
	helloSignHideRedactClasses = [];

	helloSignLoaded = false;
	docTitle = '';

	draftSubscription: Subscription;

	item: number;
	revision: number;
	type: number;
	fileIndex = 0;

	objectTypeIcons = {};
	useMfilesIcons = true;

	canAnnotate = false;

	streamingVideoURL = '';

	embedable = false;
	downloadedUrl: SafeUrl;

	workflowValueListOptions = [];
	workflowLoading = false;
	targetState: number;
	workflowComment = '';

	rejectComment = '';

	configTab = 'config';

	workspace = 1;

	viewError: boolean = false;
	errorMessage: String;

	constructor(
		private modalService: BsModalService,
		private pageService: PageService,
		private mfilesService: MFilesService,
		public configService: ConfigurationService,
		private zone: NgZone,
		private toastr: ToastrService,
		private domSanitizer: DomSanitizer,
		public authService: AuthService
	) { }

	ngOnInit() {
		this.workspace = parseInt(this.authService.getToken('workspace'));

		if (this.widgetSettings) {
			if (this.widgetSettings.displayViewer == null) {
				this.widgetSettings.displayViewer = true;
			}

			if (typeof this.widgetSettings === 'string') {
				this.widgetSettings = JSON.parse(this.widgetSettings);
			}
		} else {
			this.widgetSettings = {};
		}
		this.widgetSettingsRevert = JSON.parse(JSON.stringify(this.widgetSettings));

		this.configService.list(false).subscribe((result) => {
			for (const config of result) {
				if (config.name === 'viewerEnabled') {
					this.viewerEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'viewerProxy') {
					this.viewerProxy = config.value;
				} else if (config.name === 'enableAnnotations') {
					this.enableAnnotations = config.value.toLowerCase() === 'true';
				} else if (config.name === 'annotateButtonLabel') {
					this.annotateButtonLabel = config.value !== null ? config.value : '';
				} else if (config.name === 'saveAnnotationsButtonLabel') {
					this.saveAnnotationsButtonLabel = config.value !== null ? config.value : '';
				} else if (config.name === 'allowRedactions') {
					this.allowRedactions = config.value.toLowerCase() === 'true';
				} else if (config.name === 'commentsEnabled') {
					this.commentsEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'helloSignEnabled') {
					this.helloSignEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'helloSignApiKey') {
					this.helloSignApiKey = config.value;
				} else if (config.name === 'helloSignMessage') {
					this.helloSignMessage = config.value;
				} else if (config.name === 'helloSignRejectLabel') {
					this.helloSignRejectLabel = config.value;
				} else if (config.name === 'helloSignSignLabel') {
					this.helloSignSignLabel = config.value;
				} else if (config.name === 'helloSignSignSuccess') {
					this.helloSignSignSuccess = config.value;
				} else if (config.name === 'useMfilesIcons') {
					this.useMfilesIcons = config.value.toLowerCase() === 'true';
				} else if (config.name === 'helloSignRedactReject') {
					this.helloSignRedactReject = config.value === 'true';
				} else if (config.name === 'helloSignRedactSign') {
					this.helloSignRedactSign = config.value === 'true';
				} else if (config.name === 'helloSignHideRedactClasses') {
					this.helloSignHideRedactClasses = config.value.split(',');
					for (let i = 0; i < this.helloSignHideRedactClasses.length; i++) {
						this.helloSignHideRedactClasses[i] = parseInt(this.helloSignHideRedactClasses[i]);
					}
				}
			}

			if (this.widgetSettings.helloSignEnabled && this.helloSignEnabled) {
				this.loadHelloSign();
			}
		});

		this.mfilesService.getValueListOptions(8).subscribe((result) => { // get workflow states
			this.workflowValueListOptions = result.body.items;
		});

		this.documentSelectionSubscription = this.pageService.documentSelectionOccurred.subscribe((context: any) => {
			this.query = context.query;
			this.fileIndex = context.fileIndex;
			this.type = context.type;
			this.item = context.itemId;
			this.revision = -1;
			this.properties = [];
			this.comments = undefined;
			this.commentsCount = 0;
			this.xViewSessionId = undefined;
			this.loadRevision(this.type, this.item, -1);

			this.helloSignLoaded = false;
			if (this.widgetSettings.helloSignEnabled && this.helloSignEnabled) {
				this.loadHelloSign();
			}
		});

		this.draftSubscription = this.pageService.draftSaveOccurred.subscribe((draft: boolean) => {
			if (draft) {
				this.updateDraft.emit({ data: this.widgetData, settings: this.widgetSettings });
			}
		});

		this.setDisplayMetadata();
		this.setDisplayComments();
	}

	ngOnDestroy() {
		if (this.documentSelectionSubscription) {
			this.documentSelectionSubscription.unsubscribe();
		}
		if (this.draftSubscription) {
			this.draftSubscription.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['widgetSettings'] && typeof this.widgetSettings === 'string') {
			this.widgetSettings = JSON.parse(this.widgetSettings);
		}

		if (changes['widgetEditMode'] && !this.widgetEditMode) {
			if (this.editSaved) {
				setTimeout(() => {
					this.widgetDataChanged();
				}, 100);
			} else {
				if (this.widgetDataRevert) {
					if (this.widgetDataRevert === 'home') {
						this.widgetData = '';
					} else {
						this.widgetData = JSON.parse(JSON.stringify(this.widgetDataRevert));
					}

					setTimeout(() => {
						this.widgetDataChanged();
					}, 100);
				}
			}
		}

		if (changes['widgetConfigureMode'] && !this.widgetConfigureMode) {
			if (this.configureSaved) {
				this.widgetSettingsRevert = JSON.parse(JSON.stringify(this.widgetSettings));
				setTimeout(() => {
					this.widgetSettingsChanged();
				}, 100);
			} else {
				if (this.widgetSettingsRevert) {
					this.widgetSettings = JSON.parse(JSON.stringify(this.widgetSettingsRevert));
					setTimeout(() => {
						this.widgetSettingsChanged();
					}, 100);
				}
			}
		}
	}

	loadHelloSign() {
		if (this.item) {
			this.configService.get('viewerProxy', this.workspace).subscribe((result) => {
				this.viewerProxy = result.value;
				this.mfilesService.getHelloSignPreviewId(this.item).subscribe((result: any) => {
					if (result && result.title) {
						this.helloSignLoaded = true;
						this.docTitle = result.title;
					}
				});
			});
		}
	}

	getComments() {
		if (this.commentsEnabled && !this.comments && this.type === 0 && this.widgetSettings.displayComments) {
			this.comments = [];
			this.loadingComments = true;
			this.mfilesService.getComments(this.item).subscribe((response) => {
				this.comments = response;
				this.setCommentsCount(this.comments);
				this.loadingComments = false;
			});
		}
	}

	setCommentsCount(comments: CommentObject[]) {
		this.commentsCount = 0;
		if (comments.length > 0) {
			this.commentsCount = comments[0].commentCount;
		}
	}

	clearCommentForm(addCommentInputField: HTMLTextAreaElement) {
		addCommentInputField.value = '';
	}

	onNewComment(event: any) {
		this.loadingComments = true;

		this.mfilesService.postComment(event.id, event.parentComment, event.comment).subscribe((result) => {
			this.comments = result;
			this.setCommentsCount(this.comments);
			this.loadingComments = false;
		});
	}

	onCommentSubmit(event: any, addCommentInputField: HTMLTextAreaElement) {
		this.authService.updateLoading(true);
		this.loadingComments = true;

		this.mfilesService.postComment(this.item, null, event.target.comment.value).subscribe((result) => {
			this.comments = result;
			this.authService.updateLoading(false);
			this.setCommentsCount(this.comments);
			this.clearCommentForm(addCommentInputField);
			this.loadingComments = false;
		});

		this.toastr.success(`Comment successfully added.`);
	}

	widgetDataChanged() {
		this.updateData.emit(this.widgetData);
	}

	widgetSettingsChanged() {
		this.setDisplayMetadata();
		this.setDisplayComments();
		this.updateSettings.emit(this.widgetSettings);
	}

	initPrizmViewer(vsId: any, isAnnotation: boolean) {
		const helper = {
			documentID: vsId,
			imageHandlerUrl: this.viewerProxy,
			template: {
				viewer: require('!raw-loader!../../../file/prizmFiles/templates/viewerTemplate.html').default,
				contextMenu: require('!raw-loader!../../../file/prizmFiles/templates/contextMenuTemplate.html').default,
				printOverlay: require('!raw-loader!../../../file/prizmFiles/templates/printOverlayTemplate.html').default,
				unsavedChangesOverlay: require('!raw-loader!../../../file/prizmFiles/templates/unsavedChangesOverlayTemplate.html').default,
				overwriteOverlay: require('!raw-loader!../../../file/prizmFiles/templates/overwriteOverlayTemplate.html').default,
				print: require('!raw-loader!../../../file/prizmFiles/templates/printTemplate.html').default,
				comment: require('!raw-loader!../../../file/prizmFiles/templates/commentTemplate.html').default,
				esignOverlay: require('!raw-loader!../../../file/prizmFiles/templates/esignOverlayTemplate.html').default,
				downloadOverlay: require('!raw-loader!../../../file/prizmFiles/templates/downloadOverlayTemplate.html').default,
				imageStampOverlay: require('!raw-loader!../../../file/prizmFiles/templates/imageStampOverlayTemplate.html').default,
				hyperlinkMenu: require('!raw-loader!../../../file/prizmFiles/templates/hyperlinkMenuTemplate.html').default,
				pageRedactionOverlay: require('!raw-loader!../../../file/prizmFiles/templates/pageRedactionOverlayTemplate.html').default,
				copyOverlay: require('!raw-loader!../../../file/prizmFiles/templates/copyOverlayTemplate.html').default,
				redactionReason: require('!raw-loader!../../../file/prizmFiles/templates/redactionReasonTemplate.html').default
			},
			icons: require('!raw-loader!../../../file/prizmFiles/svg-icons.svg.html').default,
			language: require('../../../file/prizmFiles/en-US.json'),
			debug: true
		};

		this.zone.runOutsideAngular(() => {

			if (!isAnnotation && this.prizmviewer) {
				this.prizmviewer.destroy();
			}
			if (isAnnotation && this.annotationviewer) {
				this.annotationviewer.destroy();
			}

			const viewerOptions = {
				uiElements: {
					redactTab: false,
					annotateTab: isAnnotation,
					esignTab: true,
					printing: true,
					searchTab: !isAnnotation,
					viewTab: !isAnnotation,
					download: false
				},
				documentID: helper.documentID,
				template: helper.template,
				language: helper.language,
				icons: helper.icons,
				imageHandlerUrl: helper.imageHandlerUrl
			};

			if (isAnnotation) {
				this.annotationviewer = $('#annotationviewer').pccViewer(viewerOptions);
			} else {
				this.prizmviewer = $('#prizmviewer').pccViewer(viewerOptions);
			}

		});


		if (isAnnotation) {
			if (this.enableAnnotations) {
				$('.pcc-js-save-annotations').on('click', () => {
					this.saveAnnotationsHandler();
				});
				$('.pcc-js-reload-document').on('click', () => {
					this.loadRevision(this.type, this.item, -1);
				});

				this.loadAnnotations();
			}
		} else {
			this.prizmviewer.viewerControl.on('ViewerReady', () => {
				if (this.query) {
					this.zone.runOutsideAngular(() => {
						const searchTab = $('[data-pcc-nav-tab=search]');
						const tabItem = searchTab.find('.pcc-tab-item');
						const input = searchTab.find('[data-pcc-search="input"]');
						const searchButton = searchTab.find('[data-pcc-search="submit"]');
						tabItem.on('click', resetPosition(this.query));
						input.attr('disabled', 'disabled');
						tabItem.trigger('click');

						setTimeout(() => {
							searchButton.trigger('click');
						}, 100);

						function resetPosition(query: string) {
							input.val(query);
						}
					});
				}

				if (this.enableAnnotations) {
					$('.pcc-js-reload-document').on('click', () => {
						this.loadRevision(this.type, this.item, -1);
					});

					this.loadAnnotations();
				}
			});
		}
		this.loading = false;
	}

	loadAnnotations() {
		this.mfilesService.getAnnotations(this.item).subscribe((result) => {
			this.loading = false;
			if (this.prizmviewer) {
				this.prizmviewer.viewerControl.deserializeMarks([]);
				setTimeout(() => {
					this.prizmviewer.viewerControl.deserializeMarks(result);
				}, 100);
			}
			if (this.annotationviewer) {
				this.annotationviewer.viewerControl.deserializeMarks([]);
				setTimeout(() => {
					this.annotationviewer.viewerControl.deserializeMarks(result);
				}, 100);
			}
		});
	}

	saveAnnotationsHandler() {
		// Determine if there are existing annotations in the database. If there are, ensure ours are newer before overriding.
		this.mfilesService.getProperties(0, this.item, -1).subscribe((response) => {
			const latestVersion = response.body.objVer.version;
			if (latestVersion > this.revision) {
				this.toastr.warning('There is a newer version of the document. Please refresh.');
			} else {
				this.saveAnnotations();
			}
		});
	}

	saveAnnotations() {
		this.loading = true;

		if (this.enableAnnotations && this.allowRedactions && this.helloSignHideRedactClasses.indexOf(this.class) === -1 && this.hasAnnotations()) {
			this.redact();
		} else {
			// Save the annotations.
			const allMarks = this.annotationviewer.viewerControl.getAllMarks();

			if (allMarks.length > 0) {
				// Annotations found.
				this.annotationviewer.viewerControl.serializeMarks(allMarks).then(markObjects => {
					this.mfilesService.updateAnnotations(this.item, markObjects).subscribe(() => {
						this.toastr.success('The annotations were saved successfully.');
						this.loadRevision(this.type, this.item, -1);
						this.initPrizmViewer(this.xViewSessionId, false);
					});
				});
			} else {
				this.mfilesService.updateAnnotations(this.item, []).subscribe(() => {
					this.toastr.success('The annotations were saved successfully.');
					this.loadRevision(this.type, this.item, -1);
					this.initPrizmViewer(this.xViewSessionId, false);
				});
			}
		}
	}

	loadRevision(type: number, itemId: number, revision: number) {
		if (type !== undefined && itemId !== undefined && revision !== undefined) {
			this.streamingVideoURL = '';

			if (type !== 0 && (this.widgetSettings.displayMetadata || this.widgetSettings.displayWorkflow)) {
				this.displayMetadata = true;
				this.displayComments = false;
			}

			if (this.displayMetadata && !this.displayComments) {
				if (this.properties.length === 0) {
					this.loading = true;
					this.mfilesService.getProperties(type, itemId, revision).subscribe((response) => {
						this.updateProperties(response);
						this.getComments();
					});
				}
			} else if (!this.displayMetadata && this.displayComments) {
				this.getComments();
			} else if (!this.displayMetadata && !this.displayComments) {
				this.mfilesService.getProperties(type, itemId, revision).subscribe((response) => {
					this.updateProperties(response);

					// Look for streaming video url
					// FUTURE: Do this via the alias instead of looping.
					for (const property of response.body.properties) {
						if (property.name == 'Streaming Video URL') {
							if (property.value.value != null) {
								this.streamingVideoURL = property.value.value;
							}
							break;
						}
					}
					if (this.streamingVideoURL !== '') {
						this.getComments();
					} else if (this.viewerEnabled && type === 0 && !this.xViewSessionId && this.configService.unsupportedPrizmFormats.indexOf(this.extension.toLowerCase()) == -1) {
						this.loading = true;
						this.viewError = false;
						this.mfilesService.getPreviewId(itemId, revision, this.fileIndex).subscribe((result) => {
							this.xViewSessionId = result;
							this.initPrizmViewer(result, false);
							this.getComments();
						},
							err => {
								this.errorMessage = err;
								this.viewError = true;
								this.loading = false;
							}

						);
					} else if (this.viewerEnabled && (type !== 0 || this.configService.unsupportedPrizmFormats.indexOf(this.extension.toLowerCase()) > -1)) {
						this.getIcon(type);
						this.type = type;
						this.item = itemId;
						this.getComments();
					} else if (type === 0 && this.configService.emedableFormats.indexOf(this.extension.toLowerCase()) > -1) {
						this.embedable = true;
						this.downloadFileUrl(itemId, revision);
						this.getComments();
					}

				});

			}
		}
	}

	updateProperties(response: any) {
		const result: any = response.body;
		this.type = result.objVer.type;
		this.item = result.objVer.id;
		this.revision = result.objVer.version;
		this.properties = result.properties;
		this.canAnnotate = result.annotate;

		for (const prop of this.properties) {
			if (prop.propertyDef === 100) {
				this.class = prop.value.lookup.item;
			}
		}

		this.workflowInfo = {};
		if ((this.widgetSettings.displayMetadata || this.widgetSettings.displayWorkflow)) {
			for (const prop of this.properties) {
				if (prop.propertyDef === 38) {
					this.workflowInfo.name = prop.typedValue.displayValue;
					this.workflowInfo.id = prop.typedValue.lookup.item;
				} else if (prop.propertyDef === 39) {
					this.workflowInfo.stateName = prop.typedValue.displayValue;
					this.workflowInfo.stateId = prop.typedValue.lookup.item;
					this.workflowInfo.initialStateId = prop.typedValue.lookup.item;
				}
			}

			if (this.workflowInfo.id && this.workflowInfo.stateId) {
				this.workflowLoading = true;
				this.mfilesService.getWorkflowStateTransitions(this.type, this.item, this.workflowInfo.id, this.workflowInfo.stateId).subscribe((statesResult) => {
					this.workflowInfo.states = [];
					this.workflowInfo.states.push({ name: this.workflowInfo.stateName, id: this.workflowInfo.stateId, icon: this.getStateIcon(this.workflowInfo.stateId) });
					for (const state of statesResult) {
						state.icon = this.getStateIcon(state.id);
						this.workflowInfo.states.push(state);
					}
					this.workflowLoading = false;
				});
			}
		}

		if (result.files.length > 0) {
			this.extension = result.files[0].extension;
		}
		this.canAnnotate = this.canAnnotate && this.viewerEnabled && this.configService.unsupportedPrizmFormats.indexOf(this.extension?.toLowerCase()) == -1
		this.loading = false;
	}

	getStateIcon(stateId: number) {
		let icon = '';
		for (const stateOption of this.workflowValueListOptions) {
			if (stateOption.id === stateId) {
				icon = stateOption.icon;
				break;
			}
		}
		return icon;
	}

	openUpdateState(stateId: number) {
		if (typeof stateId === 'string') {
			stateId = parseInt(stateId, 10);
		}
		this.workflowComment = '';
		this.targetState = stateId;
		for (const wfState of this.workflowInfo.states) {
			if (wfState.id === stateId && wfState.placeholder) {
				this.workflowComment = wfState.placeholder;
			}
		}
		this.modalRef = this.modalService.show(
			this.workflowCommentDialog,
			{ class: 'modal-lg', backdrop: 'static', keyboard: false }
		);
	}

	cancelStateTransition() {
		this.workflowInfo.stateId = this.workflowInfo.initialStateId;
		this.modalRef.hide();
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.show(
			template,
			{ class: 'modal-' + size, backdrop: 'static' }
		);
	}

	updateState() {
		this.modalRef.hide();
		this.loading = true;
		this.properties = [];
		this.workflowInfo = {};
		this.mfilesService.updateWorkflowState(this.targetState, this.type, this.item, this.workflowComment).subscribe((result) => {
			this.workflowComment = '';
			this.targetState = undefined;
			this.loading = false;
			this.loadRevision(this.type, this.item, -1);
		});
	}

	setDisplayComments() {
		if (this.widgetSettings.displayViewer || this.widgetSettings.displayMetadata) {
			this.displayComments = false;
		} else {
			this.displayComments = true;
		}
	}

	setDisplayMetadata() {
		if (this.widgetSettings.displayViewer || this.widgetSettings.displayComments) {
			this.displayMetadata = false;
		} else {
			this.displayMetadata = true;
		}
	}

	downloadFileUrl(itemId: number, revision: number) {
		this.mfilesService.pageDownload(0, itemId, revision, this.fileIndex, this.pageId, this.widgetId).subscribe((result: any) => {
			if (result.body && result.body.size !== 0) {
				const reader = new FileReader();
				reader.onloadend = (e) => {
					this.downloadedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
				};
				reader.readAsDataURL(result.body);
			} else {
				this.downloadedUrl = undefined;
			}
		});
	}

	getIcon(type: number) {
		if (this.useMfilesIcons) {
			this.mfilesService.getObjectTypeIcon(type).subscribe((imageResult) => {
				if (imageResult.body && imageResult.body.size !== 0) {
					const reader = new FileReader();
					reader.onloadend = (e) => {
						this.objectTypeIcons[type] = this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());
					};
					reader.readAsDataURL(imageResult.body);
				} else {
					this.objectTypeIcons[type] = undefined;
				}
			});
		}
	}

	onChange() {
		if (!this.widgetSettings.displayMetadata && !this.widgetSettings.displayWorkflow && !this.widgetSettings.displayComments) {
			this.widgetSettings.displayViewer = true;
		}
		this.changesOccurred.emit();
	}

	showViewer() {
		this.displayMetadata = false;
		this.displayComments = false;
		this.loadRevision(this.type, this.item, this.revision);
	}

	showMetadata() {
		this.displayMetadata = true;
		this.displayComments = false;
		this.loadRevision(this.type, this.item, this.revision);
	}

	showComments() {
		this.displayComments = true;
		this.displayMetadata = false;
		this.loadRevision(this.type, this.item, this.revision);
	}

	reject() {
		this.modalRef.hide();
		if (this.helloSignRedactReject) {
			this.rejecting = true;
			this.redact();
		} else {
			this.doHelloSignReject();
		}
	}

	doHelloSignReject() {
		this.loading = true;
		this.showViewer();
		this.helloSignLoaded = false;
		this.mfilesService.helloSignReject(this.item, this.rejectComment).subscribe(() => {
			this.toastr.success(this.helloSignRejectLabel + ' successful');

			this.mfilesService.getProperties(0, this.item, -1).subscribe((response) => {
				this.updateProperties(response);
			});
		});
	}

	sign() {
		if (this.helloSignRedactSign) {
			this.signing = true;
			this.redact();
		} else {
			this.doHelloSignSignature();
		}
	}

	doHelloSignSignature() {
		this.loading = true;
		this.mfilesService.helloSignStart(this.item).subscribe((result) => {
			const client = new HelloSign();

			client.open(result, {
				clientId: this.helloSignApiKey,
				skipDomainVerification: true
			});

			client.on('sign', () => {
				this.toastr.success(this.helloSignSignSuccess);
				this.showViewer();
				this.helloSignLoaded = false;

				this.mfilesService.getProperties(0, this.item, -1).subscribe((response) => {
					this.updateProperties(response);
				});
			});

			this.loading = false;
		});
	}

	showViewerHeader() {
		return this.type === 0 && this.item && ((this.enableAnnotations && this.canAnnotate && !this.widgetSettings.disableAnnotations) || this.helloSignLoaded || (this.widgetSettings.displayWorkflow && this.widgetSettings.alwaysDisplayWorkflow && this.workflowInfo && this.workflowInfo.id));
	}

	hasAnnotations() {
		return this.prizmviewer && this.prizmviewer.viewerControl.getAllMarks().length > 0;
	}

	redact() {
		this.loading = true;
		// Save the annotations.
		const allMarks = this.annotationviewer.viewerControl.getAllMarks();

		if (allMarks.length > 0) {
			// Annotations found.
			this.annotationviewer.viewerControl.serializeMarks(allMarks).then(markObjects => {
				this.doRedaction(markObjects);
			});
		} else {
			this.doRedaction([]);
		}
	}

	doRedaction(annotations: any) {
		this.mfilesService.updateAnnotations(this.item, annotations).subscribe(() => {
			this.mfilesService.prizmRedaction(this.item).subscribe(() => {
				this.mfilesService.getPreviewId(this.item, -1, this.fileIndex).subscribe((result) => {
					this.xViewSessionId = result;
					this.initPrizmViewer(result, false);
				});
				if (this.rejecting) {
					this.rejecting = false;
					this.doHelloSignReject();
				} else if (this.signing) {
					this.signing = false;
					this.doHelloSignSignature();
				} else {
					this.loading = false;
				}
			});
		});
	}

	openAnnotationModal() {
		this.modalRef = this.modalService.show(
			this.annotationModal,
			{ class: 'modal-xl', backdrop: 'static', keyboard: false }
		);
		this.initPrizmViewer(this.xViewSessionId, true);
	}
}
