<nav id="navbar-container" class="main-header navbar navbar-default">
	<!-- Secondary background container -->
	<div class="navbar-container-inner">
		<!-- Logo container -->
		<div class="nav navbar-nav navbar-brand-container">
			<!-- Logo link - Not authenticated -->
			<a *ngIf="(logoUrl || siteName) && !authService.isAuthenticated()" routerLink="/login">
				<img *ngIf="logoUrl" [src]="logoUrl" alt="M-Connect" />
				<span *ngIf="siteName && displaySiteNameInHeader" class="site-name-container">
					<span *ngIf="logoUrl" class="spacer"></span>
					<span>{{siteName}}</span>
				</span>
			</a>
			<!-- Logo link - Authenticated and non-edit page mode -->
			<a *ngIf="(logoUrl || siteName) && authService.isAuthenticated() && !pageEdit" (click)="navigate('page/welcome')">
				<img *ngIf="logoUrl" [src]="logoUrl" alt="M-Connect" />
				<span *ngIf="siteName && displaySiteNameInHeader" class="site-name-container">
					<span *ngIf="logoUrl" class="spacer"></span>
					<span>{{siteName}}</span>
				</span>
			</a>
			<!-- Logo link - Authenticated and edit page mode -->
			<span *ngIf="(logoUrl || siteName) && authService.isAuthenticated() && pageEdit">
				<img *ngIf="logoUrl" [src]="logoUrl" alt="M-Connect" />
				<span *ngIf="siteName && displaySiteNameInHeader" class="site-name-container">
					<span *ngIf="logoUrl" class="spacer"></span>
					<span>{{siteName}}</span>
				</span>
			</span>
		</div>

		<!-- Page menu container - Desktop only -->
		<div *ngIf="authService.isAuthenticated()" class="page-menu-container-desktop">
			<div class="expander-button" (click)="setNavigationPanelExpanded()">
				<em class="fas fa-long-arrow-alt-left" [ngClass]="{'rotate-180': !navigationPanelExpanded, 'rotate-360': navigationPanelExpanded}"></em>
			</div>
			<ul class="nav navbar-nav pages">
				<!-- Left scroll arrow -->
				<span class="scroll-arrow-container">
					<em *ngIf="!navScrollLeft && navScrollRight" class="nav-arrows fas blank"></em>
					<em *ngIf="navScrollLeft" class="nav-arrows fas fa-chevron-left" (click)="scrollLeft()"></em>
				</span>

				<!-- Pages -->
				<div #headerItems [cdkDropListDisabled]="!pageEdit" cdkDropList cdkDropListOrientation="horizontal" class="page-list nav navbar-nav" (cdkDropListDropped)="drop($event)">
					<li class="nav-link" cdkDrag routerLinkActive="active" *ngFor="let page of pages" [cdkDragDisabled]="page.id != pageEditId" (click)="onPageSelected()">
						<!-- Normal view mode -->
						<a *ngIf="!pageEdit" [routerLink]="'/page/' + page.id">
							<div [title]="page.title"><em class="fa fa-exclamation-circle red" *ngIf="showItemIndicator && page.hasItems"></em>&nbsp;{{ page.title }}</div>
							<em *ngIf="page.settings.externalUserRights === 'view' && authService.isAdmin()" class="fas fa-external-link-square-alt external-user-badge"></em>
						</a>
						<!-- Page edit mode and selected page -->
						<em *ngIf="pageEdit && (page.id == pageEditId)" class="fas fa-ellipsis-v header-menu"></em>
						<a *ngIf="pageEdit && (page.id == pageEditId)" class="move-icon">
							<div [title]="page.title">{{ page.title }}</div>
							<em *ngIf="page.settings.externalUserRights === 'view' && authService.isAdmin()" class="fas fa-external-link-square-alt external-user-badge"></em>
						</a>
						<!-- Page edit mode and not selected page -->
						<span *ngIf="pageEdit && (page.id != pageEditId)">
							<div [title]="page.title">{{ page.title }}</div>
							<em *ngIf="page.settings.externalUserRights === 'view' && authService.isAdmin()" class="fas fa-external-link-square-alt external-user-badge"></em>
						</span>
					</li>
				</div>
				<!-- Right scroll arrow -->
				<span class="scroll-arrow-container">
					<em class="nav-arrows fas blank" *ngIf="!navScrollRight && navScrollLeft"></em>
					<em class="nav-arrows fas fa-chevron-right" (click)="scrollRight()" *ngIf="navScrollRight"></em>
				</span>
				<!-- Create page button -->
				<li *ngIf="authService.isAdmin()" id="createPage" class="nav-link" routerLinkActive="active" (click)="onPageSelected()">
					<span *ngIf="pageEdit"><em class="fas fa-plus" title="Add Page" [ngClass]="fa-plus"></em></span>
					<a *ngIf="!pageEdit" id="create-page-button" routerLink="/page" [queryParams]="{action:'create'}">
						<em class="fas fa-plus" title="Add Page" [ngClass]="fa-plus"></em>
					</a>
				</li>
			</ul>
		</div>

		<!-- Navbar right container -->
		<ul class="nav navbar-nav navbar-right">
			<!-- Expiration message -->
			<li *ngIf="timeoutTime && authService.isAuthenticated()" class="expiration-message-container"><span>Your session will expire in {{ idleGracePeriod - timeoutTime | minuteSeconds }}. <button (click)="renewSession()" class="btn btn-secondary btn-xs">Refresh</button></span></li>
			<!-- Welcome message -->
			<li *ngIf="authService.isAuthenticated()" class="welcome-message-container"><span>Welcome {{ authService.getFullName() ? authService.getFullName() : authService.name }}</span></li>
			<!-- My Recent Items - Desktop only -->
			<div class="user-icons-container">
				<li routerLinkActive="active" *ngIf="authService.isAuthenticated() && recentEnabled" (click)="onPageSelected()" class="desktop-only">
					<!-- Not page edit mode -->
					<a id="recent-items-button" *ngIf="!pageEdit" href="javascript:void(0)" (click)="navigate('recent')">
						<em class="fas fa-user-clock" title="My Recent Items"></em>
						<span class="sr-only">My Recent Items</span>
					</a>
					<!-- Page edit mode - disabled -->
					<span *ngIf="pageEdit">
						<em class="fas fa-user-clock" title="My Recent Items"></em>
						<span class="sr-only">My Recent Items</span>
					</span>
				</li>
				<!-- File Share - Desktop only -->
				<li class="nav-link" routerLinkActive="active" *ngIf="authService.isAuthenticated() && fileShareEnabled" (click)="onPageSelected()" class="desktop-only">
					<!-- Not page edit mode -->
					<a id="fileShare-button" *ngIf="!pageEdit" href="javascript:void(0)" (click)="navigate('shares')">
						<em class="fas fa-share" title="File Share"></em>
						<span class="sr-only">File Share</span>
					</a>
					<!-- Page edit mode - disabled -->
					<span *ngIf="pageEdit">
						<em class="fas fa-star" title="File Share"></em>
						<span class="sr-only">File Share</span>
					</span>
				</li>

				<!-- My Favorites - Desktop only -->
				<li class="nav-link" routerLinkActive="active" *ngIf="authService.isAuthenticated() && favoritesEnabled" (click)="onPageSelected()" class="desktop-only">
					<!-- Not page edit mode -->
					<a id="favorites-button" *ngIf="!pageEdit" href="javascript:void(0)" (click)="navigate('favorites')">
						<em class="fas fa-star" title="My Favorites"></em>
						<span class="sr-only">Favorites</span>
					</a>
					<!-- Page edit mode - disabled -->
					<span *ngIf="pageEdit">
						<em class="fas fa-star" title="My Favorites"></em>
						<span class="sr-only">Favorites</span>
					</span>
				</li>

				<!-- Page menu button - Mobile only - Page edit mode - disabled -->
				<li #collapsePagelistButton *ngIf="pageEdit" class="nav-link dropdown toggle-collapse-button disabled">
					<em class="fas fa-bars"></em>
				</li>

				<!-- Page menu button - Mobile only - Not page edit mode -->
				<li #collapsePagelistButton *ngIf="!pageEdit" class="nav-link dropdown toggle-collapse-button" (click)="pagelistCollapsed = !pagelistCollapsed; assertOneDropdown('pagelist')" [attr.aria-expanded]="!pagelistCollapsed" aria-controls="collapsable">
					<em class="fas fa-bars"></em>
					<!-- Page menu container -->
					<ul *ngIf="authService.isAuthenticated()" id="collapsable" class="dropdown-menu page-menu-container-mobile" [collapse]="pagelistCollapsed">
						<!-- Pages -->
						<div #headerItems [cdkDropListDisabled]="!pageEdit" cdkDropList cdkDropListOrientation="horizontal" class="page-list nav navbar-nav" (cdkDropListDropped)="drop($event)">
							<li class="nav-link" cdkDrag routerLinkActive="active" *ngFor="let page of pages" [cdkDragDisabled]="page.id != pageEditId" (click)="onPageSelected()">
								<!-- Normal view mode -->
								<a *ngIf="!pageEdit" [routerLink]="'/page/' + page.id">
									<div [title]="page.title">{{ page.title }}</div>
									<em *ngIf="page.settings.externalUserRights === 'view' && authService.isAdmin()" class="fas fa-external-link-square-alt external-user-badge"></em>
								</a>
								<!-- Page edit mode and selected page -->
								<em *ngIf="pageEdit && (page.id == pageEditId)" class="fas fa-ellipsis-v header-menu"></em>
								<a *ngIf="pageEdit && (page.id == pageEditId)" class="move-icon">
									<div [title]="page.title">{{ page.title }}</div>
									<em *ngIf="page.settings.externalUserRights === 'view' && authService.isAdmin()" class="fas fa-external-link-square-alt external-user-badge"></em>
								</a>
								<!-- Page edit mode and not selected page -->
								<span *ngIf="pageEdit && (page.id != pageEditId)">
									<div [title]="page.title">{{ page.title }}</div>
									<em *ngIf="page.settings.externalUserRights === 'view' && authService.isAdmin()" class="fas fa-external-link-square-alt external-user-badge"></em>
								</span>
							</li>
						</div>

						<!-- Create page button -->
						<li *ngIf="authService.isAdmin()" id="createPage" class="nav-link" routerLinkActive="active">
							<a id="create-page-button" routerLink="/page" [queryParams]="{action:'create'}" (click)="onPageSelected()">
								<em class="fas fa-plus" title="Add Page" [ngClass]="fa-plus"></em>
							</a>
						</li>
					</ul>
				</li>

				<!-- User dropdown container -->
				<li #settingsDropdown dropdown class="nav-link dropdown" *ngIf="authService.isAuthenticated()">
					<!-- Avatar / User icon - Not page edit mode -->
					<a *ngIf="!pageEdit" id="settings-dropdown" dropdownToggle data-after="User Menu" class="dropdown-toggle" role="button" aria-haspopup="true" aria-expanded="false" (click)="adminDropdownCollapsed = !adminDropdownCollapsed; assertOneDropdown('admin')">
						<img *ngIf="avatarUrl" class="headerAvatar" [src]="avatarUrl" alt="User Avatar" />
						<em *ngIf="!avatarUrl" class="fas" title="My Account" [ngClass]="{'fa-user-shield': authService.isAdmin(), 'fa-user': !authService.isAdmin()}"></em>
						<span class="caret"></span>
					</a>
					<!-- Avatar / User icon - Page edit mode - disabled -->
					<span *ngIf="pageEdit" class="dropdown-toggle">
						<img *ngIf="avatarUrl" class="headerAvatar" [src]="avatarUrl" alt="User Avatar" />
						<em *ngIf="!avatarUrl" class="fas" title="My Account" [ngClass]="{'fa-user-shield': authService.isAdmin(), 'fa-user': !authService.isAdmin()}"></em>
						<span class="caret"></span>
					</span>
					<!-- User dropdown menu-->
					<ul *dropdownMenu class="dropdown-menu">
						<!-- My Recent Items - Mobile only -->
						<li *ngIf="authService.isAuthenticated() && recentEnabled" class="mobile-only">
							<a href="javascript:void(0)" (click)="navigate('recent')"><em class="fas fa-user-clock"></em><span style="margin-left: 10px">My Recent Items</span></a>
						</li>
						<!-- File Share - Mobile only -->
						<li *ngIf="authService.isAuthenticated() && fileShareEnabled" class="mobile-only">
							<a href="javascript:void(0)" (click)="navigate('shares')"><em class="fas fa-share"></em><span style="margin-left: 10px">File Share</span></a>
						</li>
						<!-- Favorites - Mobile only -->
						<li *ngIf="authService.isAuthenticated() && favoritesEnabled" class="mobile-only">
							<a href="javascript:void(0)" (click)="navigate('favorites')"><em class="fas fa-star"></em><span style="margin-left: 10px">Favorites</span></a>
						</li>
						<!-- Workspaces -->
						<ng-container *ngIf="workspaces.length > 1">
							<li><a id="workspaces-label">Switch Workspaces:</a></li>
							<li *ngFor="let workspace of workspaces" class="workspace-item">
								<a *ngIf="workspace.id == authService.getToken('workspace')" [ngClass]="workspace.id == authService.getToken('workspace') ? 'currentWorkspace' : ''">{{workspace.name ? workspace.name : 'Unnamed Workspace'}} <em class="far fa-check-circle"></em></a>
								<a href="javascript:void(0)" *ngIf="workspace.id != authService.getToken('workspace')" (click)="switchWorkspace(workspace.id)" [ngClass]="workspace.id == authService.getToken('workspace') ? 'currentWorkspace' : ''">{{workspace.name ? workspace.name : 'Unnamed Workspace'}}</a>
							</li>
						</ng-container>
						<!-- Other Links -->
						<li *ngIf="authService.isAdmin()">
							<a href="javascript:void(0)" (click)="navigate('admin')">Global Settings</a>
						</li>
						<li *ngIf="authService.isAdmin()">
							<a href="javascript:void(0)" (click)="navigate('admin/security')">Security Settings</a>
						</li>
						<li>
							<a href="javascript:void(0)" (click)="navigate('user')">User Settings</a>
						</li>
						<li>
							<a href="javascript:void(0)" (click)="openDebugModal()">Troubleshooting</a>
						</li>
						<li *ngIf="authService.isAdmin()">
							<a href="javascript:void(0)" (click)="navigate('messages')">Recent System Messages</a>
						</li>
						<li><a href="javascript:void(0)" (click)="logout()">Logout</a></li>
					</ul>
				</li>
			</div>
			<!-- Login link - Unauthenticated only -->
			<li *ngIf="!authService.isAuthenticated()" class="nav-link">
				<a href="javascript:void(0)" routerLink="/login">Login</a>
			</li>
		</ul>
	</div>
</nav>

<ng-template #credentialsDialog>
	<div class="modal-header vault-connection">
		<em class="fas fa-database"></em>
		<h5 class="modal-title">Enter vault connection details...</h5>
	</div>
	<div class="modal-body vault-connection">
		<ng-container *ngIf="activeWorkspace === '1'">
			<div [ngClass]="{'loading in': loading }">
				<div class="col-md-6">
					<div>
						<label for="name">* Workspace Name:</label>
						<input type="text" id="name" class="form-control" [(ngModel)]="initialWorkspace.name" autocomplete="off">
					</div>
					<div>
						<label for="username">* Vault Admin Username:</label>
						<input type="text" id="username" class="form-control" [(ngModel)]="initialWorkspace.username" autocomplete="newpassword">
					</div>
					<div>
						<label for="vaultGuid">* M-Files Vault GUID:</label>
						<input type="text" id="vaultGuid" class="form-control" [(ngModel)]="initialWorkspace.vaultGuid" autocomplete="off">
					</div>
				</div>
				<div class="col-md-6">
					<div>
						<label for="endpoint">* M-Files Endpoint:</label>
						<input type="text" id="endpoint" class="form-control" [(ngModel)]="initialWorkspace.endpoint" autocomplete="off">
					</div>
					<div>
						<label for="password">* Vault Admin Password:</label>
						<input type="password" id="password" class="form-control" [(ngModel)]="initialWorkspace.password" autocomplete="off">
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="activeWorkspace !== '1'">
			The credentials for the M-Files vault associated with M-Connect have not been set. Please update the credentials and then reload the webpage.
		</ng-container>
	</div>
	<div class="modal-footer no-bg vault-connection">
		<div *ngIf="activeWorkspace === '1'">
			<span class="red">{{ intialCreateMessage }}</span> <button type="button" class="btn btn-primary btn-sm" [disabled]="!intialWorkspaceValid() || loading" (click)="setInitialWorkspace()">Submit</button>
		</div>
	</div>
</ng-template>

<ng-template #debugDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-bug"></em>Troubleshooting Information</h5>
	</div>
	<div class="modal-body debug-body" [ngClass]="{'loading in': loadingDebug }">
		<div>
			<label>Username:</label>
			<div>{{ authService.getActiveUsername() }}</div>
		</div>
		<div>
			<label>Timestamp:</label>
			<div>{{ debugTime | date:'medium' }}</div>
		</div>
		<div>
			<label>Admin?</label>
			<div>{{ authService.isAdmin() ? 'Yes' : 'No' }}</div>
		</div>
		<div>
			<label>External?</label>
			<div>{{ authService.isExternalUser() ? 'Yes' : 'No' }}</div>
		</div>
		<div>
			<label>User ID:</label>
			<div>{{ userId }}</div>
		</div>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="copyDebug()" [disabled]="loadingDebug">Copy</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="closeModal()">Close</button>
	</div>
</ng-template>