import { Guid } from './guid';

export class UploadQueue {
	id: string;
	file: File;
	progress: number;
	message: string;
	isCancel: boolean;
	isError: boolean;
	get isSuccess(): boolean {
		if (this.progress === 100) {
			return true;
		}

		return false;
	}

	constructor(file: File) {
		this.id = Guid.newGuid();
		this.file = file;
		this.progress = 0;
		this.message = '';
		this.isCancel = false;
		this.isError = false;
	}
}
