import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ConfigurationService } from '../services/configuration.service';

@Injectable()
export class SharesGuard {

	constructor(
		private authService: AuthService,
		private router: Router,
		private configService: ConfigurationService
	) { }

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {

		if (this.configService.fileShare && !this.authService.isExternalUser()) {
			return true;
		} else {
			this.router.navigate(['/page/welcome']);
			return false;
		}
	}

}
