import { NgModule } from '@angular/core';
import { BytesPipe } from './bytes.pipe';
import { ThumbnailPipe } from './thumbnail.pipe';
import { SafePipe } from './safeHtml.pipe';
import { CustomCssExtractor } from './customCss.pipe';
import { MinuteSecondsPipe } from './minuteSeconds.pipe';
import { MFObjectSizePipe } from './mfObjectSize.pipe';
import { SafeUrlPipe } from './safeUrl.pipe';

@NgModule({
	imports: [],
	declarations: [
		BytesPipe,
		ThumbnailPipe,
		SafePipe,
		SafeUrlPipe,
		CustomCssExtractor,
		MinuteSecondsPipe,
		MFObjectSizePipe
	],
	exports: [
		BytesPipe,
		ThumbnailPipe,
		SafePipe,
		SafeUrlPipe,
		CustomCssExtractor,
		MinuteSecondsPipe,
		MFObjectSizePipe
	]
})
export class PipesModule { }
