import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NavigationExtras, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(
		private authService: AuthService,
		private router: Router
	) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const service = req.url.substring(req.url.lastIndexOf('/'));
		const excludeServices = ['/view', '/classes', '/class', '/comments', '/comment', '/checkin'];
		const updateLoading = excludeServices.indexOf(service) === -1;

		if (updateLoading) {
			this.authService.updateLoading(true);
		}

		let workspace: any;
		if (req.params.get('workspace') && req.params.get('workspace') !== 'NaN') {
			workspace = req.params.get('workspace');
		} else {
			workspace = this.authService.getToken('workspace');
			if (!workspace) {
				workspace = '1';
			}
		}

		if (!sessionStorage.getItem('activeSession')) {
			this.authService.setToken('jwt', '');
		}

		let authReq = req.clone();
		if (!req.url.includes('/api/upload') && !req.url.includes('/api/revise')) {
			authReq = req.clone({
				headers: req.headers
					.set('Authorization', this.authService.getToken('jwt'))
					.set('ShareToken', this.authService.getActiveShareLink().token),
				params: req.params
					.set('workspace', workspace)
			});
		}

		return next.handle(authReq).pipe(map((response: any) => {
			if (response.status === 200) {
				if (response.headers.get('Authorization')) {
					this.authService.setToken('jwt', response.headers.get('Authorization'));
				}
				if (updateLoading) {
					this.authService.updateLoading(false);
				}
			}
			return response;
		}), catchError((response: any) => {
			if (response instanceof HttpErrorResponse && response.status === 401) {
				this.authService.logout();
				let path = this.router.url;
				if (path.indexOf('?') > -1) {
					path = path.substring(0, path.indexOf('?'));
				}
				if (path !== '/login') {
					const navigationExtras: NavigationExtras = {
						queryParams: {
							timeout: true,
							url: path
						}
					};
					this.router.navigate(['/login'], navigationExtras);
				}
			}
			if (updateLoading) {
				this.authService.updateLoading(false);
			}
			this.authService.doLoginFailed(response.status);
			return throwError(response);
		}));
	}
}
