import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ExternalShareService } from 'src/app/services/external-share.service';
import { FavoritesService } from 'src/app/services/favorite.service';
import { MFilesService } from 'src/app/services/mfiles.service';
import { PageService } from 'src/app/services/page.service';

@Component({
	selector: 'mfiles-row',
	templateUrl: './table-row.component.html',
	styleUrls: ['./table-row.component.css']
})
export class TableRowComponent implements OnInit, OnDestroy {
	modalRef: BsModalRef;
	@ViewChild('coauthorModal') private coauthorModal: TemplateRef<any>;
	@ViewChild('coauthorRequestModal') private coauthorRequestModal: TemplateRef<any>;
	@ViewChild('deleteDialog') private deleteDialog: TemplateRef<any>;
	@ViewChild('downloadDialog') private downloadDialog: TemplateRef<any>;

	loadingRelated = false;
	loadingDownload = false;

	favorites: any[] = [];

	@Input() view: any;
	@Input() item: any;
	@Input() inGrid = false;
	@Input() completedCriteriaField: boolean;
	@Input() completedField: boolean;
	@Input() completedEnabled: boolean;
	@Input() favoritesEnabled: boolean;
	@Input() revisionsEnabled = false;
	@Input() downloadEnabled = false;
	@Input() documentRevisionsDisabled: boolean;
	@Input() documentDownloadsDisabled: boolean;
	@Input() documentDeletionsDisabled: boolean;
	@Input() coauthorEnabled: boolean;
	@Input() associateSelectedEnabled = false;
	@Input() deleteEnabled = false;
	@Input() disableRelatedView = false;

	@Input() vaultProperties: any[];
	@Input() objectTypes: any;
	@Input() objectLists: any;

	@Input() icon: any;
	@Input() page: number;
	@Input() path: string;
	@Input() pageId = -1;
	@Input() widgetId = '';

	@Input() objectTypeIcons = {};

	@Input() automaticWorkflowTransitions = false;
	@Input() startingState: number;
	@Input() targetState: number;

	@Input() external = false;
	@Input() isAuthed = true;
	@Input() token: string;
	@Input() permissions = [];
	@Input() autoSelect = false;
	@Input() doNotDownload = false;

	@Input() autoSelectType: number;
	@Input() autoSelectId: number;

	@Output() uploaderOpened = new EventEmitter<any>();
	@Output() deleteClicked = new EventEmitter<any>();
	@Output() associateObjectType = new EventEmitter<number>();
	@Output() associateObject = new EventEmitter<number>();
	@Output() externalObjectSelected = new EventEmitter<any>();
	@Output() loadIcon = new EventEmitter<number>();
	@Output() loadView = new EventEmitter<number>();

	completeCriteria = [];

	scrollListener: Function;

	coauthorItem: any;

	relatedLoaded = false;
	files = [];
	related = [];
	relatedTypeShown = {};
	showRelated = false;
	@Input() relatedParents = [];

	// rowSelected = false;
	selectedItemId: number;
	selectedItemType: number;
	itemSelectedChangedSubscription: Subscription;

	deleteName = '';
	deleteType: number;
	deleteItem: number;

	coauthorRequestEmail = '';
	coauthorRequestType: number;
	coauthorRequestItem: number;

	downloadLink = '';

	constructor(
		public configService: ConfigurationService,
		private modalService: BsModalService,
		private mfilesService: MFilesService,
		private pageService: PageService,
		private favoritesService: FavoritesService,
		private myElement: ElementRef,
		private renderer: Renderer2,
		public authService: AuthService,
		private router: Router,
		private externalShareService: ExternalShareService,
		private toastr: ToastrService
	) {
	}

	ngOnInit() {
		this.subscribeToItemSelectedChanged();

		if (this.completedEnabled) {
			if (this.isElementInViewport(this.myElement.nativeElement)) {
				this.loadCompleted();
			} else {
				if (this.inGrid) {
					this.scrollListener = this.renderer.listen(this.myElement.nativeElement.closest('gridster-item'), 'scroll', (event) => {
						if (this.isElementInViewport(this.myElement.nativeElement)) {
							this.loadCompleted();
							this.scrollListener(); // destroys the scroll listener
						}
					});
				} else {
					this.scrollListener = this.renderer.listen(window, 'scroll', (event) => {
						if (this.isElementInViewport(this.myElement.nativeElement)) {
							this.loadCompleted();
							this.scrollListener(); // destroys the scroll listener
						}
					});
				}
			}
		}

		// Enable revisions if the user has clicked into a virtual folder that they have read/write access to
		if (this.view && this.view.hasAccessProperties && !this.external) {
			const pathSegments: string[] = this.view.path.split('/').filter(folder => folder.length > 0);

			// See Limitation comment above loadView() in view-widget.component.ts
			const firstGroupingLevelIndex: number = pathSegments.findIndex(segment => segment.match('L'));
			const firstGLAfterLastView: string = pathSegments[firstGroupingLevelIndex];
			const folderName: string = firstGLAfterLastView;
			if (this.view.rFolders.includes(folderName)) {
				this.downloadEnabled = true;
				this.revisionsEnabled = false;
			} else if (this.view.rwFolders.includes(folderName)) {
				this.downloadEnabled = true;
				this.revisionsEnabled = true;
				this.deleteEnabled = false;
			} else if (this.view.rwdFolders.includes(folderName)) {
				this.downloadEnabled = true;
				this.revisionsEnabled = true;
				this.deleteEnabled = true;
			}
		} else if (this.view && !this.view.hasAccessProperties && !this.external) {
			if (this.documentRevisionsDisabled !== null && this.documentRevisionsDisabled !== undefined) {
				this.revisionsEnabled = this.item.annotate && !this.documentRevisionsDisabled;
			} else {
				this.revisionsEnabled = this.item.annotate;
			}

			if (this.documentDownloadsDisabled !== null && this.documentDownloadsDisabled !== undefined) {
				this.downloadEnabled = this.item.download && !this.documentDownloadsDisabled;
			} else {
				this.downloadEnabled = this.item.download;
			}

			if (this.documentDeletionsDisabled !== null && this.documentDeletionsDisabled !== undefined) {
				this.deleteEnabled = this.item.delete && !this.documentDeletionsDisabled;
			} else {
				this.deleteEnabled = this.item.delete;
			}
		}

		if (this.item.type === this.autoSelectType && this.item.id === this.autoSelectId) {
			this.selectedItemType = this.item.type;
			this.selectedItemId = this.item.id;
			this.fileClicked(this.item.id, this.item.type, 0, {});
		}

		if (this.item.files.length > 1 && this.disableRelatedView) {
			this.files = this.item.files;
			this.showRelated = true;
		}
	}

	ngOnDestroy() {
		if (this.scrollListener) {
			this.scrollListener();
		}
		if (this.itemSelectedChangedSubscription) {
			this.itemSelectedChangedSubscription.unsubscribe();
		}
	}

	subscribeToItemSelectedChanged() {
		this.itemSelectedChangedSubscription = this.pageService.itemSelectedChanged.subscribe((value: any) => {
			this.selectedItemId = value.id;
			this.selectedItemType = value.type;
		});
	}

	isMetadataOrViewerPresent() {
		const widgets = this.pageService.getActivePageWidgets();
		let widgetFound = false;
		if (widgets) {
			for (const widget of widgets) {
				if (widget.type === 'viewer' || widget.type === 'metadata') {
					widgetFound = true;
				}
			}
		}

		return widgetFound;
	}

	fileClicked(itemId: number, type: number, fileIndex: number, e: any) {
		let openFile = true;
		if (e.target?.localName === 'em') {
			openFile = false;
		}
		if (this.automaticWorkflowTransitions && this.startingState && this.targetState) {
			for (const prop of this.item.properties) {
				if (prop.propertyDef === 39) { // workflow state
					if (this.startingState === prop.value.lookup.item) {
						this.mfilesService.updateSingleProperty(39, 9, this.targetState, type, itemId).subscribe((result) => {
							if (openFile) {
								this.openFile(itemId, type, fileIndex);
							}
						});
					} else if (openFile) {
						this.openFile(itemId, type, fileIndex);
					}
					break;
				}
			}
		} else if (openFile) {
			this.openFile(itemId, type, fileIndex);
		}
	}

	openFile(itemId: number, type: number, fileIndex: number) {
		if (this.external) {
			this.externalObjectSelected.emit({ type, itemId, fileIndex });
		} else {
			// Determine if there is a metadata or viewer  widget on the page
			const widgetFound = this.isMetadataOrViewerPresent();

			// If there is, emit that a document selection has occurred so the widgets can retrieve their data
			if (widgetFound) {
				// Set additional context for workflow state and class
				let workflowState;
				let itemClass;
				for (const prop of this.item.properties) {
					if (prop.propertyDef === 39) { // workflow state
						workflowState = prop.value.serializedValue;
					} else if (prop.propertyDef === 100) { // class
						itemClass = prop.value.serializedValue;
					}
				}

				const context = {
					itemId: itemId,
					query: '',
					type: type,
					fileIndex: fileIndex,
					workflowState: workflowState,
					itemClass: itemClass
				};
				this.pageService.documentSelectionOccurred.emit(context);
			} else if (type === 0 || type === 9) {
				// If not, open the viewer
				if (this.page) {
					this.pageService.setPageEditModeChanged({ pageId: this.page, edit: false });
					this.router.navigate(['file', itemId, fileIndex], { queryParams: { sourcePage: this.page, path: this.path } });
				} else {
					this.router.navigate(['file', itemId, fileIndex]);
				}


				// In case we need to open in new tab in future
				// window.open(window.location.origin + '/#/file/' + fileId + query, '_blank');
			}
		}
	}

	subExternalObjectSelected(objectInfo: any) {
		this.externalObjectSelected.emit({
			type: objectInfo.type,
			itemId: objectInfo.itemId,
			fileIndex: objectInfo.fileIndex
		});
	}

	download(id: number, fileIndex: number) {
		this.loadingDownload = true;
		if (this.external) {
			this.externalShareService.download(this.token, 0, id, fileIndex).subscribe(
				response => {
					saveAs(response.body, response.headers.get('filename'));
					this.loadingDownload = false;
				},
				err => {
					this.toastr.error('An error occurred. Please try again later.');
					this.loadingDownload = false;
				});
		} else {
			if (this.pageId && this.widgetId) {
				this.mfilesService.pageDownload(0, id, -1, fileIndex, this.pageId, this.widgetId).subscribe(response => {
					var reader = new FileReader();
					reader.onload = () => {
						const contents = reader.result.toString();
						if (contents.startsWith('http')) {
							this.downloadLink = contents;
							this.openModal(this.downloadDialog, 'md');
						} else {
							saveAs(response.body, response.headers.get('filename'));
						}
						this.loadingDownload = false;
					};
					reader.readAsText(response.body);
				},
					err => {
						this.toastr.error('An error occurred. Please try again later.');
						this.loadingDownload = false;
					});
			} else {
				this.mfilesService.download(0, id, -1, fileIndex).subscribe(response => {
					saveAs(response.body, response.headers.get('filename'));
					this.loadingDownload = false;
				},
					err => {
						this.toastr.error('An error occurred. Please try again later.');
						this.loadingDownload = false;
					});
			}
		}
	}

	// This method is called using the Download button on Document Collections
	downloadDocumentCollection(item: any) {
		this.loadingDownload = true;
		if (this.external) {
			this.externalShareService.downloadDocCollection(this.token, item).subscribe({
				next: (response) => {
					saveAs(response.body, response.headers.get('filename'));
				},
				error: (err) => {
					this.toastr.error('An error occurred while attempting to download all documents from document collection.');
					console.error(err);
				},
				complete: () => {
					this.loadingDownload = false;
				}
			})
		} else {
			if (this.pageId && this.widgetId) {
				this.mfilesService.pageDownloadDocCollection(item, this.pageId, this.widgetId).subscribe({
					next: (response) => {
						saveAs(response.body, response.headers.get('filename'));
					},
					error: (err) => {
						this.toastr.error('An error occurred while attempting to download all documents from document collection.');
						console.error(err);
					},
					complete: () => {
						this.loadingDownload = false;
					}
				});
			} else {
				this.mfilesService.downloadDocCollection(item).subscribe({
					next: (response) => {
						saveAs(response.body, response.headers.get('filename'));
					},
					error: (err) => {
						this.toastr.error('An error occurred while attempting to download all documents from document collection.');
						console.error(err);
					},
					complete: () => {
						this.loadingDownload = false;
					}
				});
			}
		}
	}

	favoriteChanged(file: any) {
		if (file.favorite) {
			this.favoritesService.add(file.type, file.id).subscribe(() => {
				// intentionally blank
			});
		} else {
			this.favoritesService.remove(file.type, file.id).subscribe(() => {
				// intentionally blank
			});
		}
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.show(
			template,
			{ class: 'modal-' + size, backdrop: 'static' }
		);
	}

	addCriteria() {
		this.completeCriteria.push({ id: -1, comparison: 'eq', value: '' });
	}

	removeCriteria(i: number) {
		this.completeCriteria.splice(i, 1);
	}

	criteriaFieldChanged(e: any, i: number) {
		const fieldId = e.id;
		this.checkCriteriaField(fieldId);

		this.completeCriteria[i].value = '';
	}

	checkCriteriaField(fieldId: number) {
		if (this.vaultProperties && !this.objectTypes[fieldId]) {
			for (const property of this.vaultProperties) {
				if (property.id === fieldId) {
					if (!this.objectTypes[fieldId]) {
						this.objectTypes[fieldId] = property.dataType;
					}
					if (property.dataType === 9 || property.dataType === 10) {
						this.mfilesService.getValueListOptions(property.valueList).subscribe((result) => {
							this.objectLists[fieldId] = result.body['Items'];
						});
					}
					break;
				}
			}
		}
	}

	setCriteria() {
		this.modalRef.hide();
		for (const criteria of this.completeCriteria) {
			const dataType = this.objectTypes[criteria.id];
			if (dataType === 5 || dataType === 6 || dataType === 7) {
				criteria.value = criteria.value.getTime();
			}
		}

		this.item.loaded = false;
		this.mfilesService.updateCompleteCriteria(JSON.stringify(this.completeCriteria), 0, this.item.id).subscribe((response) => {
			const result: any = response.body;
			for (let i = 0; i < result.properties.length; i++) {
				const prop = result.properties[i];
				if (prop.propertyDef === this.completedField) {
					this.item.completed = prop.value.value;
				} else if (prop.propertyDef === this.completedCriteriaField) {
					this.item.completeCriteria = prop.value.value;
					this.completeCriteria = JSON.parse(prop.value.value);
				}
				this.item.loaded = true;
			}
		}, (err: any) => {
			this.item.loaded = true;
			this.item.error = true;
		});
	}

	loadCompleted() {
		this.item.loaded = false;
		this.mfilesService.getCompletedProperties(this.item).subscribe((response) => {
			this.item = response.body;

			this.completeCriteria = JSON.parse(this.item.completeCriteria);

			for (const criterion of this.completeCriteria) {
				this.checkCriteriaField(criterion.id);
				const dataType = this.objectTypes[criterion.id];
				if (dataType === 5 || dataType === 6 || dataType === 7) {
					criterion.value = new Date(criterion.value);
				}
			}
			this.checkCriteriaField(-1);

			this.item.loaded = true;
			this.item.error = false;
		}, (err: any) => {
			this.item.loaded = true;
			this.item.error = true;
		});
	}

	isElementInViewport(el: HTMLElement) {
		const rect = el.getBoundingClientRect();

		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom - 100 <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	getRelated() {
		this.loadingRelated = true;

		if (this.external) {
			if (this.relatedLoaded) {
				this.showRelated = true;

				this.loadingRelated = false;
			} else {
				this.relatedParents.push({ type: this.item.type, id: this.item.id, version: this.item.version });
				this.externalShareService.getRelated(this.token, this.item.type, this.item.id, this.item.version).subscribe((result: any) => {
					this.relatedLoaded = true;
					this.files = result.files;
					this.related = [];

					if (result.collectionMembers && result.collectionMembers.length > 0) {
						this.related.push({ type: 0, typeName: 'Collection members', objects: [...result.collectionMembers] });
					}

					if (this.files.length > 1 || this.related.length > 0) {
						for (const relType of this.related) {
							this.relatedTypeShown[relType.type] = false;
							for (const rel of relType.objects) {
								if (this.isInRelatedParent(rel)) {
									rel.expanded = true;
								}
							}
						}
						this.showRelated = true;

						this.loadingRelated = false;
					} else {
						this.loadingRelated = false;
					}
				});
			}
		} else {
			if (this.relatedLoaded) {
				this.showRelated = true;

				this.loadingRelated = false;
			} else {
				this.relatedParents.push({ type: this.item.type, id: this.item.id, version: this.item.version });
				this.mfilesService.getPageRelated(this.pageId, this.widgetId, this.item.type, this.item.id, this.item.version).subscribe((result: any) => {
					this.relatedLoaded = true;
					if (result.files) {
						this.files = result.files;
					} else {
						this.files = [];
					}
					if (result.related) {
						this.related = result.related;
					} else {
						this.related = [];
					}

					if (result.collectionMembers && result.collectionMembers.length > 0) {
						this.related.push({ type: 0, typeName: 'Collection members', objects: [...result.collectionMembers] });
					}

					for (const relatedType of this.related) {
						if (!this.objectTypeIcons[relatedType.type]) {
							this.loadIcon.emit(relatedType.type);
						}
					}

					if (this.files.length > 1 || this.related.length > 0) {
						for (const relType of this.related) {
							this.relatedTypeShown[relType.type] = false;
							for (const rel of relType.objects) {
								if (this.isInRelatedParent(rel)) {
									rel.expanded = true;
								}
							}
						}
						this.showRelated = true;

						this.loadingRelated = false;
					} else {
						this.loadingRelated = false;
					}
				});
			}
		}
	}

	hideRelated() {
		this.showRelated = false;
	}

	hideShowRelType(type: number) {
		this.relatedTypeShown[type] = !this.relatedTypeShown[type];
	}

	getColCount() {
		let colCount = 2; // related and shareLink are always visible
		if (this.completedEnabled) {
			colCount = colCount + 1;
		}
		if (this.favoritesEnabled) {
			colCount = colCount + 1;
		}
		colCount = colCount + this.view.viewContents.columns.length;
		if (this.revisionsEnabled) {
			colCount = colCount + 1;
		}
		if (this.downloadEnabled) {
			colCount = colCount + 1;
		}
		if (this.deleteEnabled) {
			colCount = colCount + 1;
		}
		if (this.coauthorEnabled) {
			colCount = colCount + 1;
		}
		return colCount;
	}

	isInRelatedParent(rel: any) {
		let inParent = false;
		for (const parent of this.relatedParents) {
			if (rel.type === parent.type && rel.id === parent.id && rel.version === parent.version) {
				inParent = true;
				break;
			}
		}
		return inParent;
	}

	uploadRevision(itemId: number, fileIndex: number) {
		this.uploaderOpened.emit({ itemId, fileIndex });
	}

	delete(name: string, itemType: number, itemId: number) {
		this.deleteClicked.emit({ name, itemType, itemId });
	}

	openVerifyDelete(name: string, itemType: number, itemId: number) {
		this.deleteName = name;
		this.deleteType = itemType;
		this.deleteItem = itemId;
		this.openModal(this.deleteDialog, 'md');
	}

	deleteObject() {
		this.modalRef.hide();
		this.view.loading = true;
		this.mfilesService.pageDeleteObject(this.deleteType, this.deleteItem, true, this.pageId, this.widgetId).subscribe((result) => {
			this.deleteType = -1;
			this.deleteItem = -1;
			this.emitLoadView(this.view);
		});
	}

	emitLoadView(view: any) {
		this.loadView.emit(view);
	}

	isCoauthorItem(item: any) {
		return this.mfilesService.coauthorExtensions.indexOf(item.extension) > -1;
	}

	coauthor(item: any) {
		if (this.isAuthed) {
			if (item.coauthored) {
				this.coauthorItem = item;
				this.openModal(this.coauthorModal, 'md');
			} else {
				this.mfilesService.startCoauthoring(item.id);
				item.coauthored = !item.coauthored;
			}
		} else {
			this.coauthorRequestEmail = '';
			this.coauthorRequestType = item.type;
			this.coauthorRequestItem = item.id;
			this.openModal(this.coauthorRequestModal, 'md');
		}
	}

	requestCoauthor() {
		this.modalRef.hide();
		this.externalShareService.requestCoauthor(this.token, this.coauthorRequestItem, this.coauthorRequestType, this.coauthorRequestEmail).subscribe((result) => {
			this.coauthorRequestEmail = '';
			this.coauthorRequestType = -1;
			this.coauthorRequestItem = -1;
			this.toastr.success('CoAuthor Request Successful');
		});
	}

	resumeCoauthor() {
		this.mfilesService.continueCoauthoring(this.coauthorItem.id);
	}

	undoCoauthor() {
		this.modalRef.hide();
		this.mfilesService.undoCoauthoring(this.coauthorItem.id).subscribe((result) => {
			this.coauthorItem.coauthored = !this.coauthorItem.coauthored;
			this.coauthorItem = undefined;
		}, (err) => {
			this.mfilesService.undoCoauthoringLink(this.coauthorItem.id);
			this.coauthorItem.coauthored = !this.coauthorItem.coauthored;
			this.coauthorItem = undefined;
		});
	}

	checkinCoauthor() {
		this.modalRef.hide();
		this.mfilesService.endCoauthoring(this.coauthorItem.id).subscribe((result) => {
			this.coauthorItem.coauthored = !this.coauthorItem.coauthored;
			this.coauthorItem = undefined;
		}, (err) => {
			this.mfilesService.endCoauthoringLink(this.coauthorItem.id);
			this.coauthorItem.coauthored = !this.coauthorItem.coauthored;
			this.coauthorItem = undefined;
		});
	}

	getDisplayValueForColumn(column: any): string {
		const columnId = column.id;
		let columnProp = null;

		for (const prop of this.item.properties) {
			if (prop.propertyDef === columnId) {
				columnProp = prop;
				break;
			}
		}

		if (columnProp != null && columnProp.value.displayValue != null && columnProp.value.displayValue.length > 0) {
			return columnProp.value.displayValue;
		} else {
			return '-';
		}
	}

	getItemSize() {
		let size = 0;
		let sizeFound = false;

		for (const prop of this.item.properties) {
			if (prop.propertyDef === 31) {
				size = prop.value.value;
				sizeFound = true;
				break;
			}
		}

		if (!sizeFound) {
			if (this.item.files.length > 0) {
				size = this.item.files[0].size;
			}
		}

		return size;
	}

	associate(item: any) {
		this.associateObjectType.emit(item.type);
		this.associateObject.emit(item.id);

		this.pageService.setItemSelectedChanged({ id: item.id, type: item.type });
	}

	getIcon(type: number) {
		this.loadIcon.emit(type);
	}

	copyToClipboard(id) {
		const oldUrl = window.location.href;
		let newUrl;

		if (/[?&]id\s*=/.test(oldUrl)) {
			newUrl = oldUrl.replace(/(?:([?&])id\s*=[^?&]*)/, "$1id=" + id);
		} else if (/\?/.test(oldUrl)) {
			newUrl = oldUrl + "&id=" + id;
		} else {
			newUrl = oldUrl + "?id=" + id;
		}

		if (!navigator.clipboard) {
			this.fallbackCopyTextToClipboard(newUrl);
			return;
		}
		let _this = this;
		navigator.clipboard.writeText(newUrl).then(function () {
			_this.toastr.success('Copied to clipboard.');
		}, function (err) {
			_this.toastr.error('Unable to copy to clipboard.');
		});
	}

	fallbackCopyTextToClipboard(text) {
		let textArea = document.createElement("textarea");
		textArea.value = text;

		// Avoid scrolling to bottom
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			let successful = document.execCommand('copy');
			let msg = successful ? 'successful' : 'unsuccessful';
			this.toastr.success('Copied to clipboard.');
		} catch (err) {
			this.toastr.error('Unable to copy to clipboard.');
		}

		document.body.removeChild(textArea);
	}

	bidRequestClicked() {
		const route = '/' + this.item.bidRequestName + '/' + this.item.id;

		if (this.automaticWorkflowTransitions && this.startingState && this.targetState) {
			for (const prop of this.item.properties) {
				if (prop.propertyDef === 39) { // workflow state
					if (this.startingState === prop.value.lookup.item) {
						this.mfilesService.updateSingleProperty(39, 9, this.targetState, this.item.type, this.item.id).subscribe((result) => {
							this.router.navigate([route], { queryParams: { internal: 'true' } });
						});
					} else {
						this.router.navigate([route], { queryParams: { internal: 'true' } });
					}
					break;
				}
			}
		} else {
			this.router.navigate([route], { queryParams: { internal: 'true' } });
		}
	}
}
