<div *ngIf="warning_message != ''" class="alert alert-warning">
	<em class="alert-icon fas fa-info-circle"></em>
	{{warning_message}}
</div>

<label *ngIf="!uploader.queue.length && !nonDocumentMode && !showObjectTypes" class="drag-drop-zone empty" cwxDnd webkitdirectory multiple (filesChange)="onFilesChange($event)" (filesInvalidChange)="onFileInvalids($event)">
	<div class="upload-message">
		<div class="upload-icon"><em class="alert-icon fas fa-upload"></em></div>
		<div class="upload-text"><span class="browse-link">Browse</span>&nbsp;or drag files into this area</div>
		<!-- <div class="non-document-text" *ngIf="allowFilelessUploads"><a class="browse-link" (click)="enterNonDocumentMode($event);">Click here</a>&nbsp;to upload a non-document object</div> -->
	</div>
	<ng-container *ngIf="isFolderUpload; else elseTemplate">
		<input type="file" id="file" uploader="uploader" [multiple]="multiple" webkitdirectory (change)="onSelectChange($event)" />
	</ng-container>
	<ng-template #elseTemplate>
		<input type="file" id="file" uploader="uploader" [multiple]="multiple" (change)="onSelectChange($event)" />
	</ng-template>
</label>

<div *ngIf="uploader.queue.length || !nonDocumentMode" cwxDnd (filesChange)="onFilesChange($event)" class="uploader-file-div">

	<ng-container *ngIf="keepFileUploadBrowse; else elseTemplate">
		<div class="col-md-8">
			<div class="col-md-3" *ngFor="let item of uploader.queue; let i = index">
				<div class="file-container" title="{{ item.file.name }}" (click)="selected(item, i)" [ngClass]="{'selected': selectedItem===item}">
					<div class="name elipsis"><span>{{ item.file.name }}</span></div>
					<div class="info-container">
						<div class="size">{{ item.file.size | bytes }}</div>
						<div class="button-container">
							<a aria-label="Download file" class="file-download" href="javascript:" type="button" (click)="download(item)"><em class="fas fa-download" title="Download file"></em></a>
							<a aria-label="Remove file" class="file-remove" href="javascript:" type="button" (click)="remove(item)"><em class="fas fa-times" title="Remove file"></em></a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-md-4 sticky" *ngIf="keepFileUploadBrowse">
			<div class="right-upload-message">
				<div class="upload-icon"><em class="alert-icon fas fa-upload"></em></div>
				<div class="upload-text"><label for="file-upload"><span class="browse-link">Browse</span></label>&nbsp;or drag files into this area</div>
			</div>
			<input type="file" id="file-upload" uploader="uploader" [multiple]="multiple" (change)="onSelectChange($event)">
		</div>
	</ng-container>
	<ng-template #elseTemplate>
		<div class="col-md-3" *ngFor="let item of uploader.queue; let i = index">
			<div class="file-container" title="{{ item.file.name }}" (click)="selected(item, i)" [ngClass]="{'selected': selectedItem===item}">
				<div class="name elipsis"><span>{{ item.file.name }}</span></div>
				<div class="info-container">
					<div class="size">{{ item.file.size | bytes }}</div>
					<div class="button-container">
						<a aria-label="Download file" class="file-download" href="javascript:" type="button" (click)="download(item)"><em class="fas fa-download" title="Download file"></em></a>
						<a aria-label="Remove file" class="file-remove" href="javascript:" type="button" (click)="remove(item)"><em class="fas fa-times" title="Remove file"></em></a>
					</div>
				</div>
			</div>
		</div>
	</ng-template>

</div>

<div *ngIf="progress > 0">
	<div class="progress">
		<div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': progress + '%' }"></div>
	</div>
</div>

<div *ngIf="!isRevision" class="uploader-metadata-div" [ngClass]="{'loading in': loading}">
	<div *ngIf="isForm && !showObjectTypes && (uploader.queue.length || nonDocumentMode) && (!autoSubmit || requiredMissing)">
		<div class="uploader-metadata">
			<div *ngIf="!defaultClass && defaultClass !== 0">
				<label for="class">Class</label>
				<ng-select id="class" class="typeahead" [items]="classes" [clearable]="false" bindLabel="name" bindValue="id" [(ngModel)]="formClasses[activeForm]" (change)="classChanged($event.id)">
				</ng-select>
				<!--
        <span *ngIf="defaultClass" class="defaultClassName">{{defaultClassName}}</span>
        -->
			</div>

			<div *ngIf="(formProperties[activeForm].length > 0) && allowUsersToAddProps">
				<label for="class">Additional Property</label>
				<ng-select class="typeahead" [items]="allProperties" [clearable]="false" bindLabel="name" bindValue="id" [(ngModel)]="additionalProp">
				</ng-select>
				<button type="button" class="btn btn-success" [disabled]="additionalProp === 0" (click)="addProperty()">Add Property</button>
			</div>

			<ng-container *ngFor="let form of forms; let i = index">
				<form [formGroup]="form" [hidden]="activeForm !== i">
					<div *ngFor="let prop of formProperties[i]" class="form-group">
						<div *ngIf="showPropertyField(prop)">
							<label [for]="prop.id"><span *ngIf="prop.required">*</span>{{prop.name}}</label>
							<app-uploader-field [field]="prop" [id]="prop.id" [form]="form" [bsConfig]="bsConfig" [multiValues]="multiValues" [external]="external" [token]="token"></app-uploader-field>
						</div>
					</div>
				</form>
			</ng-container>
		</div>
	</div>
</div>