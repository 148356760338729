<div class="main-container" [ngClass]="{'anonymous': !isAuthenticated, 'left-navigation': navigationLocation == 'left', 'expanded': navigationPanelExpanded, 'collapsed': !navigationPanelExpanded, 'hide-header': !showHeader}">
	<app-header [hidden]="!showHeader"></app-header>
	<div>
		<main>
			<router-outlet></router-outlet>
		</main>
	</div>
	<div *ngIf="enableCookieDisclaimer && !cookiesAccepted" class="privacy-message-container">
		<div class="text">{{ privacyMessage }}</div>
		<div>
			<button type="button" class="btn btn-success" (click)="acceptCookies()">Accept</button>
			<span *ngIf="privacyPolicyURL"><a [href]="privacyPolicyURL" target="_blank">Privacy Policy</a></span>
			<span *ngIf="termsOfServiceURL"><a [href]="termsOfServiceURL" target="_blank">Terms of Service</a></span>
		</div>
	</div>
</div>