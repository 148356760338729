<div class="main-content-wrapper">
	<div class="main-content">
		<header class="main-content-header">
			<h1 class="pull-left">
				<em *ngIf="loadingTitle" class="fas fa-circle-notch fa-spin"></em>
				<ng-container *ngIf="!loadingTitle">
					{{ title }}&nbsp;&nbsp;&nbsp;<a href="javascript:" (click)="loadShare()"><em class="fa fa-redo-alt"></em></a>
				</ng-container>
			</h1>
		</header>
	</div>
</div>
<div class="main-content-area" [ngClass]="{'loading in': loading }" *ngIf="!sharedItemsLoaded">&nbsp;</div>
<div class="main-content-area tall" [ngClass]="{'loading in': loading }" *ngIf="sharedItemsLoaded && sharedItems.length === 0">
	<div class="message error">The M-Connect Share is invalid or has expired.</div>
</div>
<div class="main-content-area" #mainContentArea [ngClass]="{'hidden': !sharedItemsLoaded || sharedItems.length == 0 }">
	<div class="main-content-body" #leftContainer [ngClass]="{'loading in': loading, 'upload': showUpload || showRevision }">
		<cwx-uploader *ngIf="showUpload" class="external" (queue)="onUploadQueueChange($event)" [multiple]="true" [external]="true" [token]="token" [isForm]="true" [autopopulateName]="true" [useClassNameProp]="true" [defaultClass]="uploadClass" [allowUsersToAddProps]="false" (formObj)="onFormChange($event)" (formProps)="onPropertiesChange($event)"></cwx-uploader>
		<cwx-uploader *ngIf="showRevision" class="external" (queue)="onUploadQueueChange($event)" [multiple]="false" [external]="true" [isRevision]="true" [fileOnly]="true"></cwx-uploader>
		<div *ngIf="showUpload || showRevision" class="panelUploaderButtonDiv external">
			<button type="button" href="javascript:" (click)="cancelUpload()" class="btn btn-danger btn-md cancel">Cancel</button>
			<button type="button" href="javascript:" *ngIf="showUpload" class="icon-link btn btn-md btn-success cancel" [disabled]="!uploadFormValid || uploadFiles.length === 0" (click)="create()">Upload</button>
			<button type="button" href="javascript:" *ngIf="showRevision" class="icon-link btn btn-md btn-success cancel" [disabled]="view && view.files.length < 1" (click)="uploadRevision()">Upload Revision</button>
			<!--
			<button type="button" href="javascript:" *ngIf="view && !view.isRevision" [disabled]="!formValid" (click)="upload(view)">Upload</button>
			<button type="button" href="javascript:" *ngIf="view && view.isRevision" [disabled]="view && view.files.length < 1" (click)="uploadRevision(view)">Upload</button>
			-->
		</div>
		<button [style.opacity]="!showUpload && !showRevision ? 1 : 0" *ngIf="permissions.indexOf('Create') > -1 && uploadClass !== -1" type="button" class="btn btn-primary btn-md right" (click)="showUpload = !showUpload">Upload</button>
		<table [style.opacity]="!showUpload && !showRevision ? 1 : 0" class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted">
			<thead>
				<tr>
					<th id="name">Name</th>
					<th id="revisions" *ngIf="permissions.indexOf('Update Document') > -1"></th>
					<th id="share"></th>
					<th id="download"></th>
					<th id="delete" *ngIf="permissions.indexOf('Delete') > -1"></th>
					<th id="coauthor" *ngIf="coauthorEnabled && permissions.indexOf('Coauthor') > -1"></th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let sharedItem of sharedItems; let i = index;">
					<mfiles-row *ngIf="sharedItem.display" [autoSelect]="autoSelect && sharedItem.id == autoSelectId" [item]="sharedItem" [doNotDownload]="configService.supportedVideoFormats.indexOf(sharedItem?.extension?.toLowerCase()) > -1" [view]="dataView" [objectTypes]="objectTypes" [objectTypeIcons]="objectTypeIcons" [icon]="objectTypeIcons[sharedItem.type] ? objectTypeIcons[sharedItem.type].changingThisBreaksApplicationSecurity : ''" [external]="true" [token]="token" [permissions]="permissions" [revisionsEnabled]="permissions.indexOf('Update Document') > -1" [coauthorEnabled]="coauthorEnabled && permissions.indexOf('Coauthor') > -1" [deleteEnabled]="permissions.indexOf('Delete') > -1" [isAuthed]="isAuthed" (externalObjectSelected)="externalObjectSelected(sharedItem, i)" (uploaderOpened)="openCheckinForRevision($event.itemId, $event.fileIndex)" (deleteClicked)="openVerifyDelete($event.name, $event.itemType, $event.itemId)" (loadIcon)="getIcon($event)">
					</mfiles-row>
				</ng-container>
			</tbody>
		</table>
	</div>
	<!--
	<div class="resize-container" #resizeContainer>
		<div #resizeContainerHandle>...</div>
	</div>
	<div class="right-container" #rightContainer>

		<div class="sidebar">
			<div class="viewer" (click)="displayTab='viewer'" [ngClass]="displayTab === 'viewer' ? 'selected' : ''">Viewer
			</div>
			<div class="metadata" (click)="displayTab='metadata'" [ngClass]="displayTab === 'metadata' ? 'selected' : ''">Metadata
			</div>
			<div *ngIf="permissions.indexOf('Comment') > -1" class="comments" (click)="displayTab='comments'" [ngClass]="displayTab === 'comments' ? 'selected' : ''">
				Comments ({{ commentsCount }})
			</div>
		</div>
		<div class="fill" [ngClass]="{'hidden': displayTab !== 'metadata', 'loading in': loading}">
			<div *ngIf="!(sharedItem?.properties.length > 0)" class="widget-message">
				<span *ngIf="!sharedItem"><em href="javascript:" class="far fa-file-alt"></em> &nbsp; Metadata</span>
				<span *ngIf="sharedItem" class="error-message">No metadata to display</span>
			</div>
			<div class="custom-scroll" *ngIf="sharedItem?.properties.length > 0">
				<ng-container *ngIf="!editingProperties">
					<div class="metadata-button-container" *ngIf="permissions.indexOf('Update Metadata') > -1">
						<button *ngIf="permissions.indexOf('Update Metadata') > -1" type="button" class="btn btn-primary btn-md update" (click)="startUpdate()">Edit Metadata</button>
					</div>
					<div class="metadata-table-outer">
						<table class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted metadata-table">
							<thead>
								<tr>
									<th id="property">Property</th>
									<th id="value">Value</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let prop of sharedItem.properties">
									<td class="title"><span>{{prop.name}}</span></td>
									<td><span>{{prop.value.displayValue}}</span></td>
								</tr>
							</tbody>
						</table>
					</div>
				</ng-container>
				<ng-container *ngIf="editingProperties">
					<div class="metadata-button-container">
						<button type="button" class="btn btn-primary btn-md" (click)="cancelUpdate()">Cancel</button>
						<button type="button" class="btn btn-primary btn-md" (click)="saveUpdate()">Save</button>
					</div>
					<div class="metadata-table-outer edit">
						<div *ngFor="let field of editProperties">
							<ng-container *ngIf="field.editable">
								<label [for]="field.propertyDef" class="form-label">{{field.name}}</label>

								<div *ngIf="field.value.dataType === 1">
									<input type="text" [id]="field.propertyDef" [(ngModel)]="formData[field.propertyDef]" class="form-control">
								</div>
								<div *ngIf="field.value.dataType === 2 || field.value.dataType === 3">
									<input type="number" [id]="field.propertyDef" [(ngModel)]="formData[field.propertyDef]" class="form-control">
								</div>
								<div *ngIf="field.value.dataType === 5 || field.value.dataType === 6 || field.value.dataType === 7">
									<input type="text" [id]="field.propertyDef" [(ngModel)]="formData[field.propertyDef]" bsDatepicker class="form-control">
								</div>
								<div *ngIf="field.value.dataType === 8">
									<div class="switch-row">
										<div class="switch">
											<input [id]="'completed' + i" type="checkbox" [(ngModel)]="formData[field.propertyDef]" class="switch-input">
											<label [for]="'completed' + i" class="switch-label">Switch</label>
										</div>
									</div>
								</div>
								<div *ngIf="field.value.dataType === 9">
									<ng-select class="typeahead form-typeahead" [items]="optionLists[field.propertyDef].items ? optionLists[field.propertyDef].items : []" [clearable]="false" bindLabel="name" bindValue="id" [id]="field.propertyDef" [(ngModel)]="formData[field.propertyDef]">
									</ng-select>
								</div>
								<div *ngIf="field.value.dataType === 10">
									<ng-select class="typeahead form-typeahead" [items]="optionLists[field.propertyDef].items ? optionLists[field.propertyDef].items : []" [clearable]="false" bindLabel="name" bindValue="id" [id]="field.propertyDef" (change)="multiselectChanged(field, $event)">
									</ng-select>
									<div class="multiselect-value-row" *ngIf="!field.hidden">
										<span *ngFor="let value of formData[field.propertyDef]; let i = index">
											<span class="multiselect-value">{{value.displayValue}} <em class="fas fa-times" (click)="removeMultiselect(field, i)"></em></span>
										</span>
									</div>
								</div>
								<div *ngIf="field.value.dataType === 13">
									<textarea [id]="field.propertyDef" [(ngModel)]="formData[field.propertyDef]" class="form-control"></textarea>
								</div>
							</ng-container>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="fill" [ngClass]="{ 'hidden': displayTab !== 'viewer', 'loading in': loadingPrizm, 'no-radius': viewerEnabled && sharedItem?.type === 0 && !loadingPrizm && streamingVideoURL == '' && configService.unsupportedPrizmFormats.indexOf(sharedItem?.extension.toLowerCase()) == -1}">
			<div *ngIf="!sharedItem && !error" class="widget-message" [title]="errorMessage">
				<span><em _ngcontent-wsj-c5="" href="javascript:" class="far fa-file-image"></em> &nbsp; Viewer</span>
			</div>
			<div *ngIf="error" class="widget-message" [title]="errorMessage">
				<span *ngIf="error" class="error-message">Could not load the preview</span>
			</div>
			<div class="widget-message disabled-viewer" *ngIf="(sharedItem && !error && !viewerEnabled && (!embedable || !downloadedUrl)) || (configService.unsupportedPrizmFormats.indexOf(sharedItem?.extension.toLowerCase()) > -1 && streamingVideoURL == '' && configService.supportedVideoFormats.indexOf(sharedItem?.extension.toLowerCase()) == -1)">
				<em class="icon icon-file icon-other preview-icon" [ngClass]="sharedItem?.extension !== undefined ? 'icon-' + sharedItem?.extension : ''"></em>
			</div>
			<div class="the-preview" *ngIf="sharedItem && !error && configService.unsupportedPrizmFormats.indexOf(sharedItem?.extension.toLowerCase()) == -1 && (viewerEnabled || (embedable && downloadedUrl))">
				<div class="the-document">
					<div *ngIf="viewerEnabled && sharedItem?.type === 0" class="full-width viewer pccv" id="prizmviewer">
					</div>
					<div *ngIf="viewerEnabled && sharedItem?.type !== 0" class="full-width viewer pccv disabled-viewer object">
						<div class="object-icon-container" *ngIf="objectTypeIcons[sharedItem?.type]">
							<em class="icon icon-file icon-other preview-icon" [ngClass]="sharedItem?.extension !== undefined ? 'icon-' + sharedItem?.extension : ''" [ngStyle]="{'background': 'url(' + objectTypeIcons[sharedItem?.type].changingThisBreaksApplicationSecurity + ') no-repeat', 'background-size': 'contain'}"></em>
						</div>
					</div>
					<div *ngIf="!viewerEnabled" class="full-width viewer pccv disabled-viewer">
						<embed *ngIf="embedable && downloadedUrl" [src]="downloadedUrl" width="100%" height="100%" />
					</div>
				</div>
			</div>
			<div *ngIf="streamingVideoURL !== ''" class="auto-resizable-iframe">
				<div>
					<iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" type="text/html" [src]="streamingVideoURL | safeUrl"></iframe>
				</div>
			</div>
			<div *ngIf="streamingVideoURL == '' && configService.supportedVideoFormats.indexOf(sharedItem?.extension.toLowerCase()) > -1" class="auto-resizable-iframe">
				<div>
					<video [src]="downloadedUrl" width="100%" height="auto" controls>
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
		</div>
		<div class="fill" [ngClass]="{ 'hidden': displayTab !== 'comments', 'loading in': loadingComments }">
			<div class="widget-message" *ngIf="!sharedItem">
				<em href="javascript:" class="far fa-comments"></em> &nbsp; Comments
			</div>
			<div class="metadata-table-outer" *ngIf="sharedItem">
				<div class="comments">
					<div class="rootCommentResponse">
						<form (ngSubmit)="onCommentSubmit($event, commentArea)">
							<textarea #commentArea name="comment" placeholder="Enter a comment..." class="form-control"></textarea>
							<a href="" (click)="clearCommentForm(commentArea)"><button type="reset">Cancel</button></a>
							<a><button type="submit">Reply</button></a>
						</form>
					</div>
					<app-comments *ngFor="let comment of comments" (newComment)="onNewComment($event)" [comment]="comment" [item]="item"></app-comments>
				</div>
			</div>
		</div>

	</div>
	-->

</div>

<ng-template #deleteDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-trash"></em> Delete</h5>
	</div>
	<div class="modal-body share-dialog">
		Are you sure you want to delete {{ deleteName }}?
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" (click)="deleteObject()">Delete</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>

<ng-template #viewerModal>
	<div class="modal-body">
		<div class="viewer-container">
			<app-widget-viewer [widgetSettings]="viewerModalSettings"></app-widget-viewer>
		</div>
	</div>
	<div class="modal-footer message-footer">
		<div class="col-md-6 left">
			<button class="btn btn-light btn-md" (click)="prevFileClicked()" *ngIf="sharedItems.length > 0" [disabled]="selectedDocumentIndex == 0">Prev</button>
			<button class="btn btn-light btn-md" (click)="nextFileClicked()" *ngIf="sharedItems.length > 0" [disabled]="selectedDocumentIndex == (sharedItems.length-1)">Next</button>
		</div>
		<div class="col-md-6">
			<button class="btn btn-light btn-md" (click)="closeViewerModal()">Close</button>
		</div>
	</div>
</ng-template>