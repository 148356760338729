export class Property {
	public id: number;
	public name: string;
	public dataType: number;
	public valueList: number;
	public required: boolean;
	public value: string;
	public hasStaticFilter: boolean;
	public staticFilters: StaticFilter[];
	public userPermission?: string;
}

class StaticFilter {
	public propertyDefOrObjectTypeArray: PropertyDefOrObjectTypeArray;
	public typedValue: TypedValue;
}

class PropertyDefOrObjectTypeArray {
	public propertyDef: boolean;
	public id: number;
}

class TypedValue {
	public value: any;
	public hasValue: boolean;
	public displayValue: string;
	public serializedValue: string;
	public dataType: number;
}
