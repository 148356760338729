import { Lookup } from "./lookup.model";

export class MFilesProperty {
	public id: number;
	public name: string;
	public displayID: string;
	public typedValue: Value;
	public value: Value;
	public propertyDef: number;
	public contentType: number;
	public required: boolean;
	public editable: boolean;

	constructor(propertyDef: number, dataType: number, value?: string) {
		this.propertyDef = propertyDef;
		this.typedValue = this.value = new Value();
		this.typedValue.dataType = this.value.dataType = dataType;
		if (value && value !== null && value.length > 0) {
			if (dataType == 8) {
				if (typeof this.typedValue.value === 'boolean') {
					this.typedValue.value = this.value.value = value === 'true';
				}
			} else if (dataType == 9) {
				this.typedValue.lookup = this.value.lookup = new Lookup(parseInt(value));
			} else if (dataType == 10) {
				this.typedValue.lookups = this.value.lookups = [];
				const values = value.split(",");
				for (const lookupValue of values) {
					const lookup = new Lookup(parseInt(lookupValue));
					this.typedValue.lookups.push(lookup);
					this.value.lookups.push(lookup);
				}
			} else {
				this.typedValue.value = this.value.value = value;
				this.typedValue.displayValue = this.value.displayValue = value;
			}
			this.typedValue.hasValue = this.value.hasValue = true;
		}
		this.typedValue.hasAutomaticPermission = this.value.hasAutomaticPermission = true;
	}
}

class Value {
	public value: any;
	public hasValue: boolean;
	public displayValue: string;
	public serializedValue: string;
	public dataType: number;
	public sortingKey: string;
	public hasAutomaticPermission: boolean;
	public lookup: Lookup;
	public lookups: Lookup[] = [];
}