import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ParamMap } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { Subject } from 'rxjs';
import { ShareLink } from '../models/share-link.model';


@Injectable()
export class AuthService {
	public activeUserChanged = new Subject<string>();
	public logoutOccurred = new Subject<string>();
	public autoSaveTriggered = new Subject<boolean>();
	public cookiesAccepted = new Subject<boolean>();
	userPermissions = {};
	project = '';
	name = '';
	fullName = '';
	isAdministrator = false;

	logoUrl: SafeUrl[] = [];
	loginLogo: SafeUrl[] = [];
	backgroundUrl: any[] = [];
	loginConfigs: any[];

	public loginFailed = -1;
	public loading = new Subject<boolean>();
	public isUploading = false;

	constructor(
		private userIdle: UserIdleService
	) { }

	logout() {
		this.userIdle.stopTimer();

		localStorage.removeItem('activeUser');
		localStorage.removeItem('token');
		localStorage.removeItem('jwt');
		localStorage.removeItem('userType');
		localStorage.removeItem('username');
		localStorage.removeItem('fullName');
		sessionStorage.removeItem('activeSession');

		this.setUserPermissions({}, '', false);
		this.loginFailed = -1;

		this.logoutOccurred.next('');
	}

	getActiveUsername() {
		if (this.name === '') {
			this.name = localStorage.getItem('username');
		}
		return this.name;
	}

	getFullName() {
		if (this.fullName === '') {
			if (localStorage.getItem('fullName')) {
				return localStorage.getItem('fullName');
			}
		} else {
			return this.fullName;
		}
	}

	getToken(name: string) {
		let token = localStorage.getItem(name);
		if (token === null) {
			token = '';
		}
		return token;
	}

	setToken(name: string, token: string) {
		localStorage.setItem(name, token);
	}

	isAuthenticated() {
		if (this.name === '') {
			this.name = localStorage.getItem('username');
		}
		return sessionStorage.getItem('activeSession') && this.name ? true : false;
	}

	isExternalUser() {
		let externalUser = false;
		if (localStorage.getItem('userType') && localStorage.getItem('userType') === '2') {
			externalUser = true;
		}
		return externalUser;
	}

	isAdmin() {
		return this.isAdministrator;
	}

	setUserPermissions(userPermissions: any, username: string, isAdmin: boolean, fullName?: string) {
		this.userPermissions = userPermissions;

		if (userPermissions.userType) {
			// 1 = M-Files user
			// 2 = External user
			localStorage.setItem('userType', userPermissions.userType);
		}

		this.isAdministrator = isAdmin;
		this.name = username;
		this.fullName = fullName ? fullName : '';
		localStorage.setItem('username', this.name);
		localStorage.setItem('fullName', this.fullName);
		sessionStorage.setItem('activeSession', '1');
		if (userPermissions) {
			this.activeUserChanged.next('');
		}
	}

	getProject() {
		return this.project;
	}

	setProject(project: string) {
		this.project = project;
	}

	setWorkspace(workspace: number) {
		localStorage.setItem('workspace', workspace + '');
	}

	updateLoading(loading: boolean) {
		this.loading.next(loading);
	}

	hasAccess(component: string) {
		return this.userPermissions[component] === true;
	}

	doLoginFailed(error: number) {
		this.loginFailed = error;
	}

	setActiveShareLink(shareLink: ShareLink) {
		this.setToken('share', JSON.stringify(shareLink));
	}

	getActiveShareLink() {
		if (this.getToken('share')) {
			return JSON.parse(this.getToken('share'));
		} else {
			return { token: '' };
		}
	}

	ping() {
		this.autoSaveTriggered.next(true);
	}

	canAccessParams(params: ParamMap) {
		let result = true;
		params.keys.forEach((key) => {
			switch (key) {
				case 'action':
					result = this.isAdministrator === true;
					break;

				// If there are query params that are specific to admins, such as the 'action' param,
				// create a case for them similar to the 'action' case above.

				default:
					break;
			}
		});
		return result;
	}
}
