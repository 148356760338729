import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard {

	constructor(
		private authService: AuthService,
		private router: Router
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		if (this.authService.isAuthenticated()) {

			return true;

		} else {
			const queryParams = {
				url: state.url
			};
			if (route.queryParams.workspace) {
				queryParams['workspace'] = route.queryParams.workspace;
			}

			this.router.navigate(['/login'], { queryParams });

			return false;
		}
	}

}
