import { animate, style, transition, trigger } from '@angular/animations';
import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SessionStorage } from 'ngx-store';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Subscription, concatMap, from, mergeMap, tap } from 'rxjs';
import { MFilesService } from 'src/app/services/mfiles.service';
import { CommentObject } from '../models/comment.model';
import { ShareLink } from '../models/share-link.model';
import { AuthService } from '../services/auth.service';
import { ConfigurationService } from '../services/configuration.service';
import { ShareLinkService } from '../services/share-link.service';
import { UploaderComponent } from '../upload/uploader/uploader.component';


declare var $: any;

@Component({
	selector: 'app-file',
	templateUrl: './file.component.html',
	styleUrls: ['./file.component.css'],
	animations: [
		trigger(
			'myAnimation',
			[
				transition(
					':enter', [
					style({ opacity: 0 }),
					animate('0.1s 100ms ease-out', style({ 'opacity': 1 })),
				]
				),
				transition(
					':leave', [
					style({ 'opacity': 1 }),
					animate('0.1s 100ms ease-out', style({ 'opacity': 0 }))
				]
				)]
		)
	]
})
export class FileComponent implements OnInit {
	modalRef: BsModalRef;
	@ViewChild('coauthorModal') private coauthorModal: TemplateRef<any>;
	@ViewChild('uploadProgressModal') private uploadProgressModal: TemplateRef<any>;

	@ViewChild(UploaderComponent) uploader: UploaderComponent;
	files: any[] = [];
	currentFile: number;
	formValues: any[] = [];

	loading = false;
	item: number;
	extension: string;
	revision: number;
	fileIndex = 0;

	shares: ShareLink[] = [];

	viewerEnabled = false;
	viewerProxy = '';
	enableAnnotations = false;
	isBasicViewer = false;

	finalizeEnabled = false;
	finalized = false;
	finalizeField: number;

	completedEnabled = false;
	completed = false;
	completedCriteriaField: number;
	completedField: number;

	vaultProperties = [];
	objectTypes = {};
	objectLists = {};
	completeCriteria = [];
	loaded = false;

	properties = [];
	revisions = [];
	objectFiles = [];

	filename = '';
	comments: CommentObject[];
	commentsCount = 0;
	loadingComments = false;

	xViewSessionId: any;

	@SessionStorage('viewer') viewer;
	prizmviewer: any;

	showMetadata = true;
	showUpload = false;
	activeTab = 'metadata';

	sourcePage = -1;
	query: string;
	path: string;

	commentsEnabled: boolean;
	coauthorEnabled: boolean;
	coauthored = false;

	canDownload = false;
	canAnnotate = false;
	canDelete = false;

	embedable = false;
	downloadedUrl: SafeUrl;

	uploadsCommitting = false;
	uploadProgressStats: any = {};
	microserviceUploadSubscription: Subscription;

	constructor(
		private modalService: BsModalService,
		private mfilesService: MFilesService,
		private route: ActivatedRoute,
		private titleService: Title,
		private zone: NgZone,
		public authService: AuthService,
		private domSanitizer: DomSanitizer,
		private toastr: ToastrService,
		private configService: ConfigurationService,
		private shareLinkService: ShareLinkService,
		private router: Router
	) { }

	ngOnInit() {
		// TODO: Make this configurable (role based instead of user based).
		if (this.authService.getActiveUsername() && this.authService.getActiveUsername().toLowerCase() === 'alan.stone') {
			this.showMetadata = false;
			this.activeTab = 'comments';
		}

		this.configService.list(false).subscribe((result) => {
			for (const config of result) {
				if (config.name === 'finalizeEnabled') {
					this.finalizeEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'finalizeField') {
					this.finalizeField = parseInt(config.value, 10);
				} else if (config.name === 'completedEnabled') {
					this.completedEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'completedCriteriaField') {
					this.completedCriteriaField = parseInt(config.value, 10);
				} else if (config.name === 'completedField') {
					this.completedField = parseInt(config.value, 10);
				} else if (config.name === 'viewerEnabled') {
					this.viewerEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'viewerProxy') {
					this.viewerProxy = config.value;
				} else if (config.name === 'commentsEnabled') {
					this.commentsEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'enableAnnotations') {
					this.enableAnnotations = config.value.toLowerCase() === 'true';
				} else if (config.name === 'coauthorEnabled') {
					this.coauthorEnabled = config.value.toLowerCase() === 'true';
				}
			}

			this.route.params.subscribe((params: Params) => {
				this.item = parseInt(params['file'], 10);
				if (!this.authService.isAuthenticated() && (!this.authService.getActiveShareLink()
					|| this.authService.getActiveShareLink().type !== 'document' || this.authService.getActiveShareLink().resourceId !== this.item)) {
					this.router.navigate(['/login']);
				}

				if (params['fileIndex']) {
					this.fileIndex = params['fileIndex'];
				}

				if (params['viewerType']) {
					this.isBasicViewer = (params['viewerType'] === 'basic');
				}

				this.titleService.setTitle('File: ' + this.item);
				this.loadRevision(-1);

				if (!this.isBasicViewer && this.commentsEnabled) {
					this.loadingComments = true;

					this.mfilesService.getComments(this.item).subscribe((response) => {
						this.comments = response;

						this.setCommentsCount(this.comments);
						this.loadingComments = false;
					});

					this.shareLinkService.fetchShareLinksByTypeAndId('document', this.item).subscribe((response) => {
						this.shares = response;
						for (const share of this.shares) {
							share.sharedUntil = new Date(share.sharedUntil);
						}
					});
				}
			});

			this.route.queryParams.subscribe(params => {
				if (params.query) {
					this.query = params.query;
				}
				if (params.path) {
					this.path = params.path;
				}
				if (params.sourcePage) {
					this.sourcePage = params.sourcePage;
				}
			});
		});


		this.mfilesService.getVaultProperties().subscribe((result) => {
			const properties: any = result.body;
			this.vaultProperties = properties;
			this.vaultProperties.push({ id: -1, name: 'Revision', dataType: 2 });
		});
	}

	setCommentsCount(comments: CommentObject[]) {
		this.commentsCount = 0;
		if (comments.length > 0) {
			this.commentsCount = comments[0].commentCount;
		}
	}

	clearCommentForm(addCommentInputField: HTMLTextAreaElement) {
		addCommentInputField.value = '';
	}

	onCommentSubmit(event: any, addCommentInputField: HTMLTextAreaElement) {
		this.authService.updateLoading(true);
		this.loadingComments = true;

		this.mfilesService.postComment(this.item, null, event.target.comment.value).subscribe((result) => {
			this.comments = result;
			this.authService.updateLoading(false);
			this.setCommentsCount(this.comments);
			this.clearCommentForm(addCommentInputField);
			this.loadingComments = false;
		});

		this.toastr.success(`Comment successfully added.`);
	}

	onNewComment(event: any) {
		this.loadingComments = true;

		this.mfilesService.postComment(event.id, event.parentComment, event.comment).subscribe((result) => {
			this.comments = result;
			this.setCommentsCount(this.comments);
			this.loadingComments = false;
		});
	}

	download() {
		this.mfilesService.download(0, this.item, -1, 0).subscribe(response => {
			saveAs(response.body, response.headers.get('filename'));
		});
	}

	initPrizmViewer(vsId) {
		const helper = {
			documentID: vsId,
			imageHandlerUrl: this.viewerProxy,
			template: {
				viewer: require('!raw-loader!./prizmFiles/templates/viewerTemplate.html').default,
				contextMenu: require('!raw-loader!./prizmFiles/templates/contextMenuTemplate.html').default,
				printOverlay: require('!raw-loader!./prizmFiles/templates/printOverlayTemplate.html').default,
				unsavedChangesOverlay: require('!raw-loader!./prizmFiles/templates/unsavedChangesOverlayTemplate.html').default,
				overwriteOverlay: require('!raw-loader!./prizmFiles/templates/overwriteOverlayTemplate.html').default,
				print: require('!raw-loader!./prizmFiles/templates/printTemplate.html').default,
				comment: require('!raw-loader!./prizmFiles/templates/commentTemplate.html').default,
				esignOverlay: require('!raw-loader!./prizmFiles/templates/esignOverlayTemplate.html').default,
				downloadOverlay: require('!raw-loader!./prizmFiles/templates/downloadOverlayTemplate.html').default,
				imageStampOverlay: require('!raw-loader!./prizmFiles/templates/imageStampOverlayTemplate.html').default,
				hyperlinkMenu: require('!raw-loader!./prizmFiles/templates/hyperlinkMenuTemplate.html').default,
				pageRedactionOverlay: require('!raw-loader!./prizmFiles/templates/pageRedactionOverlayTemplate.html').default,
				copyOverlay: require('!raw-loader!./prizmFiles/templates/copyOverlayTemplate.html').default,
				redactionReason: require('!raw-loader!./prizmFiles/templates/redactionReasonTemplate.html').default
			},
			icons: require('!raw-loader!./prizmFiles/svg-icons.svg.html').default,
			language: require('./prizmFiles/en-US.json'),
			debug: true
		};

		this.zone.runOutsideAngular(() => {

			if (this.prizmviewer) {
				this.prizmviewer.destroy();
			}

			const viewerOptions = {
				uiElements: {
					redactTab: false,
					annotateTab: this.canAnnotate,
					esignTab: true,
					printing: true,
					searchTab: true,
					viewTab: true,
					download: false
				},
				documentID: helper.documentID,
				template: helper.template,
				language: helper.language,
				icons: helper.icons,
				imageHandlerUrl: helper.imageHandlerUrl
			};

			if (this.query) {
				viewerOptions['predefinedSearch'] = {
					searchOnInit: true,
					globalOptions: {
						matchCase: false,
						endsWith: false,
						beginsWith: false,
						matchWholeWord: false
					},
					terms: [{
						searchTerm: this.query,
						selected: true,
						options: {
							matchWholeWord: false
						}
					}]
				};
			}

			this.prizmviewer = $('#prizmviewer').pccViewer(viewerOptions);

		});

		this.prizmviewer.viewerControl.on('ViewerReady', () => {
			if (this.enableAnnotations) {
				$('.pcc-js-save-annotations').on('click', () => {
					this.saveAnnotationsHandler();
				});
				$('.pcc-js-reload-document').on('click', () => {
					this.loadRevision(-1);
				});

				this.loadAnnotations();
			}
		});
	}

	loadAnnotations() {
		this.mfilesService.getAnnotations(this.item).subscribe((result) => {
			this.loading = false;
			this.prizmviewer.viewerControl.deserializeMarks(result);
		});
	}

	saveAnnotationsHandler() {
		// Determine if there are existing annotations in the database. If there are,
		// ensure ours are newer before overriding.
		this.mfilesService.getProperties(0, this.item, -1).subscribe((response) => {
			const latestVersion = response.body.objVer.version;
			if (latestVersion > this.revision) {
				this.toastr.warning('There is a newer version of the document. Please refresh.');
			} else {
				this.saveAnnotations();
			}
		});
	}

	saveAnnotations() {
		this.loading = true;
		// Save the annotations.
		const allMarks = this.prizmviewer.viewerControl.getAllMarks();

		if (allMarks.length > 0) {
			// Annotations found.
			this.prizmviewer.viewerControl.serializeMarks(allMarks).then(markObjects => {
				this.mfilesService.updateAnnotations(this.item, markObjects).subscribe(() => {
					this.toastr.success('The annotations were saved successfully.');
					this.loadRevision(-1);
					this.loadAnnotations();
				});
			});
		} else {
			this.mfilesService.updateAnnotations(this.item, []).subscribe(() => {
				this.toastr.success('The annotations were saved successfully.');
				this.loadRevision(-1);
				this.loadAnnotations();
			});
		}
	}

	onUploadQueueChange(queue: any) {
		this.files = queue;
		this.currentFile = 0;

		if (queue.length > 0) {
			this.uploader.selectedItem = queue[0];
		}
	}

	upload() {
		this.mfilesService.cancelMicroserviceUpload.subscribe(() => {
			this.microserviceUploadSubscription.unsubscribe();
			this.loading = false;
		});

		this.mfilesService.isMicroserviceAvailable().subscribe((available: boolean) => {
			if (available) {
				this.mfilesService.microserviceUploadProgress = this.uploadProgressStats;
				this.openModal(this.uploadProgressModal, 'md');

				this.microserviceUploadSubscription = from([''])
					.pipe(
						tap(() => this.authService.isUploading = true),
						concatMap((props) => this.mfilesService.prepareUploadRevision(this.item, this.fileIndex)), // Makes the prepareUploadRevision calls in order and returns the result in order.
						mergeMap((result, index) => this.mfilesService.microserviceUpload(result, this.files[this.currentFile].file, true)
							.catch(err => { // If an error occurs while the file is uploading to the microservice, this catches it and ensures the rest of the uploads don't get canceled.
								if (err.file) {
									this.uploadProgressStats[err.file.name] = 'ERROR'; // UI looks for this value and uses it to determine if the error message should be shown. Don't remove it.
								}
								return EMPTY;
							}), 3), // Starts uploading the files to the microservice. The 3 tells angular to limit the number of concurrent uploads to 3.
						mergeMap((result) => result, 1), // Intermediate step since microserviceUpload() returns a Promise with an Observable in it. This just returns the enclosed Observable.
						tap(() => {
							this.authService.isUploading = false;
							this.mfilesService.emitUploadProgress('all', 100);
						})) // The upload to M-Files is now 100% complete.
					.subscribe({
						next: (res) => { },
						error: (err) => { },
						complete: () => {
							setTimeout(() => {
								this.closeModal();

								this.showUpload = false;

								this.loadRevision(-1);
								this.mfilesService.microserviceUploadProgress = {};
								this.uploadProgressStats = {};
							}, 1500);
						}
					});
			} else {
				this.mfilesService.uploadRevision(this.item, this.fileIndex, this.files[this.currentFile].file).subscribe((uploadResult) => {
					this.showUpload = false;

					this.loadRevision(-1);
				});
			}
		});
	}

	closeModal() {
		if (this.modalRef) {
			this.modalRef.hide();
			this.modalRef = null;
		}
	}

	loadFile(fileIndex: number) {
		this.fileIndex = fileIndex;

		if (this.viewerEnabled) {
			this.loading = true;
			this.mfilesService.getPreviewId(this.item, this.revision, this.fileIndex).subscribe((result) => {
				this.xViewSessionId = result;
				this.initPrizmViewer(result);
				this.loading = false;
			});
		}
	}

	loadRevision(revision: number) {
		this.embedable = false;
		this.downloadedUrl = undefined;
		this.loading = true;

		this.mfilesService.getProperties(0, this.item, revision).subscribe((response) => {
			this.updateProperties(response, revision);

			if (this.viewerEnabled) {
				this.mfilesService.getPreviewId(this.item, revision, this.fileIndex).subscribe((result) => {
					this.xViewSessionId = result;
					this.initPrizmViewer(result);
				});
			} else if (!this.viewerEnabled && this.configService.emedableFormats.indexOf(this.extension.toLowerCase()) > -1) {
				this.embedable = true;
				this.downloadFileUrl(this.item, revision);
			}
		});

		this.mfilesService.getFiles(0, this.item, revision).subscribe((response) => {
			this.objectFiles = response.body;
		});
	}

	updateProperties(response: any, revision: number) {
		const result: any = response.body;
		if (result) {
			this.properties = result.properties;
			this.extension = result.files[0].extension;
			this.revision = result.objVer.version;
			this.coauthored = result.coauthored;
			this.canDownload = result.download;
			this.canAnnotate = result.annotate;
			this.canDelete = result.delete;
			if (revision === -1) {
				this.revisions = Array(this.revision).fill(0).map((x, i) => i + 1).reverse();
			}

			for (const property of this.properties) {
				if (property.propertyDef === 0) {
					this.filename = property.value.displayValue;
				} else if (this.finalizeEnabled && property.propertyDef === this.finalizeField) {
					this.finalized = property.value.displayValue === 'Yes';
				} else if (this.completedEnabled && property.propertyDef === this.completedField) {
					this.completed = property.value.displayValue === 'Yes';
				} else if (this.completedEnabled && property.propertyDef === this.completedCriteriaField) {
					this.completeCriteria = JSON.parse(property.value.displayValue);
				}
			}

			if (this.completedEnabled) {
				for (const criterion of this.completeCriteria) {
					this.checkCriteriaField(criterion.id);
					const dataType = this.objectTypes[criterion.id];
					if (dataType === 5 || dataType === 6 || dataType === 7) {
						criterion.value = new Date(criterion.value);
					}
				}
			}
			this.checkCriteriaField(-1);
		}

		this.loading = false;
		this.loaded = true;
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.show(
			template,
			{ class: 'modal-' + size, backdrop: 'static' }
		);
	}

	finalize() {
		this.loading = true;
		this.modalRef.hide();
		this.mfilesService.finalize(0, this.item, -1).subscribe((response) => {
			this.updateProperties(response, -1);
		});
	}


	unfinalize() {
		this.loading = true;
		this.modalRef.hide();
		this.mfilesService.unfinalize(0, this.item, -1).subscribe((response) => {
			this.updateProperties(response, -1);
		});
	}

	addCriteria() {
		this.completeCriteria.push({ id: -1, comparison: 'eq', value: '' });
	}

	removeCriteria(i: number) {
		this.completeCriteria.splice(i, 1);
	}

	criteriaFieldChanged(e: any, i: number) {
		const fieldId = e.id;
		this.checkCriteriaField(fieldId);

		this.completeCriteria[i].value = '';
	}

	checkCriteriaField(fieldId: number) {
		if (!this.objectTypes[fieldId]) {
			for (const property of this.vaultProperties) {
				if (property.id === fieldId) {
					if (!this.objectTypes[fieldId]) {
						this.objectTypes[fieldId] = property.dataType;
					}
					if (property.dataType === 9 || property.dataType === 10) {
						this.mfilesService.getValueListOptions(property.valueList).subscribe((result) => {
							this.objectLists[fieldId] = result.body['Items'];
						});
					}
					break;
				}
			}
		}
	}

	setCriteria() {
		this.loading = true;
		this.modalRef.hide();
		for (const criteria of this.completeCriteria) {
			const dataType = this.objectTypes[criteria.id];
			if (dataType === 5 || dataType === 6 || dataType === 7) {
				criteria.value = criteria.value.getTime();
			}
		}

		this.loaded = false;
		this.mfilesService.updateCompleteCriteria(JSON.stringify(this.completeCriteria), 0, this.item).subscribe((response) => {
			this.updateProperties(response, -1);
		});
	}

	addShareLink() {
		const share = new ShareLink();
		share.sharedBy = this.authService.getActiveUsername();
		share.sharedOn = new Date();
		share.type = 'document';
		share.resourceId = this.item;
		share.permissions = 'view';

		this.shares.push(share);
	}

	copyShareLink(shareLink: ShareLink) {
		navigator['clipboard'].writeText(window.location.origin + '/#/share/' + shareLink.token).then(() => {
			this.toastr.success('Share link copied');
		});
	}

	removeShareLink(index: number) {
		const shareLink = this.shares[index];
		this.shares.splice(index, 1);
		if (shareLink.id) {
			this.shareLinkService.deleteShareLink(shareLink.id).subscribe((response) => {
				// intentionally blank
			});
		}
	}

	updateShareLinks() {
		this.loading = true;
		this.shareLinkService.updateShareLinks(this.shares).subscribe((response) => {
			this.shares = response;
			for (const share of this.shares) {
				share.sharedUntil = new Date(share.sharedUntil);
			}
			this.loading = false;
			this.toastr.success('Share links updated');
		});
	}

	checkSharePermissions(permission: string) {
		let hasPermissions = false;
		const authShareLink = this.authService.getActiveShareLink();
		if (authShareLink.resourceId === this.item) {
			if (permission === 'download') {
				hasPermissions = ['download', 'edit', 'upload', 'delete'].includes(authShareLink.permissions);
			} else if (permission === 'edit') {
				hasPermissions = ['edit', 'upload', 'delete'].includes(authShareLink.permissions);
			} else if (permission === 'upload') {
				hasPermissions = ['upload', 'delete'].includes(authShareLink.permissions);
			} else if (permission === 'delete') {
				hasPermissions = ['delete'].includes(authShareLink.permissions);
			}
		}
		return hasPermissions;
	}

	back() {
		const queryParams = {};
		if (this.query) {
			queryParams['query'] = this.query;
		}
		if (this.path) {
			queryParams['path'] = this.path;
		}
		this.router.navigate(['/page', this.sourcePage], { queryParams });
	}

	isCoauthorItem() {
		return this.mfilesService.coauthorExtensions.indexOf(this.extension) > -1;
	}

	coauthor() {
		if (this.coauthored) {
			this.openModal(this.coauthorModal, 'md');
		} else {
			this.mfilesService.startCoauthoring(this.item);
		}
	}

	resumeCoauthor() {
		this.mfilesService.continueCoauthoring(this.item);
	}

	undoCoauthor() {
		this.modalRef.hide();
		this.mfilesService.undoCoauthoring(this.item).subscribe((result) => {
			this.coauthored = !this.coauthored;
		}, (err) => {
			this.mfilesService.undoCoauthoringLink(this.item);
			this.coauthored = !this.coauthored;
		});
	}

	checkinCoauthor() {
		this.modalRef.hide();
		this.mfilesService.endCoauthoring(this.item).subscribe((result) => {
			this.coauthored = !this.coauthored;
		}, (err) => {
			this.mfilesService.endCoauthoringLink(this.item);
			this.coauthored = !this.coauthored;
		});
	}

	downloadFileUrl(itemId: number, revision: number) {
		this.mfilesService.download(0, itemId, revision, this.fileIndex).subscribe((result) => {
			if (result.body && result.body.size !== 0) {
				const reader = new FileReader();
				reader.onloadend = (e) => {
					this.downloadedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
				};
				reader.readAsDataURL(result.body);
			} else {
				this.downloadedUrl = undefined;
			}
		});
	}
}
