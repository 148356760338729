import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';
import { Configuration } from '../models/configuration.model';



@Injectable({
	providedIn: 'root'
})
export class ConfigurationService {
	public emedableFormats = ['html', 'pdf', 'txt', 'png', 'jpg', 'jpeg', 'gif', 'bmp', 'mp4'];
	public unsupportedPrizmFormats = ['exe', 'mov', 'avi', 'apk', 'evtx', 'zip', 'mp4'];
	public supportedVideoFormats = ['mp4'];

	public recent = false;
	public favorites = false;
	public fileShare = false;
	public avatars = false;
	public recentEnabledChanged = new Subject<boolean>();
	public favoritesEnabledChanged = new Subject<boolean>();
	public fileShareEnabledChanged = new Subject<boolean>();
	public avatarsEnabledChanged = new Subject<boolean>();
	public siteNameChanged = new Subject<string>();
	public displaySiteNameInHeaderChanged = new Subject<boolean>();
	public siteLogoChanged = new Subject();
	public siteFaviconChanged = new Subject();
	public navigationLocationChanged = new Subject<string>();

	private defaultHeaders: HttpHeaders = new HttpHeaders().set('Accept', 'application/json');
	requestUrl = `${environment.serviceUrl}/configurations`;
	constructor(
		protected httpClient: HttpClient
	) { }

	list(loadImages: boolean): Observable<any> {
		let params = new HttpParams();
		params = params.set('loadImages', loadImages + '');

		return this.httpClient.get<Array<Configuration>>(this.requestUrl,
			{
				headers: this.defaultHeaders,
				params: params
			}
		);
	}

	get(name: string, workspace: number): Observable<any> {
		let queryParameters = new HttpParams();
		if (workspace) {
			queryParameters = queryParameters.set('workspace', workspace);
		}

		return this.httpClient.get<Configuration>(this.requestUrl + `/${encodeURIComponent(String(name))}`,
			{
				headers: this.defaultHeaders,
				params: queryParameters
			}
		);
	}

	getList(names: string[]): Observable<any> {
		return this.httpClient.post<Configuration>(this.requestUrl + `/configList`,
			names,
			{
				headers: this.defaultHeaders
			}
		);
	}

	getLoginConfigs(): Observable<any> {
		return this.httpClient.get<Configuration[]>(this.requestUrl + `/loginConfigs`,
			{
				headers: this.defaultHeaders
			}
		);
	}

	getImage(name: string, workspace: number): Observable<any> {
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('workspace', workspace);

		return this.httpClient.get(this.requestUrl + `/${encodeURIComponent(String(name))}/image`,
			{
				headers: new HttpHeaders(),
				params: queryParameters,
				responseType: 'blob',
				observe: 'response'
			}
		);
	}

	update(name: string, value: string): Observable<any> {

		if (name === null || name === undefined) {
			throw new Error('Required parameter name was null or undefined when calling update.');
		}

		if (value === null || value === undefined) {
			throw new Error('Required parameter value was null or undefined when calling update.');
		}

		let queryParameters = new HttpParams();
		if (value !== undefined && value !== null) {
			queryParameters = queryParameters.set('value', <any>value);
		}

		return this.httpClient.put<Configuration>(`${environment.serviceUrl}/configurations/${encodeURIComponent(String(name))}`,
			null,
			{
				params: queryParameters,
				headers: this.defaultHeaders
			}
		);
	}

	updateImage(name: string, value: string, file: File): Observable<any> {

		if (name === null || name === undefined) {
			throw new Error('Required parameter name was null or undefined when calling update.');
		}

		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('value', <any>value);

		const formData = new FormData();
		formData.append('image', file);

		return this.httpClient.put<Configuration>(`${environment.serviceUrl}/configurations/${encodeURIComponent(String(name))}/image`,
			formData,
			{
				params: queryParameters,
				headers: this.defaultHeaders
			}
		);
	}

	create(configuration: Configuration): Observable<any> {

		if (configuration === null || configuration === undefined) {
			throw new Error('Required parameter configuration was null or undefined when calling create.');
		}

		return this.httpClient.post<Configuration>(this.requestUrl,
			configuration,
			{
				headers: this.defaultHeaders
			}
		);
	}

	setRecentEnabledChanged(value: boolean) {
		this.recent = value;
		this.recentEnabledChanged.next(value);
	}
	setFavoritesEnabledChanged(value: boolean) {
		this.favorites = value;
		this.favoritesEnabledChanged.next(value);
	}
	setFileShareEnabledChanged(value: boolean): void {
		this.fileShare = value;
		this.fileShareEnabledChanged.next(value);
	}
	setAvatarsEnabledChanged(value: boolean) {
		this.avatars = value;
		this.avatarsEnabledChanged.next(value);
	}
	setSiteNameChanged(value: string) {
		this.siteNameChanged.next(value);
	}
	setDisplaySiteNameInHeaderChanged(value: boolean) {
		this.displaySiteNameInHeaderChanged.next(value);
	}
	setNavigationLocationChanged(value: string) {
		this.navigationLocationChanged.next(value);
	}
}
