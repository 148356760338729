import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, Subscription, forkJoin, from } from 'rxjs';
import { concatMap, mergeMap, tap, toArray } from 'rxjs/operators';
import { Property } from 'src/app/models/property.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LayoutService } from 'src/app/services/layout.service';
import { MFilesService } from 'src/app/services/mfiles.service';
import { PageService } from 'src/app/services/page.service';
import { MFObjectSizePipe } from 'src/app/shared/pipes/mfObjectSize.pipe';
import { PreviewButtonRendererComponent } from './preview-button/preview_button-renderer.component';
import { TypeaheadSelectRendererComponent } from './typeahead-select/typeahead-select.component';

@Component({
	selector: 'app-view-widget',
	templateUrl: './view-widget.component.html',
	styleUrls: ['./view-widget.component.css'],
	animations: [
		trigger(
			'myAnimation',
			[
				transition(
					':enter', [
					style({ opacity: 0 }),
					animate('0.1s 100ms ease-out', style({ 'opacity': 1 })),
				]
				),
				transition(
					':leave', [
					style({ 'opacity': 1 }),
					animate('0.1s 100ms ease-out', style({ 'opacity': 0 }))
				]
				)]
		)
	]
})
export class ViewWidgetComponent implements OnInit, OnDestroy {
	modalRef: BsModalRef;
	@ViewChild('deleteDialog') private deleteDialog: TemplateRef<any>;
	@ViewChild('downloadDialog') private downloadDialog: TemplateRef<any>;
	@ViewChild('uploadProgressModal') private uploadProgressModal: TemplateRef<any>;

	@Input() view: any;
	@Input() inGrid = false;
	@Input() associateSelected = false;
	@Input() inheritSelected = false;
	@Input() autoSubmit = false;
	@Input() associatedProp: number;
	@Input() contextualCheckin = false;
	// @Input() revisionsEnabled = true;
	@Input() defaultClass: number;
	@Input() hasAccessProperties = false;
	@Input() additionalProps: Property[];
	@Input() autopopulateDocName = false;
	@Input() nameProp: number;
	@Input() allowUsersToAddProps = true;
	@Input() widgetEditMode = false;
	@Input() filelessUploads = [];
	@Input() objectTypeClasses = [];
	@Input() conditionalUploadsClasses = [];
	@Input() conditionalUploadsStates = [];
	@Input() workflowStates = [];
	@Input() automaticWorkflowTransitions = false;
	@Input() startingState: number;
	@Input() targetState: number;
	@Input() disableRelatedView = false;
	@Input() massMetadataUpdates = false;
	@Input() uploadsDisabled: boolean = true;
	@Input() documentRevisionsDisabled: boolean = false;
	@Input() documentDownloadsDisabled: boolean = false;
	@Input() documentDeletionsDisabled: boolean = false;
	@Input() overrideClassUsingClassGroupingLevel: boolean = false;
	@Input() pageId: number;
	@Input() widgetId: string;

	@Input() autoSelectType: number;
	@Input() autoSelectId: number;

	@Output() openedView = new EventEmitter<string>();
	@Output() objectType = new EventEmitter<number>();
	@Output() uploadConfirm = new EventEmitter<any>();

	documentSelectionSubscription: Subscription;
	loadViewSubscription: Subscription;

	editMode = false;

	tiles = false;
	favoritesEnabled: boolean;
	coauthorEnabled: boolean;
	completedEnabled = false;
	completedCriteriaField: number;
	completedField: number;
	vaultProperties = [];
	objectTypes = {};
	objectLists = {};
	propValueListMap = {};

	activeType: string;
	activeIndex: number;
	activeView: any;
	completeCriteria = [];

	relatedItems = [];

	animStart = false;
	animDone = true;

	formValid = false;
	formProperties: Property[][] = [];
	filelessObjectType: number;
	filelessClass: number;
	filelessPermissions = [];

	useMfilesIcons = true;
	objectTypeIcons = {};
	page: number;

	selectedItem: any;
	sortRelatedAsc = true;

	associatedObjectType: number;
	associatedObject: number;

	// AG GRID
	agGridColumnDefs = [];
	agGridRowData = [];
	agGridColumnApi: any;
	agGridApi: any;
	copyEvent = {
		value: '',
		column: null
	};
	recentClickedCellColDef: any;
	agGridChangesMade = false;
	changedObjs = {};
	frameworkComponents = {};
	context = {};

	deleteName = '';
	deleteType: number;
	deleteItem: number;

	bidRequestClassId: number;

	downloadLink = '';
	loadingDownloadAll: boolean = false;
	loadingDownloadFolders: boolean = false;

	uploadsCommitting: boolean = false;
	uploadProgressStats: any = {};
	microserviceUploadSubscription: Subscription;
	uploadProgressMessage: string = '';

	alertsEnabled: boolean = false;
	alertTriggers: any[] = [];
	alertFrequencies: any[] = [];
	alertDays: any[] = [];

	initialLoad = true;

	constructor(
		private mfilesService: MFilesService,
		public configService: ConfigurationService,
		private pageService: PageService,
		private router: Router,
		private modalService: BsModalService,
		private domSanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private mfObjectSizePipe: MFObjectSizePipe,
		private datePipe: DatePipe,
		private layoutService: LayoutService,
		private authService: AuthService
	) { }

	ngOnInit() {
		this.view.loading = true;
		this.route.queryParams.subscribe(params => {
			if (params.path) {
				this.view.path = params.path;
			} else if (!this.initialLoad) {
				this.view.path = '';
			}

			if (!this.view.loading) {
				this.loadView(this.view);
			}
		});

		this.favoritesEnabled = this.configService.favorites;

		this.configService.list(false).subscribe((result) => {
			for (const config of result) {
				if (config.name === 'completedEnabled') {
					this.completedEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'completedCriteriaField') {
					this.completedCriteriaField = parseInt(config.value, 10);
				} else if (config.name === 'completedField') {
					this.completedField = parseInt(config.value, 10);
				} else if (config.name === 'coauthorEnabled') {
					this.coauthorEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'useMfilesIcons') {
					this.useMfilesIcons = config.value.toLowerCase() === 'true';
				} else if (config.name === 'alertsEnabled') {
					this.alertsEnabled = config.value.toLowerCase() === 'true';
					this.getAlertsValueLists();
				}
			}
		});

		this.mfilesService.getClassesByAlias(['TI.MConnect.Class.BidRequest']).subscribe(response => {
			this.bidRequestClassId = response.body['TI.MConnect.Class.BidRequest'];
		});

		this.mfilesService.getObjectTypes().subscribe((result) => {
			result.body.forEach((objectType) => {
				this.objectTypes[objectType.id] = objectType;
			});
		});

		this.mfilesService.getVaultProperties().subscribe((response) => {
			const properties: any = response.body;
			this.vaultProperties = properties;
			this.vaultProperties.push({ ID: -1, Name: 'Revision', DataType: 2 });

			this.mfilesService.getVaultValueLists().subscribe((responseValueLists) => {
				const valueLists: any = responseValueLists.body;
				for (const valueList of valueLists) {
					const propsUsingValueList: any[] = this.vaultProperties.filter(
						(prop) =>
							prop.valueList !== 0 &&
							(prop.dataType === 9 || prop.dataType === 10) &&
							prop.valueList === valueList.id
					);
					for (const prop of propsUsingValueList) {
						this.propValueListMap[prop.id] = valueList;
					}
				}
			});

		});

		this.loadView(this.view);

		this.documentSelectionSubscription = this.pageService.documentSelectionOccurred.subscribe((context: any) => {
			let unlockClass = false;
			let unlockState = false;

			if (this.conditionalUploadsClasses.length == 0 && this.conditionalUploadsStates.length == 0) {
				if (this.uploadsDisabled === null || this.uploadsDisabled === undefined) {
					this.uploadsDisabled = false;
				}
			} else {
				this.uploadsDisabled = true;

				if (this.conditionalUploadsClasses.length > 0) {
					for (const uploadClass of this.conditionalUploadsClasses) {
						if (uploadClass.class == context.itemClass) {
							unlockClass = true;
						}
					}
				} else {
					unlockClass = true;
				}

				if (this.conditionalUploadsStates.length > 0) {
					for (const uploadState of this.conditionalUploadsStates) {
						if (uploadState.state == context.workflowState) {
							unlockState = true;
						}
					}
				} else {
					unlockState = true;
				}
				if (unlockClass && unlockState) {
					this.uploadsDisabled = false;
				}
			}
		});

		this.loadViewSubscription = this.mfilesService.loadView.subscribe((widgetId) => {
			if (widgetId === this.widgetId) {
				this.loadView(this.view);
			}
		});

		this.route.params.subscribe((params: Params) => {
			this.page = parseInt(params['page'], 10);
		});

		const classes = [];
		for (const classInfo of this.filelessUploads) {
			classes.push(classInfo.class);
		}
		if (classes.length > 0) {
			this.mfilesService.checkClassPermissions(classes).subscribe((result) => {
				const response: any = result.body;
				this.filelessPermissions = response;
			});
		}
	}

	ngOnDestroy(): void {
		if (this.documentSelectionSubscription) {
			this.documentSelectionSubscription.unsubscribe();
		}
		if (this.loadViewSubscription) {
			this.loadViewSubscription.unsubscribe();
		}
	}

	setTiles(tiles: boolean) {
		this.tiles = tiles;
	}

	// Limitation:
	//  - If read/write/delete access properties are enabled, there are some limitations in how
	//    this will work with the view structure in M-Files. The filtering will always take place
	//    at the first grouping level after the last view. This means that the following structures
	//    will work:
	//      View 1
	//        View 2
	//          Grouping Level 1
	//            Grouping Level 2
	//              Grouping Level 3
	//                etc.
	//    Grouping Level 1 will be filtered with the access properties since it is the first grouping
	//    level after the last view. The following structure will NOT work (if the goal is to filter at
	//    Grouping Level 2):
	//      View 1
	//        Grouping Level 1
	//          View 2 (inside grouping level 1)
	//            Grouping level 2
	//    Grouping Level 1 will be filtered by the access properties.
	loadView(view: any) {
		this.associatedObject = undefined;
		this.associatedObjectType = undefined;

		if (view.path.length < view.startingPath.length) {
			view.path = view.startingPath;
		}

		if (view.path && !this.widgetEditMode && view.path.length > view.startingPath.length) {
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams: {
					path: view.path
				},
				queryParamsHandling: 'merge',
				skipLocationChange: false
			});
		}

		view.loading = true;
		this.mfilesService.getPageView(view.path, this.pageId, this.widgetId).subscribe((result) => {
			view.viewContents = result;
			view.sortColumn = 'dateModified';
			view.sortAsc = true;
			this.sort('dateModified', view);
			view.isRevision = false;

			const iconsToLoad = [];
			for (const item of view.viewContents.items) {
				if (iconsToLoad.indexOf(item.type) === -1) {  // If the item type is not already in the iconsToLoad array, add and retrieve it
					iconsToLoad.push(item.type);
					this.getIcon(item.type);
				}

				// MCT-335
				const classProp = item.properties.find(prop => prop.propertyDef === 100);
				if (classProp) {
					const classId = parseInt(classProp.value?.serializedValue, 10);
					if (!isNaN(classId) && classId === this.bidRequestClassId) {
						item.isBidRequest = true;
						const bidRequestName: string = classProp.value?.displayValue;
						item.bidRequestName = bidRequestName.replace(' ', '').toLowerCase();
					}
				}
			}

			this.agGridColumnDefs = [];
			this.agGridRowData = [];

			if (view.viewContents.items && view.viewContents.items.length > 0 &&
				view.viewContents.columns && view.viewContents.columns.length > 0) {
				this.getColumnsForAgGrid();
				this.getRowsForAgGrid();
			}

			view.loading = false;
			this.initialLoad = false;
		});
	}

	getIcon(type: number) {
		if (this.useMfilesIcons) {
			this.mfilesService.getObjectTypeIcon(type).subscribe((imageResult) => {
				if (imageResult.body && imageResult.body.size !== 0) {
					const reader = new FileReader();
					reader.onloadend = (e) => {
						this.objectTypeIcons[type] = this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());
					};
					reader.readAsDataURL(imageResult.body);
				} else {
					this.objectTypeIcons[type] = undefined;
				}
			});
		}
	}

	sort(field: string, view: any) {
		if (view.sortColumn === field) {
			view.sortAsc = !view.sortAsc;
		} else {
			view.sortAsc = true;
		}
		view.sortColumn = field;

		if (view.sortAsc) {
			if (field === 'name') {
				view.viewContents.items.sort((a, b) => (a.name > b.name) ? 1 : -1);
			} else if (field === 'dateCreated') {
				view.viewContents.items.sort((a, b) => (a.dateCreated > b.dateCreated) ? 1 : -1);
			} else if (field === 'dateModified') {
				view.viewContents.items.sort((a, b) => (a.dateModified > b.dateModified) ? 1 : -1);
			} else if (field === 'version') {
				view.viewContents.items.sort((a, b) => (a.version > b.version) ? 1 : -1);
			} else if (field === 'type') {
				view.viewContents.items.sort((a, b) => (a.type > b.type) ? 1 : -1);
			}
		} else {
			if (field === 'name') {
				view.viewContents.items.sort((a, b) => (a.name < b.name) ? 1 : -1);
			} else if (field === 'dateCreated') {
				view.viewContents.items.sort((a, b) => (a.dateCreated < b.dateCreated) ? 1 : -1);
			} else if (field === 'dateModified') {
				view.viewContents.items.sort((a, b) => (a.dateModified < b.dateModified) ? 1 : -1);
			} else if (field === 'version') {
				view.viewContents.items.sort((a, b) => (a.version < b.version) ? 1 : -1);
			} else if (field === 'type') {
				view.viewContents.items.sort((a, b) => (a.type < b.type) ? 1 : -1);
			}
		}
	}

	sortRelated(field: string) {
		if (this.sortRelatedAsc) {
			this.sortRelatedAsc = !this.sortRelatedAsc;
			if (field === 'name') {
				this.relatedItems.sort((a, b) => (a.name > b.name) ? 1 : -1);
			} else if (field === 'dateCreated') {
				this.relatedItems.sort((a, b) => (Date.parse(a.dateCreated) > Date.parse(b.dateCreated)) ? 1 : -1);
			} else if (field === 'dateModified') {
				this.relatedItems.sort((a, b) => (Date.parse(a.dateModified) > Date.parse(b.dateModified)) ? 1 : -1);
			} else if (field === 'version') {
				this.relatedItems.sort((a, b) => (a.version > b.version) ? 1 : -1);
			} else if (field === 'type') {
				this.relatedItems.sort((a, b) => (a.type > b.type) ? 1 : -1);
			}
		} else {
			this.sortRelatedAsc = !this.sortRelatedAsc;
			if (field === 'name') {
				this.relatedItems.sort((a, b) => (a.name < b.name) ? 1 : -1);
			} else if (field === 'dateCreated') {
				this.relatedItems.sort((a, b) => (Date.parse(a.dateCreated) < Date.parse(b.dateCreated)) ? 1 : -1);
			} else if (field === 'dateModified') {
				this.relatedItems.sort((a, b) => (Date.parse(a.dateModified) < Date.parse(b.dateModified)) ? 1 : -1);
			} else if (field === 'version') {
				this.relatedItems.sort((a, b) => (a.version < b.version) ? 1 : -1);
			} else if (field === 'type') {
				this.relatedItems.sort((a, b) => (a.type < b.type) ? 1 : -1);
			}
		}
	}

	openView(path: string, view: any) {
		this.relatedItems = [];
		view.path = path;
		if (path.indexOf('L') > -1 || this.widgetEditMode) {
			this.loadView(view);
		} else {
			this.mfilesService.setPaths.next({ loadView: true, view: view, widget: this.widgetId });
		}
		this.openedView.emit(path);
	}

	openCheckin(view: any) {
		this.filelessObjectType = undefined;
		this.filelessClass = undefined;
		view.showCheckin = true;
		view.isRevision = false;
	}

	openFilelessCheckin(view: any, upload: any) {
		this.filelessObjectType = upload.objectType;
		this.filelessClass = upload.class;
		view.showCheckin = true;
		view.isRevision = false;
	}

	openCheckinForRevision(view: any, itemId: number, fileIndex: number) {
		view.showCheckin = true;
		view.isRevision = true;
		view.revisionItemId = itemId;
		view.fileIndex = fileIndex;
	}

	associateObjectType(classId: number) {
		this.associatedObjectType = classId;
	}

	associateObject(itemId: number) {
		this.associatedObject = itemId;
	}

	closeCheckin(view: any) {
		view.showCheckin = false;
	}

	createProperty(dataType: number, value: string, required: boolean, id: number): Property {
		const property: Property = {
			dataType: dataType,
			name: '',
			value: value,
			required: required,
			id: id,
			valueList: null,
			hasStaticFilter: false,
			staticFilters: null
		};
		return property;
	}

	upload(view: any) {
		if (view.files.length > 0) {
			view.loading = true;

			const fileUploadObservables: Observable<Object>[] = [];

			const fileProps = [];
			for (let i = 0; i < view.files.length; i++) {
				let props = this.formProperties[i].slice();

				// Additional Properties
				if (this.additionalProps) {
					const validated = this.additionalProps.map(prop => this.validateMultiselectValues(prop));
					props = props.concat(validated);
				}

				if (this.associateSelected) {
					let associatedPropertyExists = false;
					for (let j = 0; j < props.length; j++) {
						if (props[j].id === this.associatedProp) {
							associatedPropertyExists = true;
							break;
						}
					}

					if (!associatedPropertyExists) {
						let dataType = 9;
						for (const property of this.vaultProperties) {
							if (property.id === this.associatedProp) {
								dataType = property.dataType;
								break;
							}
						}

						const associatedProperty = this.createProperty(
							dataType,
							this.associatedObject + '',
							false,
							this.associatedProp
						);
						props.push(associatedProperty);
					}
				}

				fileProps[i] = props;
			}

			this.mfilesService.isMicroserviceAvailable().subscribe((available: boolean) => {
				if (available) {
					const uploadProps = [];
					let maxConcurrentUploads = 3;
					let filesUploading = 0;
					let totalFiles = view.files.length;

					for (let i = 0; i < view.files.length; i++) {
						const file = view.files[i].file;

						if (file.size > 0) {
							this.uploadProgressStats[file.name] = 0;	// Init the progress stats for this file while we're iterating through the files
						} else {
							this.uploadProgressStats[file.name] = 'EMPTY';
							view.files.splice(i, 1);		// If the file is empty, remove it and don't try to upload it. A message will be displayed to the user for this file.
							totalFiles--;
							i--;
							continue;
						}

						if (file.size >= (1024 * 1024 * 200)) { // 200MB
							maxConcurrentUploads = 1;
						}

						const props = [];
						for (const prop of fileProps[i]) {
							const propCopy = JSON.parse(JSON.stringify(prop));
							if (propCopy.id === 0) {
								propCopy.value = this.getFileName(file.name);
							}
							props.push(propCopy);
						}
						uploadProps.push(props);
					}

					this.mfilesService.microserviceUploadProgress = this.uploadProgressStats;
					this.openModal(this.uploadProgressModal, 'md');

					this.microserviceUploadSubscription = from(uploadProps)
						.pipe(
							tap(() => this.authService.isUploading = true),
							concatMap((props) => this.mfilesService.preparePageCheckinNew(
								props,
								this.pageId,
								this.widgetId,
								this.contextualCheckin ? view.path : '',
								this.contextualCheckin ? this.overrideClassUsingClassGroupingLevel : false)
							),								// Makes the page-prepare-checkin calls in order and returns the result in order.
							mergeMap((result, index) => {
								filesUploading += 1;
								this.uploadProgressMessage = `Uploading ${filesUploading}/${totalFiles}`;

								return this.mfilesService.microserviceUpload(result, view.files[index].file)
									.catch(err => {																								// If an error occurs while the file is uploading to the microservice, this catches it and ensures the rest of the uploads don't get canceled.
										if (err.file) {
											this.uploadProgressStats[err.file.name] = 'ERROR';													// UI looks for this value and uses it to determine if the error message should be shown. Don't remove it.
										}
										return EMPTY;
									})
							}, maxConcurrentUploads),																										// Starts uploading the files to the microservice. The 3 tells angular to limit the number of concurrent uploads to 3.
							mergeMap((result) => result, 1),																				// Intermediate step since microserviceUpload() returns a Promise with an Observable in it. This just returns the enclosed Observable.
							toArray(),																										// Merge all of the microserviceUpload results into an array.
							tap(() => {
								this.authService.isUploading = false;
								this.mfilesService.emitUploadProgress('all', 100);
								this.uploadProgressMessage = 'Complete';
							}))								// The upload to M-Files is now 100% complete.
						.subscribe({
							next: (res) => { },
							error: (err) => { },
							complete: () => {
								setTimeout(() => {
									this.closeModal();

									view.showCheckin = false;
									this.associatedObject = undefined;
									this.uploadConfirm.emit();
									this.loadView(view);

									this.mfilesService.microserviceUploadProgress = {};
									this.uploadProgressStats = {};
									this.uploadProgressMessage = '';
								}, 1500);
							}
						});
				} else {
					for (let i = 0; i < view.files.length; i++) {
						const props = fileProps[i];
						fileUploadObservables.push(
							this.mfilesService
								.pageCheckinNew(
									props,
									view.files[i].file,
									this.pageId,
									this.widgetId,
									this.contextualCheckin ? view.path : '',
									this.contextualCheckin ? this.overrideClassUsingClassGroupingLevel : false
								)
								.pipe(
									// This is where logic can be added to improve the UX around file uploads.
									// It would be helpful to do something like turn the file name for each upload to green or red
									// depending on if the upload was successful.
									tap(() => console.log(i))
								)
						);
					}

					forkJoin(fileUploadObservables).subscribe((result) => {
						view.showCheckin = false;
						this.associatedObject = undefined;
						this.uploadConfirm.emit();
						this.loadView(view);
					}, (error) => {
						this.toastr.error('Upload failed.');
						view.loading = false;
					});
				}
			});
		} else {
			view.loading = true;
			let props = this.formProperties[0].slice();

			// COMMENTING THIS OUT BECAUSE THE ADDITIONAL PROPERTIES SHOULD ONLY BE USED ON UPLOADED DOCUMENTS, NOT OBJECTS
			// // Additional Properties
			// if (this.additionalProps) {
			// 	const validated = this.additionalProps.map(prop => this.validateMultiselectValues(prop));
			// 	props = props.concat(validated);
			// }

			if (this.associateSelected) {
				let associatedPropertyExists = false;
				for (let i = 0; i < props.length; i++) {
					if (props[i].id === this.associatedProp) {
						associatedPropertyExists = true;
						break;
					}
				}

				if (!associatedPropertyExists) {
					let dataType = 9;
					for (const property of this.vaultProperties) {
						if (property.id === this.associatedProp) {
							dataType = property.dataType;
							break;
						}
					}

					const associatedProperty = this.createProperty(
						dataType,
						this.associatedObject + '',
						false,
						this.associatedProp
					);
					props.push(associatedProperty);
				}
			}

			let workflowSet = false;
			let defaultWorkflow: number;

			for (const prop of props) {
				if (prop.id === 100) {
					for (const classObj of this.objectTypeClasses[this.filelessObjectType]) {
						if (classObj.id === parseInt(prop.value, 10)) {
							defaultWorkflow = classObj.workflow;
							break;
						}
					}
				} else if (prop.id === 38 && prop.value) {
					workflowSet = true;
				}
			}

			if (!workflowSet && defaultWorkflow) {
				const workflowProperty = this.createProperty(
					9,
					defaultWorkflow + '',
					false,
					38
				);
				props.push(workflowProperty);
			}

			this.mfilesService.pageCheckinNewObject(props, this.filelessObjectType, this.pageId, this.widgetId).subscribe((result) => {
				view.showCheckin = false;
				this.associatedObject = undefined;
				this.loadView(view);
			});
		}
	}

	getFileName(fileName: string): string {
		const parts = fileName.split('.');
		parts.pop();
		return parts.join('.');
	}

	validateMultiselectValues(prop: Property) {
		if (prop.dataType === 10 && typeof prop.value !== 'string') {
			const valueArr: Property[] = prop.value;
			let joined = '';
			valueArr.forEach(val => {
				joined += val.id + ',';
			});
			prop.value = joined.replace(/,\s*$/, '');
		}
		return prop;
	}

	uploadRevision(view: any) {
		if (view.isRevision) {
			view.loading = true;

			if (!view.fileIndex) {
				view.fileIndex = 0;
			}
			this.mfilesService.isMicroserviceAvailable().subscribe((available: boolean) => {
				if (available) {
					this.mfilesService.microserviceUploadProgress = this.uploadProgressStats;
					this.openModal(this.uploadProgressModal, 'md');

					this.microserviceUploadSubscription = from([''])
						.pipe(
							tap(() => this.authService.isUploading = true),
							concatMap((props) => this.mfilesService.preparePageUploadRevision(view.revisionItemId, view.fileIndex, this.pageId, this.widgetId)),								// Makes the prepareUploadRevision calls in order and returns the result in order.
							mergeMap((result, index) => this.mfilesService.microserviceUpload(result, view.files[0].file, true)
								.catch(err => {																								// If an error occurs while the file is uploading to the microservice, this catches it and ensures the rest of the uploads don't get canceled.
									if (err.file) {
										this.uploadProgressStats[err.file.name] = 'ERROR';													// UI looks for this value and uses it to determine if the error message should be shown. Don't remove it.
									}
									return EMPTY;
								}), 3),																										// Starts uploading the files to the microservice. The 3 tells angular to limit the number of concurrent uploads to 3.
							mergeMap((result) => result, 1),																				// Intermediate step since microserviceUpload() returns a Promise with an Observable in it. This just returns the enclosed Observable.
							tap(() => {
								this.authService.isUploading = false;
								this.mfilesService.emitUploadProgress('all', 100);
							}))								// The upload to M-Files is now 100% complete.
						.subscribe({
							next: (res) => { },
							error: (err) => { },
							complete: () => {
								setTimeout(() => {
									this.closeModal();

									view.showCheckin = false;
									view.files = [];
									view.properties = [];
									view.fileIndex = 0;
									this.loadView(view);
									this.mfilesService.microserviceUploadProgress = {};
									this.uploadProgressStats = {};
								}, 1500);
							}
						});
				} else {
					this.mfilesService.pageUploadRevision(view.revisionItemId, view.fileIndex, view.files[0].file, this.pageId, this.widgetId).subscribe((result) => {
						view.showCheckin = false;
						view.files = [];
						view.properties = [];
						view.fileIndex = 0;
						this.loadView(view);
					}, (error) => {
						view.loading = false;
					});
				}
			});
		}
	}

	closeModal() {
		if (this.modalRef) {
			this.modalRef.hide();
			this.modalRef = null;
		}
	}

	onUploadQueueChange(view: any, event: any) {
		view.files = event;
	}

	openFile(fileId: number) {
		window.open(window.location.origin + '/#/file/' + fileId + '/0', '_blank');
	}

	download(id: number) {
		this.mfilesService.pageDownload(0, id, -1, 0, this.pageId, this.widgetId).subscribe(response => {
			var reader = new FileReader();
			reader.onload = () => {
				const contents = reader.result.toString();
				if (contents.startsWith('http')) {
					this.downloadLink = contents;
					this.openModal(this.downloadDialog, 'md');
				} else {
					saveAs(response.body, response.headers.get('filename'));
				}
			};
			reader.readAsText(response.body);
		});
	}

	onFormChange(forms: UntypedFormGroup[]) {
		let valid = true;
		if (forms.length < this.view.files.length) {
			valid = false;
		}
		for (const form of forms) {
			if (!form.valid) {
				valid = false;
				break;
			}
		}
		this.formValid = valid;
	}

	onPropertiesChange(formProperties: Property[][]) {
		this.formProperties = formProperties;
	}

	relatedLoaded(e: any) {
		this.selectedItem = e['item'];
		const related = e['related'];
		const iconsToLoad = [];
		for (const item of related) {
			if (!item.type && item.objVer) {
				item.name = item.title;
				item.type = item.objVer.type;
				item.version = item.objVer.version;
				item.dateCreated = item.created;
				item.dateModified = item.lastModified;
			}

			if (!this.objectTypeIcons[item.type] && iconsToLoad.indexOf(item.type) === -1) {
				iconsToLoad.push(item.type);
				this.getIcon(item.type);
			}
		}

		this.relatedItems = related;
	}

	animationStart() {
		this.animStart = true;
		this.animDone = false;
	}

	animationDone() {
		this.animStart = false;
		this.animDone = true;
	}

	showUploadDocumentButton() {
		if (this.associateSelected && !this.associatedObject) {
			return false;
		}
		if (this.authService.isExternalUser() && this.hasAccessProperties) {
			const pathSegments: string[] = this.view.path.split('/').filter(folder => folder.length > 0);

			// See Limitation comment above loadView()
			const firstGroupingLevelIndex: number = pathSegments.findIndex(segment => segment.match('L'));
			const firstGLAfterLastView: string = pathSegments[firstGroupingLevelIndex];
			const contextFolder: string = firstGLAfterLastView;

			if (contextFolder) {
				// Brad Hickey: I am adding a temporary fix that ensures the user is in the second level of a virtual folder
				//              before showing the upload button.  This is for HHB and we should find a better way to handle
				//              this across all clients.
				const regex = /[L]/g;
				const matches = this.view.path.match(regex);
				return matches.length > 1 &&  // Check to make sure the user is in second level virtual folder
					//return this.view.path.indexOf('L') > -1 &&  // Check to make sure the user is in a virtual folder					(
					(
						// Check to make sure they have r/w or r/w/d access to the virtual folder
						this.view.rwFolders.includes(contextFolder) ||
						this.view.rwdFolders.includes(contextFolder)
					);
			}
		} else if (this.authService.isExternalUser() && this.contextualCheckin && !this.hasAccessProperties) {
			const pathSegments: string[] = this.view.path.split('/').filter(folder => folder.length > 0);

			// See Limitation comment above loadView()
			const firstGroupingLevelIndex: number = pathSegments.findIndex(segment => segment.match('L'));
			const firstGLAfterLastView: string = pathSegments[firstGroupingLevelIndex];
			const contextFolder: string = firstGLAfterLastView;

			if (contextFolder) {
				return true; // As long as the user has selected a virtual folder, show the upload document button
			}
		} else {
			return this.view.path; // Returning this here rather than true because we only want to display the upload button if the view has a valid path.
			// if (this.documentUploadsEnabled) {
			// 	return this.view.path; // Returning this here rather than true because we only want to display the upload button if the view has a valid path.
			// } else {
			// 	return false;
			// }
		}
	}

	toggleEditMode() {
		this.editMode = !this.editMode;
	}

	createCopyPasteEventListeners() {
		const gridCells = document.querySelectorAll('div.ag-cell');
		gridCells.forEach(cell => {
			cell.addEventListener('copy', (event => {
				this.copyEvent.value = document.getSelection().anchorNode.nodeValue;
				navigator.clipboard.writeText(this.copyEvent.value);
				this.copyEvent.column = this.recentClickedCellColDef;
				this.toastr.info('Cell value copied!');
			}));

			cell.addEventListener('paste', (event => {
				if (this.copyEvent.value && this.copyEvent.column) {
					const selectedRows = this.agGridApi.getSelectedRows();
					selectedRows.forEach(row => {
						const rowIndex = this.agGridRowData.indexOf(row);
						this.agGridRowData[rowIndex][this.copyEvent.column.field] = this.copyEvent.value;

						const newValue = this.copyEvent.value;
						const colDataType = this.copyEvent.column.dataType;
						const colId = this.copyEvent.column.id;

						if (colDataType === 9 || colDataType === 10 && this.agGridRowData[rowIndex].vliMappings[this.copyEvent.column.valueList]) {
							this.agGridRowData[rowIndex].vliMappings[this.copyEvent.column.valueList] = this.getLookupId(colId, newValue);
						}

						const changedProp = {
							propertyDef: colId === -1 ? 0 : colId,
							dataType: colDataType,
							value: colDataType === 9 || colDataType === 10 ? this.getLookupId(colId, newValue) : newValue
						};
						this.addOrUpdateChangedItem(row.itemId, row.itemType, row.itemVersion, changedProp);
					});

					this.agGridChangesMade = true;
					this.agGridApi.setRowData(this.agGridRowData);
					this.createCopyPasteEventListeners();
				} else {
					this.toastr.info('Please copy a cell before pasting.');
				}

			}));
		});
	}

	determineAgGridHeight() {
		const widgetContainerHeight = document.querySelector<HTMLElement>('.dataview-widget-outer').getBoundingClientRect().height;
		document.querySelector<HTMLElement>('#ag-grid').style.height = `${widgetContainerHeight - 193}px`;
	}

	onGridReady(params) {
		this.agGridApi = params.api;
		this.agGridColumnApi = params.columnApi;

		this.determineAgGridHeight();

		this.layoutService.screenHeightChanged.subscribe(() => {
			setTimeout(() => {
				this.determineAgGridHeight();
			}, 300);
		});

		this.createCopyPasteEventListeners();
	}

	isMetadataOrViewerPresent() {
		const widgets = this.pageService.getActivePageWidgets();
		let widgetFound = false;
		for (const widget of widgets) {
			if (widget.type === 'viewer' || widget.type === 'metadata') {
				widgetFound = true;
			}
		}

		return widgetFound;
	}

	openPreview(fileId: number, type: number) {
		// Determine if there is a metadata or viewer  widget on the page
		const widgetFound = this.isMetadataOrViewerPresent();

		// If there is, emit that a document selection has occurred so the widgets can retrieve their data
		if (widgetFound) {
			const context = {
				itemId: fileId,
				query: '',
				type: type
			};
			this.pageService.documentSelectionOccurred.emit(context);
		} else {
			this.router.navigate(['file', fileId, 0]);

			// In case we need to open in new tab in future
			// window.open(window.location.origin + '/#/file/' + fileId + query, '_blank');
		}
	}

	addOrUpdateChangedItem(itemId: number, itemType: number, itemVersion: number, property: any) {
		if (this.changedObjs[itemId]) {
			const existingPropIndex = this.changedObjs[itemId].properties.findIndex(prop => prop.propertyDef === property.propertyDef);
			if (existingPropIndex === -1) {
				this.changedObjs[itemId].properties.push(property);
			} else {
				this.changedObjs[itemId].properties[existingPropIndex] = property;
			}
		} else {
			this.changedObjs[itemId] = {
				objVer: {
					id: itemId,
					type: itemType,
					version: itemVersion
				},
				properties: [property]
			};
		}
	}

	getValuesForSelectList(params) {
		if (params.colDef.dataType === 8) {
			return ['Yes', 'No'];
		} else {
			const propValueListMapping = this.propValueListMap[params.colDef.id];
			if (this.propValueListMap[params.colDef.id].hasOwner) {
				const ownerListId = propValueListMapping.owner;
				if (params.data.vliMappings && params.data.vliMappings[ownerListId]) {
					const ownerListValueId = params.data.vliMappings[ownerListId];
					const result = this.propValueListMap[params.colDef.id].items
						.filter((item: any) => item.ownerId === ownerListValueId)
						.map((item: any) => item.name);

					result.unshift('-');
					return result;
				} else {
					return ['-'];
				}
			} else {
				return this.propValueListMap[params.colDef.id].items.map(val => val.name);
			}
		}
	}

	getColDataType(colId: number) {
		let prop = null;

		for (const vaultProp of this.vaultProperties) {
			if (colId === -1) {   // Search for Name property
				if (vaultProp.id === 0) {
					prop = vaultProp;
					break;
				}
			} else {
				if (vaultProp.id === colId) {
					prop = vaultProp;
					break;
				}
			}
		}

		if (prop) {
			return prop.dataType;
		}
	}

	getColumnsForAgGrid(refresh?: boolean) {
		if (refresh) {
			this.agGridColumnDefs = [];
		}

		if (this.agGridColumnDefs.length === 0) {
			const viewColumns = this.view.viewContents.columns;

			this.agGridColumnDefs.push({
				field: 'Preview',
				editable: false,
				position: 0,
				width: '100px',
				resizable: false,
				cellRenderer: 'previewButtonRenderer'
			});

			this.context = { componentParent: this };
			this.frameworkComponents = {
				previewButtonRenderer: PreviewButtonRendererComponent,
				typeaheadSelectRenderer: TypeaheadSelectRendererComponent
			};

			viewColumns.forEach(col => {
				const colDataType: number = this.getColDataType(col.id);
				let useDefaultColumn = false;

				let column: any;
				if (col.id > 999 || col.id === 100 &&
					colDataType === 9 || colDataType === 10) {
					if (this.propValueListMap[col.id]) {
						column = {
							field: col.name,
							editable: col.id > 999 || col.id === 100,
							id: col.id,
							position: col.position,
							dataType: colDataType,
							valueList: this.propValueListMap[col.id].id,
							resizable: true,
							filter: true,
							sortable: true,
							cellEditor: 'typeaheadSelectRenderer',
							cellEditorParams: (params) => {
								return {
									values: this.getValuesForSelectList(params)
								};
							}
						};
					} else {
						useDefaultColumn = true;
					}
				} else {
					useDefaultColumn = true;
				}

				if (useDefaultColumn) {
					column = {
						field: col.name,
						editable: col.id > 999 || col.id === -1,
						id: col.id,
						position: col.position,
						dataType: colDataType,
						resizable: true,
						filter: true,
						sortable: true,
						cellEditor: '',
						cellEditorParams: {}
					};
				}

				this.agGridColumnDefs.push(column);
			});
		}
	}

	myCellRenderer(params) {
		return '<a href="javascript:" (click)="openFile(' + params.id + ', ' + params.type + ')" ' +
			'*ngIf="' + params.type + ' === 0">' + params.name + '</a>';
	}

	getRowsForAgGrid(refresh?: boolean) {
		if (refresh) {
			this.agGridRowData = [];
		}

		if (this.agGridRowData.length === 0) {
			const items = this.view.viewContents.items;
			items.forEach(item => {
				const row = { itemId: item.id, itemType: item.type, itemVersion: item.version };
				this.view.viewContents.columns.forEach(col => {
					switch (col.id) {
						case -1: // Name
							row[col.name] = item.name;
							break;
						case -2: // Type
							row[col.name] = this.objectTypes[item.type].name;
							break;
						case -3: // Size
							row[col.name] = this.mfObjectSizePipe.transform(this.getItemSize(item));
							break;
						case -8: // Version
							row[col.name] = item.version;
							break;
						case -13: // Date Created
							row[col.name] = this.datePipe.transform(item.dateCreated, 'M/d/yyyy h:mm a');
							break;
						case -14: // Date Modified
							row[col.name] = this.datePipe.transform(item.dateModified, 'M/d/yyyy h:mm a');
							break;
						default:
							const info = this.getDisplayValueForColumn(col, item);

							row[col.name] = info.value;
							if (info.valueListID && info.vliID) {
								if (row['vliMappings']) {
									row['vliMappings'][info.valueListID] = info.vliID;
								} else {
									row['vliMappings'] = {};
									row['vliMappings'][info.valueListID] = info.vliID;
								}
							}
					}
				});

				this.agGridRowData.push(row);
			});
		}
	}

	getItemSize(item) {
		let size = 0;

		for (const prop of item.properties) {
			if (prop.propertyDef === 31) {
				size = prop.value.value;
				break;
			}
		}
		return size;
	}

	getDisplayValueForColumn(column: any, item: any) {
		const columnId = column.id;
		let columnProp = null;

		for (const prop of item.properties) {
			if (prop.propertyDef === columnId) {
				columnProp = prop;
				break;
			}
		}

		if (columnProp != null && columnProp.value.displayValue != null && columnProp.value.displayValue.length > 0) {
			if (columnProp.value.dataType === 9) {
				return {
					valueListID: this.propValueListMap[columnId]?.id,
					vliID: columnProp.value.lookup.item,
					value: columnProp.value.displayValue
				};
			}
			return {
				valueListID: null,
				vliID: null,
				value: columnProp.value.displayValue
			};
		} else {
			return {
				valueListID: null,
				vliID: null,
				value: '-'
			};
		}
	}

	getLookupId(propId: number, newValue: string) {
		const valueListItem = this.propValueListMap[propId].items.find(item => item.name === newValue);
		if (valueListItem) {
			return valueListItem.id;
		} else {
			return null;
		}
	}

	getBooleanValue(val: string) {
		return val === 'Yes';
	}

	onCellValueChanged(cell: any) {
		if (cell.newValue) {
			this.agGridChangesMade = true;

			const newValue = cell.data[cell.colDef.field];
			const colDataType = cell.colDef.dataType;
			const colId = cell.colDef.id;
			let colValue = newValue;

			if (colDataType === 8) {
				colValue = this.getBooleanValue(newValue);
			} else if (colDataType === 9 || colDataType === 10) {
				if (colValue === '-') {
					cell.data.vliMappings[cell.colDef.valueList] = null;
				} else {
					colValue = this.getLookupId(colId, newValue);
					const valueListForColProp = this.propValueListMap[cell.colDef.id];
					const newVli = valueListForColProp.items.find(item => item.name === cell.newValue);
					const newVliID = newVli.id;
					cell.data.vliMappings[cell.colDef.valueList] = newVliID;
				}
			}

			if (colValue) {
				const changedProp = {
					propertyDef: colId === -1 ? 0 : colId,
					dataType: colDataType,
					value: colValue === '-' ? null : colValue
				};

				this.addOrUpdateChangedItem(cell.data.itemId, cell.data.itemType, cell.data.itemVersion, changedProp);
			}
		}
	}

	onCellClicked(cell: any) {
		// Save the column definition just in case a copy event happens
		this.recentClickedCellColDef = cell.column.colDef;
	}

	saveChangedObjs() {
		this.view.loading = true;
		this.mfilesService.updatePropertiesOnMultipleObjs(Object.values(this.changedObjs)).subscribe((response) => {
			if (response && response.length > 0) {
				this.toastr.error(response);
			} else {
				this.toastr.success('Changes were saved successfully.');

				this.loadView(this.view);
				this.changedObjs = {};
				this.agGridChangesMade = false;
				this.editMode = false;

				this.getColumnsForAgGrid(true);
				this.getRowsForAgGrid(true);
			}

			this.view.loading = false;
		});
	}

	cancelChangedObjs() {
		this.changedObjs = {};
		this.agGridChangesMade = false;
		this.editMode = false;

		this.getColumnsForAgGrid(true);
		this.getRowsForAgGrid(true);
	}

	getClassName(upload: any) {
		let name = upload.class;
		for (const classObj of this.objectTypeClasses[upload.objectType]) {
			if (classObj.id === upload.class) {
				name = classObj.name;
				break;
			}
		}
		return name;
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.show(
			template,
			{ class: 'modal-' + size, backdrop: 'static' }
		);
	}

	openVerifyDelete(name: string, itemType: number, itemId: number) {
		this.deleteName = name;
		this.deleteType = itemType;
		this.deleteItem = itemId;
		this.openModal(this.deleteDialog, 'md');
	}

	deleteObject() {
		this.modalRef.hide();
		this.view.loading = true;
		this.mfilesService.pageDeleteObject(this.deleteType, this.deleteItem, true, this.pageId, this.widgetId).subscribe((result) => {
			this.deleteType = -1;
			this.deleteItem = -1;
			this.loadView(this.view);
		});
	}

	canCreate(classInfo: any) {
		let canCreate = false;
		for (const permission of this.filelessPermissions) {
			if (permission.classId === classInfo.class) {
				canCreate = permission.create;
			}
		}

		return canCreate;
	}

	downloadAll(view: any) {
		this.loadingDownloadAll = true;
		this.mfilesService.pageViewDownloadMultiple(view.path, this.pageId, this.widgetId).subscribe({
			next: (result) => {
				saveAs(result.body, result.headers.get('filename'));
			},
			error: (err) => {
				console.error(err);
				this.toastr.error('An error occurred while downloading the file. Please try again later.');
				this.loadingDownloadAll = false;
			},
			complete: () => {
				this.loadingDownloadAll = false;
			}
		});
	}

	downloadFolders(view: any) {
		this.loadingDownloadFolders = true;

		let zipName: string = "";
		if (view.viewContents.breadcrumb.length > 0) {
			let lastBreadcrumb = view.viewContents.breadcrumb[view.viewContents.breadcrumb.length - 1];
			zipName = lastBreadcrumb.name;
		}

		this.mfilesService.pageViewDownload(zipName, view.path, this.pageId, this.widgetId).subscribe({
			next: (result) => {
				saveAs(result.body, result.headers.get('filename'));
			},
			error: (err) => {
				if (err.status === 400) {
					this.toastr.error('The folders cannot be downloaded because there are no files in any of the displayed folders.');
				} else if (err.status === 413) {
					this.toastr.error('The files and folders in the selected view are too large to download. Please select each folder in this view and download them separately.');
				}
				this.loadingDownloadFolders = false;
			},
			complete: () => {
				this.loadingDownloadFolders = false;
			}
		});
	}

	getAlertsValueLists() {
		if (this.alertsEnabled) {
			forkJoin([
				this.mfilesService.getAlertTriggers(),
				this.mfilesService.getAlertFrequencies(),
				this.mfilesService.getAlertDays()
			]).subscribe({
				next: (response: any[]) => {
					this.alertTriggers = response[0];
					this.alertFrequencies = response[1];
					this.alertDays = response[2];
				},
				error: (err) => {
					console.error(err);
				},
				complete: () => {
					this.alertTriggers.sort((a, b) => a.id - b.id);
					this.alertFrequencies.sort((a, b) => a.id - b.id);
					this.alertDays.sort((a, b) => a.id - b.id);
				}
			});
		}
	}
}
