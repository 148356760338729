import { Component, OnInit } from '@angular/core';
import { MFilesService } from '../services/mfiles.service';
import { saveAs } from 'file-saver';
import { FavoritesService } from '../services/favorite.service';
import { ConfigurationService } from '../services/configuration.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../services/auth.service';

@Component({
	selector: 'app-favorites',
	templateUrl: './favorites.component.html'
})
export class FavoritesComponent implements OnInit {

	favorites: any[] = [];
	favoritesEnabled = true;
	coauthorEnabled = false;
	loading = true;

	objectTypes = [];
	objectTypeLookup = {};
	classes = [];
	classLookup = {};

	objectTypeIcons = {};
	useMfilesIcons = true;

	sortColumn = 'date';
	sortAsc = true;

	dataView = {
		viewContents: {
			columns: [
				{ 'id': -1, 'name': 'Name', 'position': 0, 'width': 500 },
				{ 'id': -2, 'name': 'Type', 'position': 1, 'width': 60 },
				{ 'id': -3, 'name': 'Size', 'position': 2, 'width': 60 },
				{ 'id': -8, 'name': 'Version', 'position': 3, 'width': 60 },
				{ 'id': -14, 'name': 'Date Modified', 'position': 4, 'width': 120 }
			]
		}
	};

	constructor(
		private mfilesService: MFilesService,
		private favoritesService: FavoritesService,
		private configService: ConfigurationService,
		public authService: AuthService,
		private domSanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this.configService.list(false).subscribe((result) => {
			for (const config of result) {
				if (config.name === 'coauthorEnabled') {
					this.coauthorEnabled = config.value.toLowerCase() === 'true';
				} else if (config.name === 'useMfilesIcons') {
					this.useMfilesIcons = config.value.toLowerCase() === 'true';
				}
			}
		});

		this.mfilesService.getObjectTypes().subscribe((result) => {
			result.body.forEach((objectType) => {
				this.objectTypes[objectType.id] = objectType;
			});
		});

		this.favoritesService.get().subscribe((result) => {
			this.favorites = result;

			const iconsToLoad = [];
			for (const item of this.favorites) {
				if (iconsToLoad.indexOf(item.type) === -1) {
					iconsToLoad.push(item.type);
					this.getIcon(item.type);
				}
			}

			this.loading = false;
		});
	}

	openFile(fileId: number) {
		window.open(window.location.origin + '/#/file/' + fileId + '/0', '_blank');
	}

	download(id: number) {
		this.mfilesService.download(0, id, -1, 0).subscribe(response => {
			saveAs(response.body, response.headers.get('filename'));
		});
	}

	sort(field: string) {
		if (this.sortColumn === field) {
			this.sortAsc = !this.sortAsc;
		} else {
			this.sortAsc = true;
		}
		this.sortColumn = field;

		if (this.sortAsc) {
			if (field === 'size') {
				this.favorites.sort((a, b) => (this.getItemSize(a) > this.getItemSize(b)) ? 1 : -1);
			} else {
				this.favorites.sort((a, b) => (a[field] > b[field]) ? 1 : -1);
			}
		} else {
			if (field === 'size') {
				this.favorites.sort((a, b) => (this.getItemSize(a) < this.getItemSize(b)) ? 1 : -1);
			} else {
				this.favorites.sort((a, b) => (a[field] < b[field]) ? 1 : -1);
			}
		}
	}

	getItemSize(item) {
		const size = item.properties.find((prop) => prop.propertyDef === 31).value.value;
		return size;
	}

	getIcon(type: number) {
		if (this.useMfilesIcons) {
			this.mfilesService.getObjectTypeIcon(type).subscribe((imageResult) => {
				if (imageResult.body && imageResult.body.size !== 0) {
					const reader = new FileReader();
					reader.onloadend = (e) => {
						this.objectTypeIcons[type] = this.domSanitizer.bypassSecurityTrustUrl(reader.result.toString());
					};
					reader.readAsDataURL(imageResult.body);
				} else {
					this.objectTypeIcons[type] = undefined;
				}
			});
		}
	}

}
