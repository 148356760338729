import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-preview-button',
	template: `<span class="preview-button">
      <em class="fas fa-file-image" (click)="previewDocument()"></em>
  </span>`,
	styles: [
		`
         .preview-button {
           font-size: 18px;
           color: rgb(128, 128, 128);
         }
         .preview-button:hover {
           color: rgb(90, 90, 90);
           cursor: pointer;
         }
       `,
	],
})
export class PreviewButtonRendererComponent implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	public previewDocument() {
		this.params.context.componentParent.openPreview(this.params.data.itemId, this.params.data.itemType);
	}

	refresh(): boolean {
		return false;
	}
}
