<div class="main-content-wrapper">
	<div class="main-content">
		<header class="main-content-header">
			<h1 class="pull-left">Favorites</h1>
		</header>
	</div>
</div>


<div class="main-content-area">
	<div class="row">
		<div class="widget-holder col-xs-12">
			<div class="panel panel-default">
				<div class="panel-body">
					<div class="scroll-holder">
						<div [ngClass]="{'loading in': loading }">
							<div class="table-scroll horizontal-scroll custom-scroll">
								<table class="full-width table table-striped table-fixed has-locked-deleted has-locked-and-deleted">
									<thead>
										<tr>
											<th id="favorite"></th>
											<th id="name" (click)="sort('name')"><span [ngStyle]="{'cursor': 'pointer'}">Name</span></th>
											<th id="objectType" (click)="sort('type')"><span [ngStyle]="{'cursor': 'pointer'}">Type</span></th>
											<th id="size" (click)="sort('size')"><span [ngStyle]="{'cursor': 'pointer'}">Size</span></th>
											<th id="version" (click)="sort('version')"><span [ngStyle]="{'cursor': 'pointer'}">Version</span></th>
											<th id="lastModified" (click)="sort('date')"><span [ngStyle]="{'cursor': 'pointer'}">Last Modified</span></th>
											<th id="download"></th>
											<th id="delete"></th>
											<th id="coauthor" *ngIf="coauthorEnabled"></th>
										</tr>
									</thead>
									<tbody>
										<mfiles-row *ngFor="let item of favorites" [ngStyle]="{'backgroundImage': tiles ? ('File' | thumbnailBackgroundUrl) : 'none', 'background-size': 'File' !== 'Content' ? '40%' : '' }" [item]="item" [objectTypes]="objectTypes" [favoritesEnabled]="favoritesEnabled" [coauthorEnabled]="coauthorEnabled" [icon]="objectTypeIcons[item.type] ? objectTypeIcons[item.type].changingThisBreaksApplicationSecurity : ''" [view]="dataView" [objectTypeIcons]="objectTypeIcons" (loadIcon)="getIcon($event)">
										</mfiles-row>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>