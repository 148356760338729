import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import HelloSign from 'hellosign-embedded';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SessionStorage } from 'ngx-store';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { ConfigurationService } from '../services/configuration.service';
import { MFilesService } from '../services/mfiles.service';

declare var $: any;

@Component({
	selector: 'app-document-review',
	templateUrl: './document-review.component.html',
	styleUrls: ['./document-review.component.css']
})
export class DocumentReviewComponent implements OnInit {
	modalRef: BsModalRef;
	@ViewChild('signInfoModal') private signInfoModal: TemplateRef<any>;
	@ViewChild('annotationModal') private annotationModal: TemplateRef<any>;

	loading = false;
	signed = false;

	id = -1;
	revision: number;
	docTitle = '';
	properties = [];
	class = -1;

	@SessionStorage('viewer') viewer;
	viewerProxy = '';
	enableAnnotations = false;
	annotateButtonLabel = 'Annotate';
	saveAnnotationsButtonLabel = 'Save Annotations';
	allowRedactions = false;
	prizmviewer: any;
	annotationviewer: any;
	xViewSessionId: any;

	rejecting = false;
	signing = false;
	helloSignRedactReject = false;
	helloSignRedactSign = false;
	helloSignHideRedactClasses = [];

	helloSignApiKey = '';
	helloSignMessage = '';
	showHelloSignMessage = false;
	helloSignRejectLabel = '';
	helloSignSignLabel = '';
	helloSignSignSuccess = '';

	rejectComment = '';

	canAnnotate = false;

	workspace = 1;

	constructor(
		private authService: AuthService,
		private modalService: BsModalService,
		private router: Router,
		private route: ActivatedRoute,
		private zone: NgZone,
		private mfilesService: MFilesService,
		private configService: ConfigurationService,
		private toastr: ToastrService
	) { }

	ngOnInit(): void {
		this.workspace = parseInt(this.authService.getToken('workspace'));

		this.configService.getList(['viewerProxy', 'enableAnnotations', 'annotateButtonLabel', 'saveAnnotationsButtonLabel', 'allowRedactions', 'helloSignApiKey', 'helloSignMessage', 'showHelloSignMessage', 'helloSignRejectLabel', 'helloSignSignLabel', 'helloSignSignSuccess', 'helloSignEnabled', 'helloSignRedactReject', 'helloSignRedactSign', 'helloSignHideRedactClasses']).subscribe((result) => {
			let helloSignEnabled = false;
			for (const config of result) {
				if (config.name === 'viewerProxy') {
					this.viewerProxy = config.value;
				} else if (config.name === 'enableAnnotations') {
					this.enableAnnotations = config.value.toLowerCase() === 'true';
				} else if (config.name === 'annotateButtonLabel') {
					this.annotateButtonLabel = config.value !== null ? config.value : '';
				} else if (config.name === 'saveAnnotationsButtonLabel') {
					this.saveAnnotationsButtonLabel = config.value !== null ? config.value : '';
				} else if (config.name === 'allowRedactions') {
					this.allowRedactions = config.value.toLowerCase() === 'true';
				} else if (config.name === 'helloSignApiKey') {
					this.helloSignApiKey = config.value;
				} else if (config.name === 'helloSignMessage') {
					this.helloSignMessage = config.value;
				} else if (config.name === 'showHelloSignMessage') {
					this.showHelloSignMessage = config.value === 'true';
				} else if (config.name === 'helloSignRejectLabel') {
					this.helloSignRejectLabel = config.value;
				} else if (config.name === 'helloSignSignLabel') {
					this.helloSignSignLabel = config.value;
				} else if (config.name === 'helloSignSignSuccess') {
					this.helloSignSignSuccess = config.value;
				} else if (config.name === 'helloSignEnabled') {
					helloSignEnabled = config.value === 'true';
				} else if (config.name === 'helloSignRedactReject') {
					this.helloSignRedactReject = config.value === 'true';
				} else if (config.name === 'helloSignRedactSign') {
					this.helloSignRedactSign = config.value === 'true';
				} else if (config.name === 'helloSignHideRedactClasses') {
					this.helloSignHideRedactClasses = config.value.split(',');
					for (let i = 0; i < this.helloSignHideRedactClasses.length; i++) {
						this.helloSignHideRedactClasses[i] = parseInt(this.helloSignHideRedactClasses[i]);
					}
				}
			}
			if (helloSignEnabled) {
				this.route.params.subscribe(params => {
					if (params.id) {
						this.id = params.id;
						this.loadViewer();
					}
				});
			} else {
				this.router.navigate(['page', 'welcome']);
			}
		});
	}

	loadViewer() {
		this.loading = true;
		this.configService.get('viewerProxy', this.workspace).subscribe((proxyResult) => {
			this.viewerProxy = proxyResult.value;
			this.mfilesService.getHelloSignPreviewId(this.id).subscribe((result: any) => {
				if (result && result.title) {
					this.docTitle = result.title;
					this.xViewSessionId = result.viewerId;

					this.mfilesService.getProperties(0, this.id, -1).subscribe((response: any) => {
						const body: any = response.body;
						this.revision = body.objVer.version;
						this.properties = body.properties;
						this.canAnnotate = body.annotate;

						for (const prop of this.properties) {
							if (prop.propertyDef === 100) {
								this.class = prop.value.lookup.item;
							}
						}

						this.initPrizmViewer(result.viewerId, false);
						this.loading = false;
					});

					if (this.helloSignMessage && this.showHelloSignMessage) {
						this.openModal(this.signInfoModal, 'lg');
					}

					this.route.queryParams.subscribe((queryParams: Params) => {
						if (queryParams['signed']) {
							this.signed = true;
							this.toastr.success(this.helloSignSignSuccess);
						}
					});
				} else {
					this.router.navigate(['page', 'welcome']);
				}
			});
		});
	}

	initPrizmViewer(vsId: any, isAnnotation: boolean) {
		const helper = {
			documentID: vsId,
			imageHandlerUrl: this.viewerProxy,
			template: {
				viewer: require('!raw-loader!../file/prizmFiles/templates/viewerTemplate.html').default,
				contextMenu: require('!raw-loader!../file/prizmFiles/templates/contextMenuTemplate.html').default,
				printOverlay: require('!raw-loader!../file/prizmFiles/templates/printOverlayTemplate.html').default,
				unsavedChangesOverlay: require('!raw-loader!../file/prizmFiles/templates/unsavedChangesOverlayTemplate.html').default,
				overwriteOverlay: require('!raw-loader!../file/prizmFiles/templates/overwriteOverlayTemplate.html').default,
				print: require('!raw-loader!../file/prizmFiles/templates/printTemplate.html').default,
				comment: require('!raw-loader!../file/prizmFiles/templates/commentTemplate.html').default,
				esignOverlay: require('!raw-loader!../file/prizmFiles/templates/esignOverlayTemplate.html').default,
				downloadOverlay: require('!raw-loader!../file/prizmFiles/templates/downloadOverlayTemplate.html').default,
				imageStampOverlay: require('!raw-loader!../file/prizmFiles/templates/imageStampOverlayTemplate.html').default,
				hyperlinkMenu: require('!raw-loader!../file/prizmFiles/templates/hyperlinkMenuTemplate.html').default,
				pageRedactionOverlay: require('!raw-loader!../file/prizmFiles/templates/pageRedactionOverlayTemplate.html').default,
				copyOverlay: require('!raw-loader!../file/prizmFiles/templates/copyOverlayTemplate.html').default,
				redactionReason: require('!raw-loader!../file/prizmFiles/templates/redactionReasonTemplate.html').default
			},
			icons: require('!raw-loader!../file/prizmFiles/svg-icons.svg.html').default,
			language: require('../file/prizmFiles/en-US.json'),
			debug: true
		};

		this.zone.runOutsideAngular(() => {
			if (!isAnnotation && this.prizmviewer) {
				this.prizmviewer.destroy();
			}
			if (isAnnotation && this.annotationviewer) {
				this.annotationviewer.destroy();
			}

			const viewerOptions = {
				uiElements: {
					redactTab: false,
					annotateTab: isAnnotation,
					esignTab: true,
					printing: true,
					searchTab: !isAnnotation,
					viewTab: !isAnnotation,
					download: false
				},
				documentID: helper.documentID,
				template: helper.template,
				language: helper.language,
				icons: helper.icons,
				imageHandlerUrl: helper.imageHandlerUrl
			};

			if (isAnnotation) {
				this.annotationviewer = $('#annotationviewer').pccViewer(viewerOptions);
			} else {
				this.prizmviewer = $('#prizmviewer').pccViewer(viewerOptions);
			}
		});

		if (isAnnotation) {
			if (this.enableAnnotations) {
				$('.pcc-js-save-annotations').on('click', () => {
					this.saveAnnotationsHandler();
				});

				this.loadAnnotations();
			}
		} else {
			this.prizmviewer.viewerControl.on('ViewerReady', () => {
				if (this.enableAnnotations) {
					this.loadAnnotations();
				}
			});
		}
	}

	loadAnnotations() {
		this.mfilesService.getAnnotations(this.id).subscribe((result) => {
			this.loading = false;
			if (this.prizmviewer) {
				this.prizmviewer.viewerControl.deserializeMarks([]);
				setTimeout(() => {
					this.prizmviewer.viewerControl.deserializeMarks(result);
				}, 100);
			}
			if (this.annotationviewer) {
				this.annotationviewer.viewerControl.deserializeMarks([]);
				setTimeout(() => {
					this.annotationviewer.viewerControl.deserializeMarks(result);
				}, 100);
			}
		});
	}

	saveAnnotationsHandler() {
		// Determine if there are existing annotations in the database. If there are, ensure ours are newer before overriding.
		this.mfilesService.getProperties(0, this.id, -1).subscribe((response) => {
			const latestVersion = response.body.objVer.version;
			if (latestVersion > this.revision) {
				this.toastr.warning('There is a newer version of the document. Please refresh.');
			} else {
				this.saveAnnotations();
			}
		});
	}

	saveAnnotations() {
		this.loading = true;

		if (this.enableAnnotations && this.helloSignHideRedactClasses.indexOf(this.class) === -1 && this.hasAnnotations()) {
			this.redact();
		} else {
			// Save the annotations.
			const allMarks = this.annotationviewer.viewerControl.getAllMarks();

			if (allMarks.length > 0) {
				// Annotations found.
				this.annotationviewer.viewerControl.serializeMarks(allMarks).then(markObjects => {
					this.mfilesService.updateAnnotations(this.id, markObjects).subscribe(() => {
						this.toastr.success('The annotations were saved successfully.');
						this.initPrizmViewer(this.xViewSessionId, false);
					});
				});
			} else {
				this.mfilesService.updateAnnotations(this.id, []).subscribe(() => {
					this.toastr.success('The annotations were saved successfully.');
					this.initPrizmViewer(this.xViewSessionId, false);
				});
			}
		}
	}

	reject() {
		this.modalRef.hide();
		if (this.helloSignRedactReject) {
			this.rejecting = true;
			this.redact();
		} else {
			this.doHelloSignReject();
		}
	}

	doHelloSignReject() {
		this.loading = true;
		this.mfilesService.helloSignReject(this.id, this.rejectComment).subscribe((result) => {
			this.toastr.success(this.helloSignRejectLabel + ' successful');
			this.router.navigate(['page', 0]);
		});
	}

	sign() {
		if (this.helloSignRedactSign) {
			this.signing = true;
			this.redact();
		} else {
			this.doHelloSignSignature();
		}
	}

	doHelloSignSignature() {
		this.loading = true;
		this.mfilesService.helloSignStart(this.id).subscribe((result) => {
			const client = new HelloSign();

			client.open(result, {
				clientId: this.helloSignApiKey,
				skipDomainVerification: true
			});

			client.on('sign', () => {
				this.toastr.success(this.helloSignSignSuccess);
				this.router.navigate(['page', 'welcome']);
			});

			this.loading = false;
		});
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.show(
			template,
			{ class: 'modal-' + size, backdrop: 'static' }
		);
	}

	hasAnnotations() {
		return this.prizmviewer && this.prizmviewer.viewerControl.getAllMarks().length > 0;
	}

	redact() {
		this.loading = true;
		// Save the annotations.
		const allMarks = this.annotationviewer.viewerControl.getAllMarks();

		if (allMarks.length > 0) {
			// Annotations found.
			this.annotationviewer.viewerControl.serializeMarks(allMarks).then(markObjects => {
				this.doRedaction(markObjects);
			});
		} else {
			this.doRedaction([]);
		}
	}

	doRedaction(annotations: any) {
		this.mfilesService.updateAnnotations(this.id, annotations).subscribe(() => {
			this.mfilesService.prizmRedaction(this.id).subscribe(() => {
				this.loadViewer();
				if (this.rejecting) {
					this.rejecting = false;
					this.doHelloSignReject();
				} else if (this.signing) {
					this.signing = false;
					this.doHelloSignSignature();
				} else {
					this.loading = false;
				}
			});
		});
	}

	openAnnotationModal() {
		this.modalRef = this.modalService.show(
			this.annotationModal,
			{ class: 'modal-xl', backdrop: 'static', keyboard: false }
		);
		this.initPrizmViewer(this.xViewSessionId, true);
	}

}
