import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { DateTime } from 'luxon';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
	// tslint:disable-next-line:component-selector
	selector: '[toast-container]',
	template: ` 
    <span *ngIf="message && options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass" [innerHTML]="message"></span>
    <span *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message"> {{ message }}</span>
  `,
	animations: [
		trigger('flyInOut', [
			state('inactive', style({
				opacity: 0
			})),
			transition('inactive => active', animate('400ms ease-in', keyframes([
				style({
					transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
					opacity: 0,
				}),
				style({
					transform: 'skewX(20deg)',
					opacity: 1,
				}),
				style({
					transform: 'skewX(-5deg)',
					opacity: 1,
				}),
				style({
					transform: 'none',
					opacity: 1,
				}),
			]))),
			transition('active => removed', animate('400ms ease-out', keyframes([
				style({
					opacity: 1,
				}),
				style({
					transform: 'translate3d(100%, 0, 0) skewX(30deg)',
					opacity: 0,
				}),
			]))),
		]),
	],
	preserveWhitespaces: false,
})
export class MFilesToastComponent extends Toast {

	// constructor is only necessary when not using AoT
	constructor(
		protected toastrService: ToastrService,
		public toastPackage: ToastPackage,
	) {
		super(toastrService, toastPackage);

		this.addMessageToCache();

	}

	// Add the toastr message to session storage cache.
	addMessageToCache() {
		const newEntry = {
			date: DateTime.fromJSDate(new Date()).toFormat('MM/dd/yyyy t'),
			type: this.toastPackage.toastType,
			message: this.toastrService.previousToastMessage
		};

		if (sessionStorage.getItem('ngx_toastr') && sessionStorage.getItem('ngx_toastr').length > 0) {
			const oldEntries = JSON.parse(sessionStorage.getItem('ngx_toastr'));

			// Only keep 30 messages
			if (oldEntries.length === 30) {
				oldEntries.pop();
			}

			oldEntries.unshift(newEntry);
			sessionStorage.setItem('ngx_toastr', JSON.stringify(oldEntries));
		} else {
			sessionStorage.setItem('ngx_toastr', JSON.stringify([newEntry]));
		}
	}
}
