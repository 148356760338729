import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MFilesItem } from '../models/mfiles-item.model';
import { Property } from '../models/property.model';
import { SharesLink } from '../models/shares-link.model';
import { MFilesService } from '../services/mfiles.service';
import { PageService } from '../services/page.service';

@Component({
	selector: 'app-shares',
	templateUrl: './shares.component.html',
	styleUrls: ['./shares.component.css']
})
export class SharesComponent implements OnInit {
	@ViewChild('fileShareCreateModal') private fileShareCreateModal: TemplateRef<any>;
	@ViewChild('fileShareDeleteModal') private fileShareDeleteModal: TemplateRef<any>;
	@ViewChild('viewerModal') private viewerModal: TemplateRef<any>;

	modalRef: BsModalRef;

	loading = true;

	fields = [];
	formData = {};
	tempFormData = {};
	optionLists = {};

	shareDoc = {};
	shareDocs = [];
	searchDocs = [];

	shareLinks: SharesLink[] = [];

	fileShareClass: any;

	shares = [];
	sharesLoaded = false;

	activeShare = -1;

	updateShare = false;

	deleteTarget: any;

	hasChanged = false;

	fileShareWizardStep = 1;
	showShareEmptyConfirmMessage = false;

	public selectedShareIndex;
	public selectedDocumentIndex;

	viewerModalSettings = {
		displayViewer: true,
		displayMetadata: false,
		displayComments: false,
		displayWorkflow: false,
		disableAnnotations: true
	};

	constructor(
		private modalService: BsModalService,
		private mfilesService: MFilesService,
		private pageService: PageService,
		private toastr: ToastrService
	) { }

	ngOnInit(): void {
		this.mfilesService.getClassesByAlias(['TI.MConnect.Class.ExternalFileShare']).subscribe((classAliasResult) => {
			this.mfilesService.getPropertyDefsByAlias(['TI.MConnect.Property.ExternalLinks']).subscribe((propertyAliasResult) => {
				this.mfilesService.getClassProperties(classAliasResult.body['TI.MConnect.Class.ExternalFileShare']).subscribe((properties: any[]) => {
					this.fields = [];
					for (const prop of properties) {
						if (prop.id !== propertyAliasResult.body['TI.MConnect.Property.ExternalLinks']) {
							prop.property = prop.id;
							prop.propertyDef = prop.id;
							this.fields.push(prop);
						}
					}

					this.mfilesService.getOptionLists(this.fields).subscribe((result) => {
						this.optionLists = result.body;
					});

					this.fileShareClass = classAliasResult.body['TI.MConnect.Class.ExternalFileShare'];
				});
			});
		});

		this.loadShares();
	}

	loadShares() {
		this.loading = true;
		this.mfilesService.getExternalShares().subscribe((result: any[]) => {
			this.shares = result;
			this.shares.map(share => ({ ...share, hasDocs: false, hasLinks: false }));
			this.sharesLoaded = true;
			this.loading = false;
			this.activeShare = -1;
		});
	}

	shareClicked(share: any, i: number) {
		if (this.activeShare == i) {
			this.activeShare = -1;
		} else {
			if (!share.docs && !share.links) {
				this.loading = true;
				this.mfilesService.getExternalShareContents(share.id).subscribe((result) => {
					share.docs = result.docs;
					share.links = result.links;
					share.expiration = result.expiration;
					share.uploadClass = result.uploadClass;
					share.hasDocs = !!result.docs.length;
					share.hasLinks = !!result.links.length;
					this.loading = false;
				});
			}
			this.activeShare = i;
		}
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.show(
			template,
			{ backdrop: 'static', class: 'modal-' + size, animated: false }
		);
	}

	openCreateShare() {
		this.fileShareWizardStep = 1;
		this.formData[100] = this.fileShareClass;
		this.openModal(this.fileShareCreateModal, 'lg')
	}

	resetCreateShare() {
		this.formData = {};
		this.tempFormData = {};
		this.shareDoc = {};
		this.shareDocs = [];
		this.searchDocs = [];
		this.shareLinks = [];
		this.updateShare = false;
		this.showShareEmptyConfirmMessage = false;
		this.fileShareWizardStep = 1;
	}

	inputChanged() {
		this.hasChanged = true;
	}

	selectFieldValueChanged(field: any) {
		const valueListForField = this.optionLists[field.property];

		for (const propertyDef in this.optionLists) {
			const childValueList = this.optionLists[propertyDef];

			if (childValueList.hasOwner && childValueList.owner === valueListForField.id && this.optionLists[propertyDef].childItemsMap) {
				if (field.dataType === 9) {
					const ownerValue: number = this.formData[field.property];
					this.optionLists[propertyDef].items = this.optionLists[propertyDef].childItemsMap[ownerValue];
				} else if (field.dataType === 10) {
					const ownerValues: any[] = this.formData[field.property];
					this.optionLists[propertyDef].items = [];

					for (const ownerValue of ownerValues) {
						this.optionLists[propertyDef].items = this.optionLists[propertyDef].items.concat(this.optionLists[propertyDef].childItemsMap[ownerValue.id]);
					}
				}
			}
		}
		this.hasChanged = true;
	}

	multiselectChanged(field: any, event: any) {
		let containsValue = false;

		if (!this.formData[field.property]) {
			this.formData[field.property] = [];
		} else {
			this.formData[field.property].forEach(value => {
				if (value.id === event.id) {
					containsValue = true;
				}
			});
		}

		if (!containsValue) {
			this.formData[field.property].push({ name: event.name, id: event.id });
		}
		this.hasChanged = true;
	}

	removeMultiselect(field: any, i: number) {
		this.formData[field.property].splice(i, 1);
		this.selectFieldValueChanged(field);
		this.hasChanged = true;
	}

	shareDocChanged(event: any) {
		let containsValue = false;

		if (!this.shareDocs) {
			this.shareDocs = [];
		} else {
			this.shareDocs.forEach(value => {
				if (value.id === event.objVer.id) {
					containsValue = true;
				}
			});
		}

		if (!containsValue) {
			this.shareDocs.push({ title: event.title, id: event.objVer.id });
		}
		this.hasChanged = true;
	}

	shareDocSearch(event: any) {
		this.mfilesService.shareDocSearch(event.term).subscribe((result: any) => {
			this.searchDocs = result.items;
		});
	}

	removeShareDoc(i: number) {
		this.shareDocs.splice(i, 1);
	}

	cancelCreateShare() {
		this.showShareEmptyConfirmMessage = false;
	}

	createShare(confirmed: boolean) {
		this.loading = true;

		if (!confirmed && (this.shareDocs.length == 0 || this.shareLinks.length == 0)) {
			this.showShareEmptyConfirmMessage = true;
			this.loading = false;
		} else {
			const [properties, docs] = this.getPropsAndDocs();
			const shareInfo = {
				properties,
				docs,
				links: this.shareLinks
			}

			this.mfilesService.createExternalShare(shareInfo).subscribe((result) => {
				this.resetCreateShare();
				this.toastr.success('External Share Created');
				this.loadShares();

				this.modalRef.hide();

				this.loading = false;
			});
		}
	}

	createShareAndMoveStep() {
		this.loading = true;

		const [properties, docs] = this.getPropsAndDocs();
		const shareInfo = {
			properties,
			docs,
			links: this.shareLinks
		}

		this.mfilesService.createExternalShare(shareInfo).subscribe((result) => {
			this.loadShares();
			this.fileShareWizardStep = 2;
			this.updateShare = true;
			this.loading = false;
		});
	}

	getPropsAndDocs() {
		let properties = [];
		let docs = '';

		for (const field of this.fields) {
			if (this.formData[field.id]) {
				const property = new Property();
				property.id = field.id;
				property.dataType = field.dataType;

				if (property.dataType === 10) {
					let value = '';
					for (let i = 0; i < this.formData[field.property].length; i++) {
						value = value + this.formData[field.property][i].id + ',';
					}
					if (value !== '') {
						value = value.substring(0, value.length - 1);
					}
					property.value = value;
				} else {
					property.value = this.formData[field.id];
				}

				properties.push(property);
			}
		}

		for (let i = 0; i < this.shareDocs.length; i++) {
			docs = docs + this.shareDocs[i].id + ',';
		}
		if (docs !== '') {
			docs = docs.substring(0, docs.length - 1);
		}
		return [properties, docs];
	}

	validForm() {
		let valid = true;

		for (const field of this.fields) {
			if (field.required && !this.formData[field.property]) {
				valid = false;
				break;
			}
		}
		/**
		for (const link of this.shareLinks) {
			if (!link.name) {
				valid = false;
				break;
			}
		}
			*/
		return valid;
	}

	addLink() {
		this.shareLinks.push(new SharesLink());
	}

	removeLink(i: number) {
		this.shareLinks.splice(i, 1);
	}

	editFileShares(share: any) {
		if (!share.docs && !share.links) {
			this.loading = true;
			this.mfilesService.getExternalShareContents(share.id).subscribe((result) => {
				share.docs = result.docs;
				share.links = result.links;
				share.expiration = result.expiration;
				share.uploadClass = result.uploadClass;
				this.loading = false;
				this.setUpdateModal(share);
			});
		} else {
			this.setUpdateModal(share);
		}
	}

	setUpdateModal(share: any) {
		this.formData[0] = share.name;
		this.formData[100] = this.fileShareClass;
		this.formData[1062] = [...share.links];
		for (const doc of share.docs) {
			this.shareDocs.push({
				title: doc.name + '.' + doc.extension,
				id: doc.id
			});
		}
		for (const link of share.links) {
			this.setLinks(link);
		}
		if (share.expiration) {
			this.formData[1054] = new Date(share.expiration);
		}
		if (share.uploadClass) {
			this.formData[1059] = parseInt(share.uploadClass);
		}
		this.updateShare = true;
		this.hasChanged = false;
		this.openModal(this.fileShareCreateModal, 'lg');
	}

	setLinks(link) {
		let formattedLink = new SharesLink();
		formattedLink.name = link.name;
		for (let linkPermission of link.permissions.split(';')) {
			formattedLink[this.camelCase(linkPermission.trim())] = true;
		}
		this.shareLinks.push(formattedLink);
	}

	camelCase(linkPermission: string): string {
		return linkPermission.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		}).replace(/\s+/g, '');
	}

	updateFileShare(confirmed: boolean) {
		if (!confirmed && (this.shareDocs.length == 0 || this.shareLinks.length == 0)) {
			this.showShareEmptyConfirmMessage = true;
			this.loading = false;
		} else {
			const [properties, docs] = this.getPropsAndDocs();
			const filteredProps = [...properties].filter(prop => prop.id !== 100);

			const shareInfo = {
				properties: filteredProps,
				docs,
				links: this.shareLinks
			}

			this.loading = true;
			this.modalRef.hide();
			this.mfilesService.updateExternalShare(shareInfo).subscribe((result) => {
				this.resetCreateShare();
				this.toastr.success('External Share Updated');
				this.loadShares();
			});
		}
	}

	openDeleteShare(share: any) {
		this.deleteTarget = share;
		this.openModal(this.fileShareDeleteModal, 'md');
	}

	deleteFileShare() {
		this.modalRef.hide();
		this.loading = true;
		this.mfilesService.deleteExternalShare(this.deleteTarget.id).subscribe((result) => {
			for (let i = 0; i < this.shares.length; i++) {
				if (this.shares[i] === this.deleteTarget) {
					this.shares.splice(i, 1);
				}
			}
			this.loading = false;
			this.toastr.success('External Share Deleted');
		});
	}

	openShareLink(link: any) {
		window.open(link.url);
	}

	copyShareLink(link: any) {
		navigator.clipboard.writeText(link.url);
		this.toastr.success('M-Connect Share URL saved to clipboard');
	}

	fileClicked(obj: MFilesItem, shareIndex: number, documentIndex: number) {
		if (shareIndex > -1) {
			this.selectedShareIndex = shareIndex;
		} else {
			this.selectedShareIndex = -1;
		}

		if (documentIndex > -1) {
			this.selectedDocumentIndex = documentIndex;
		} else {
			this.selectedDocumentIndex = -1;
		}

		const context = {
			itemId: obj.id,
			type: 0,
			fileIndex: 0
		};

		let triggerModal = false;
		if (!this.modalRef) {
			triggerModal = true;
		}

		if (triggerModal) {
			this.openModal(this.viewerModal, 'lg bid-request');
		} else {
			this.pageService.documentSelectionOccurred.emit(context);
		}

		setTimeout(() => {
			if (triggerModal) {
				this.pageService.documentSelectionOccurred.emit(context);
			}
		}, 500);
	}

	prevFileClicked() {
		if (this.selectedDocumentIndex > 0) {
			let newDocumentIndex = this.selectedDocumentIndex - 1;
			this.fileClicked(this.shares[this.selectedShareIndex].docs[newDocumentIndex], this.selectedShareIndex, newDocumentIndex);
		}
	}

	nextFileClicked() {
		if (this.selectedDocumentIndex <= this.shares[this.selectedShareIndex].docs.length) {
			let newDocumentIndex = this.selectedDocumentIndex + 1;
			this.fileClicked(this.shares[this.selectedShareIndex].docs[newDocumentIndex], this.selectedShareIndex, newDocumentIndex);
		}
	}

	closeViewerModal() {
		if (this.modalRef) {
			this.modalRef.hide();
			this.modalRef = null;
		}
	}
}