import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { User } from '../models/user.model';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	constructor(
		private http: HttpClient
	) { }

	verifyAzure() {
		const headers = new HttpHeaders();

		return this.http.get<boolean>(`${environment.serviceUrl}/login/azure/verify`, { headers });
	}

	azureLogin() {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.set('angularHost', window.location.origin);
		params = params.set('serviceHost', `${environment.serviceUrl}`);

		return this.http.get(`${environment.serviceUrl}/login/azure`, { headers, params, responseType: 'text' });
	}

	azureUserInfo() {
		const headers = new HttpHeaders();

		return this.http.get<User>(`${environment.serviceUrl}/login/azure/info`, { headers });
	}
}
