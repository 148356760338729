import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { AngularSplitModule } from 'angular-split';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { WidgetViewerComponent } from '../page/widget/viewer/viewer.component';
import { UploadModule } from '../upload/upload.module';
import { PreviewButtonRendererComponent } from '../view-static/widget/preview-button/preview_button-renderer.component';
import { TypeaheadSelectRendererComponent } from '../view-static/widget/typeahead-select/typeahead-select.component';
import { ViewWidgetComponent } from '../view-static/widget/view-widget.component';
import { CommentsComponent } from './comments/comments.component';
import { MFObjectSizePipe } from './pipes/mfObjectSize.pipe';
import { PipesModule } from './pipes/pipes.module';
import { TableRowComponent } from './row/table-row.component';
import { ViewFolderRowComponent } from './view-folder-row/view-folder-row.component';

export function getDatepickerConfig(): BsDatepickerConfig {
	return Object.assign(new BsDatepickerConfig(), {
		showWeekNumbers: false,
		dateInputFormat: 'DD/MM/YYYY HH:mm'
	});
}

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		UploadModule,
		ReactiveFormsModule,
		AngularSplitModule,
		BsDropdownModule.forRoot(),
		CollapseModule.forRoot(),
		ModalModule.forRoot(),
		TabsModule.forRoot(),
		PopoverModule.forRoot(),
		BsDatepickerModule.forRoot(),
		PipesModule,
		NgSelectModule,
		AgGridModule,
		MatProgressBarModule
	],
	declarations: [
		CommentsComponent,
		ViewWidgetComponent,
		ViewFolderRowComponent,
		TableRowComponent,
		PreviewButtonRendererComponent,
		TypeaheadSelectRendererComponent,
		WidgetViewerComponent
	],
	exports: [
		CommentsComponent,
		CommonModule,
		FormsModule,
		UploadModule,
		ReactiveFormsModule,
		BsDropdownModule,
		BsDatepickerModule,
		AngularSplitModule,
		CollapseModule,
		TabsModule,
		PopoverModule,
		ModalModule,
		PipesModule,
		ViewWidgetComponent,
		ViewFolderRowComponent,
		TableRowComponent,
		WidgetViewerComponent
	],
	providers: [
		{ provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
		DatePipe,
		MFObjectSizePipe
	]
})
export class SharedModule { }
