import { Directive, HostBinding, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[spinner]'
})
export class SpinnerDirective implements OnDestroy {

	@HostBinding('class.in') showSpinner = false;

	private subscription: Subscription;

	constructor(
		private authService: AuthService
	) {
		this.subscription = this.authService.loading.subscribe((loading: boolean) => {
			this.showSpinner = loading;
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
