<div class="main-content-wrapper">
	<div class="main-content">
		<header class="main-content-header">
			<ng-container *ngIf="canAnnotate && enableAnnotations">
				<button type="button" [disabled]="loading" (click)="openAnnotationModal()" class="btn btn-danger">{{ annotateButtonLabel }}</button>&nbsp;
			</ng-container>
			<button type="button" [disabled]="loading || signed" (click)="openModal(rejectCommentDialog, 'lg')" class="btn btn-danger">{{ helloSignRejectLabel }}</button>&nbsp;
			<button type="button" [disabled]="loading || signed" (click)="sign()" class="btn btn-primary">{{ helloSignSignLabel }}</button>&nbsp;
			{{ docTitle }}
			<a *ngIf="helloSignMessage" class="info-link" href="javascript:" (click)="openModal(signInfoModal, 'lg')">
				<em class="fas fa-question tooltip-icon" tooltip="Text property" placement="bottom"></em>
			</a>
		</header>
		<div class="main-content-area global-settings" [ngClass]="{'loading in': loading || rejecting || signing }">
			<div class="the-preview">
				<div class="the-document">
					<div class="full-width viewer pccv" id="prizmviewer"></div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #signInfoModal>
	<div class="modal-body">
		<div *ngIf="helloSignMessage" [innerHTML]="helloSignMessage | safeHtml"></div>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Close</button>
	</div>
</ng-template>

<ng-template #rejectCommentDialog>
	<div class="modal-header">
		<h5 class="modal-title"><em class="fas fa-pencil"></em> Comment</h5>
	</div>
	<div class="modal-body share-dialog">
		<textarea class="workflow-comments-text-area" placeholder="Comment" [(ngModel)]="rejectComment"></textarea>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="reject()">{{ helloSignRejectLabel }}</button>
	</div>
</ng-template>

<ng-template #annotationModal>
	<div class="modal-body">
		<div class="the-preview">
			<div class="the-document">
				<div class="full-width viewer modal-viewer pccv" id="annotationviewer"></div>
			</div>
		</div>
	</div>
	<div class="modal-footer no-bg">
		<button type="button" class="btn btn-primary btn-md" data-dismiss="modal" (click)="saveAnnotationsHandler(); modalRef.hide()">{{ saveAnnotationsButtonLabel }}</button>
		<button type="button" class="btn btn-default btn-md" data-dismiss="modal" (click)="modalRef.hide()">Cancel</button>
	</div>
</ng-template>