import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject, EMPTY, Observable, Subject, lastValueFrom, of } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { CommentObject } from '../models/comment.model';
import { MFilesItem } from '../models/mfiles-item.model';
import { Property } from '../models/property.model';
import { SearchFilter } from '../models/search-filter.model';
import { User } from '../models/user.model';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class MFilesService {
	// Used for caching of data
	private vaultProperties: Observable<any>;
	private vaultValueLists: Observable<any>;
	private vaultSecurityGroups: Observable<any>;
	private objectTypes: Observable<any>;
	private workflows: Observable<any>;
	private workflowStates = {};
	private valueListOptions = {};
	private objectTypeIcons = {};
	private classesByObjectType = {};
	private classes = {};
	private propertiesByClass = {};
	private userInfo = {};
	private tasklists = {};
	private classesByAlias = {};
	private statesByAlias = {};
	private workflowsByAlias = {};
	private propertyDefsByAlias = {};
	private itemsProperties = {};
	private itemFiles = {};
	public setPaths = new Subject<any>();
	public loadView = new Subject<any>();

	public coauthorExtensions = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'csv'];

	public microserviceUploadProgress = {};
	public uploadProgressUpdated = new Subject<any>();
	public cancelMicroserviceUpload = new Subject();
	public microserviceUploadCanceled = false;

	constructor(
		private http: HttpClient,
		private authService: AuthService
	) { }

	clearCache() {
		this.vaultProperties = null;
		this.vaultValueLists = null;
		this.vaultSecurityGroups = null;
		this.objectTypes = null;
		this.workflows = null;
		this.workflowStates = {};
		this.valueListOptions = {};
		this.objectTypeIcons = {};
		this.classesByObjectType = {};
		this.classes = {};
		this.propertiesByClass = {};
		this.userInfo = {};
		this.tasklists = {};
		this.classesByAlias = {};
		this.statesByAlias = {};
		this.workflowsByAlias = {};
		this.propertyDefsByAlias = {};
		this.itemsProperties = {};
	}

	vaultExists() {
		const headers = new HttpHeaders();

		return this.http.get<boolean>(`${environment.serviceUrl}/vault/exists`, { headers });
	}

	getVaultWorkspaces() {
		const headers = new HttpHeaders();

		return this.http.get(`${environment.serviceUrl}/vault/workspaces`, { headers });
	}

	getPublicVaultWorkspaces() {
		const headers = new HttpHeaders();

		return this.http.get(`${environment.serviceUrl}/vault/public-workspaces`, { headers });
	}

	getModules() {
		const headers = new HttpHeaders();

		return this.http.get(`${environment.serviceUrl}/dashboard/modules`, { headers });
	}

	setInitialVaultWorkspace(workspace: any) {
		if (!workspace.password) {
			workspace.password = '';
		}

		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		headers = headers.set('LoginAuth', `Basic ${btoa(workspace.username + ':' + workspace.password)}`);

		workspace.password = undefined;

		const requestUrl = `${environment.serviceUrl}/vault/initial-workspace`;

		return this.http.put(requestUrl, JSON.stringify(workspace), { headers, responseType: 'text' });
	}

	addVaultWorkspace(workspace: any) {
		if (!workspace.password) {
			workspace.password = '';
		}

		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		headers = headers.set('LoginAuth', `Basic ${btoa(workspace.username + ':' + workspace.password)}`);

		workspace.password = undefined;

		const requestUrl = `${environment.serviceUrl}/vault/workspace`;

		return this.http.post(requestUrl, JSON.stringify(workspace), { headers, observe: 'response' });
	}

	updateVaultWorkspace(workspace: any) {
		if (!workspace.password) {
			workspace.password = '';
		}

		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		headers = headers.set('LoginAuth', `Basic ${btoa(workspace.username + ':' + workspace.password)}`);

		workspace.password = undefined;

		const requestUrl = `${environment.serviceUrl}/vault/workspace`;

		return this.http.put(requestUrl, JSON.stringify(workspace), { headers, observe: 'response' });
	}

	deleteVaultWorkspace(workspace: any) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.set('deleteWorkspace', workspace);

		const requestUrl = `${environment.serviceUrl}/vault/workspace`;

		return this.http.delete(requestUrl, { headers, params, observe: 'response' });
	}

	ping() {
		const headers = new HttpHeaders();

		return this.http.get(`${environment.serviceUrl}/dashboard/ping`, { headers });
	}

	checkAliases() {
		const headers = new HttpHeaders();

		return this.http.get(`${environment.serviceUrl}/dashboard/checkAliases`, { headers });
	}

	getErrorLogs() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = `${environment.serviceUrl}/errors`;

		return this.http.get(requestUrl, { headers, observe: 'response' });
	}

	getWorkspaces() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = `${environment.serviceUrl}/dashboard/workspaces`;

		return this.http.get(requestUrl, { headers, observe: 'response' });
	}

	syncWorkspaces() {
		const headers = new HttpHeaders();
		const params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/dashboard/workspaces/sync`;

		return this.http.post<boolean>(requestUrl, { headers, params, observe: 'response' });
	}

	getObjectTypes() {
		if (this.objectTypes) {
			return this.objectTypes;
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			const requestUrl = `${environment.serviceUrl}/mfiles/objectTypes`;

			this.objectTypes = this.http.get(requestUrl, { headers, observe: 'response' })
				.pipe(
					shareReplay(1),
					catchError(err => {
						this.objectTypes = new Observable<any>();
						return EMPTY;
					})
				);
			return this.objectTypes;
		}
	}

	getVaultSecurityGroups() {
		if (this.vaultSecurityGroups) {
			return this.vaultSecurityGroups;
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			const requestUrl = `${environment.serviceUrl}/mfiles/securityGroups`;

			this.vaultSecurityGroups = this.http.get(requestUrl, { headers, observe: 'response' })
				.pipe(
					shareReplay(1),
					catchError(err => {
						this.vaultSecurityGroups = new Observable<any>();
						return EMPTY;
					})
				);
			return this.vaultSecurityGroups;
		}
	}

	getVaultProperties() {
		if (this.vaultProperties) {
			return this.vaultProperties;
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			const requestUrl = `${environment.serviceUrl}/mfiles/properties`;

			this.vaultProperties = this.http.get(requestUrl, { headers, observe: 'response' })
				.pipe(
					shareReplay(1),
					catchError(err => {
						this.vaultProperties = new Observable<any>();
						return EMPTY;
					})
				);
			return this.vaultProperties;
		}
	}

	getUserValueLists() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = `${environment.serviceUrl}/mfiles/userValueLists`;

		this.vaultValueLists = this.http.get(requestUrl, { headers, observe: 'response' });

		return this.http.get<any[]>(requestUrl, { headers });
	}

	getVaultValueLists() {
		if (this.vaultValueLists) {
			return this.vaultValueLists;
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			const requestUrl = `${environment.serviceUrl}/mfiles/valueLists`;

			this.vaultValueLists = this.http.get(requestUrl, { headers, observe: 'response' })
				.pipe(
					shareReplay(1),
					catchError(err => {
						this.vaultValueLists = null;
						return EMPTY;
					})
				);
			return this.vaultValueLists;
		}
	}

	getValueListOptions(id: number) {
		if (this.valueListOptions[id]) {
			return this.valueListOptions[id];
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			let params = new HttpParams();
			params = params.set('id', id + '');

			const requestUrl = `${environment.serviceUrl}/mfiles/valueListOptions`;

			this.valueListOptions[id] = this.http.get(requestUrl, { params, headers, observe: 'response' })
				.pipe(
					shareReplay(1),
					catchError(err => {
						delete this.valueListOptions[id];
						return EMPTY;
					})
				);
			return this.valueListOptions[id];
		}
	}

	getWorkflows() {
		if (this.workflows) {
			return this.workflows;
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			const requestUrl = `${environment.serviceUrl}/mfiles/workflows`;

			this.workflows = this.http.get(requestUrl, { headers, observe: 'response' })
				.pipe(
					shareReplay(1),
					catchError(err => {
						this.workflows = new Observable<any>();
						return EMPTY;
					})
				);
			return this.workflows;
		}
	}

	getWorkflowStates(workflowId: number) {
		if (this.workflowStates[workflowId]) {
			return this.workflowStates[workflowId];
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			let params = new HttpParams();
			params = params.set('workflowId', workflowId + '');

			const requestUrl = `${environment.serviceUrl}/mfiles/workflow-states`;

			this.workflowStates[workflowId] = this.http.get(requestUrl, { headers, params, observe: 'response' })
				.pipe(
					shareReplay(1),
					catchError(err => {
						delete this.workflowStates[workflowId];
						return EMPTY;
					})
				);
			return this.workflowStates[workflowId];
		}
	}

	getWorkflowStateTransitions(type: number, id: number, workflowId: number, currentState: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.set('type', type + '');
		params = params.set('id', id + '');
		params = params.set('workflowId', workflowId + '');
		params = params.set('currentState', currentState + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/workflow-state-transitions`;

		return this.http.get<any[]>(requestUrl, { headers, params });
	}

	getOptionLists(fields: any[]) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/mfiles/option-lists`;

		return this.http.post(requestUrl, JSON.stringify(fields), { headers, params, observe: 'response' });
	}

	checkClassPermissions(classIds: any[]) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/mfiles/checkClassPermissions`;

		return this.http.post(requestUrl, JSON.stringify(classIds), { headers, params, observe: 'response' });
	}

	pageSearch(filters: SearchFilter[], pageId: number, widgetId: string, itemType: number, searchTerm: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.set('pageId', pageId + '');
		params = params.set('widgetId', widgetId + '');
		params = params.set('itemType', itemType + '');
		params = params.set('searchTerm', searchTerm);

		const requestUrl = `${environment.serviceUrl}/dashboard/page-search`;

		return this.http.post(requestUrl, JSON.stringify(filters), { headers, params, observe: 'response' });
	}

	getObjectTypeIcon(type: number) {
		if (this.objectTypeIcons[type]) {
			return this.objectTypeIcons[type];
		} else {
			const headers = new HttpHeaders();

			let params = new HttpParams();
			params = params.set('type', type + '');

			const requestUrl = `${environment.serviceUrl}/mfiles/objectTypeIcon`;

			this.objectTypeIcons[type] = this.http.get(requestUrl, {
				responseType: 'blob',
				headers,
				params,
				observe: 'response'
			}).pipe(
				shareReplay(1),
				catchError(err => {
					delete this.objectTypeIcons[type];
					return EMPTY;
				})
			);
			return this.objectTypeIcons[type];
		}
	}

	getClassesByAlias(aliases: string[]) {
		if (this.containsAllClassAliases(aliases)) {
			return new BehaviorSubject({ body: this.classesByAlias }).asObservable();
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			const requestUrl = `${environment.serviceUrl}/mfiles/classesByAlias`;

			return this.http.post(requestUrl, JSON.stringify(aliases), { headers, observe: 'response' })
				.pipe(
					tap((response) => {
						for (const alias in response.body) {
							this.classesByAlias[alias] = parseInt(response.body[alias], 10);
						}
					})
				);
		}
	}

	getWorkflowsByAlias(aliases: string[]) {
		if (this.containsAllWorkflowAliases(aliases)) {
			return new BehaviorSubject({ body: this.workflowsByAlias }).asObservable();
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			const requestUrl = `${environment.serviceUrl}/mfiles/workflowsByAlias`;

			return this.http.post(requestUrl, JSON.stringify(aliases), { headers, observe: 'response' })
				.pipe(
					tap((response) => {
						for (const alias in response.body) {
							this.workflowsByAlias[alias] = parseInt(response.body[alias], 10);
						}
					})
				);
		}
	}

	getStatesByAlias(aliases: string[]) {
		if (this.containsAllStateAliases(aliases)) {
			return new BehaviorSubject({ body: this.statesByAlias }).asObservable();
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			const requestUrl = `${environment.serviceUrl}/mfiles/statesByAlias`;

			return this.http.post(requestUrl, JSON.stringify(aliases), { headers, observe: 'response' })
				.pipe(
					tap((response) => {
						for (const alias in response.body) {
							this.statesByAlias[alias] = parseInt(response.body[alias], 10);
						}
					})
				);
		}
	}

	getPropertyDefsByAlias(aliases: string[]) {
		if (this.containsAllPropertyDefAliases(aliases)) {
			return new BehaviorSubject({ body: this.propertyDefsByAlias }).asObservable();
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			const requestUrl = `${environment.serviceUrl}/mfiles/propertiesByAlias`;

			return this.http.post(requestUrl, JSON.stringify(aliases), { headers, observe: 'response' })
				.pipe(
					tap((response) => {
						for (const alias in response.body) {
							this.propertyDefsByAlias[alias] = parseInt(response.body[alias], 10);
						}
					})
				);
		}
	}

	getProperties(type: number, id: number, version: number) {
		if (version !== -1 && this.itemsPropertiesContains(type, id, version)) {
			return this.getCachedItemsProperties(type, id, version);
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			let params = new HttpParams();
			params = params.append('type', type + '');
			params = params.append('id', id + '');
			params = params.append('version', version + '');

			const requestUrl = `${environment.serviceUrl}/mfiles/itemProperties`;

			const response = this.http.get(requestUrl, { headers, params, observe: 'response' })
				.pipe(
					shareReplay(1),
					catchError(err => {
						this.removeItemsProperties(type, id, version);
						return EMPTY;
					})
				);
			this.addResponseToItemsProperties(response, type, id, version);
			return response;
		}
	}

	getFiles(type: number, id: number, version: number) {
		if (version !== -1 && this.itemFilesContains(type, id, version)) {
			return this.getCachedItemFiles(type, id, version);
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			let params = new HttpParams();
			params = params.append('type', type + '');
			params = params.append('id', id + '');
			params = params.append('version', version + '');

			const requestUrl = `${environment.serviceUrl}/mfiles/files`;

			const response = this.http.get(requestUrl, { headers, params, observe: 'response' })
				.pipe(
					shareReplay(1),
					catchError(err => {
						this.removeItemFiles(type, id, version);
						return EMPTY;
					})
				);
			this.addResponseToItemFiles(response, type, id, version);
			return response;
		}
	}

	pageDownload(type: number, id: number, version: number, fileIndex: number, pageId: number, widgetId: string) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.set('type', type + '');
		params = params.set('id', id + '');
		params = params.set('version', version + '');
		params = params.set('fileIndex', fileIndex + '');
		params = params.set('pageId', pageId + '');
		params = params.set('widgetId', widgetId);

		const requestUrl = `${environment.serviceUrl}/dashboard/page-download`;

		return this.http.get(requestUrl, { responseType: 'blob', headers, params, observe: 'response' });
	}

	download(type: number, id: number, version: number, fileIndex: number) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.set('type', type + '');
		params = params.set('id', id + '');
		params = params.set('version', version + '');
		params = params.set('fileIndex', fileIndex + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/download`;

		return this.http.get(requestUrl, { responseType: 'blob', headers, params, observe: 'response' });
	}

	downloadReportProgress(type: number, id: number, version: number, fileIndex: number) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.set('type', type + '');
		params = params.set('id', id + '');
		params = params.set('version', version + '');
		params = params.set('fileIndex', fileIndex + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/download`;

		return this.http.get(requestUrl, { responseType: 'blob', headers, params, observe: 'events', reportProgress: true });
	}

	pageDownloadDocCollection(item: any, pageId: number, widgetId: string) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.set('pageId', pageId + '');
		params = params.set('widgetId', widgetId);

		const requestUrl = `${environment.serviceUrl}/dashboard/page-download-doc-collection`;

		return this.http.post(requestUrl, JSON.stringify(item), { responseType: 'blob', headers, params, observe: 'response' });
	}

	downloadDocCollection(item: any) {
		const headers = new HttpHeaders();

		let params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/mfiles/download-doc-collection`;

		return this.http.post(requestUrl, JSON.stringify(item), { responseType: 'blob', headers, params, observe: 'response' });
	}

	pageDownloadMultiple(objs: any[], pageId: number, widgetId: string) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.set('pageId', pageId + '');
		params = params.set('widgetId', widgetId);

		const requestUrl = `${environment.serviceUrl}/dashboard/page-download-multiple`;

		return this.http.post(requestUrl, JSON.stringify(objs), { responseType: 'blob', headers, params, observe: 'response' });
	}

	pageViewDownload(zipName: string, path: string, pageId: number, widgetId: string) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.set('zipName', zipName);
		params = params.set('path', path);
		params = params.set('pageId', pageId + '');
		params = params.set('widgetId', widgetId);

		const requestUrl = `${environment.serviceUrl}/dashboard/page-view-download`;

		return this.http.get(requestUrl, { responseType: 'blob', headers, params, observe: 'response' });
	}

	pageViewDownloadMultiple(path: string, pageId: number, widgetId: string) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.set('path', path);
		params = params.set('pageId', pageId + '');
		params = params.set('widgetId', widgetId);

		const requestUrl = `${environment.serviceUrl}/dashboard/page-view-download-multiple`;

		return this.http.post(requestUrl, '', { responseType: 'blob', headers, params, observe: 'response' });
	}

	/**
	 * Downloads multiple objects or documents as a zip file. Use pageDownloadMultiple for additional permission checks for external users.
	 * @param objs An array of objects with integer values for type, id, and version
	 * @returns A zip file containing the objects
	 */
	downloadMultiple(objs: any[]) {
		const headers = new HttpHeaders();

		let params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/mfiles/download-multiple`;

		return this.http.post(requestUrl, JSON.stringify(objs), { responseType: 'blob', headers, params, observe: 'response' });
	}

	signIn(email: string, password: string, code: string) {
		let headers = new HttpHeaders();
		headers = headers.set('LoginAuth', `Basic ${btoa(email + ':' + password)}`);

		let params = new HttpParams();
		params = params.set('code', code + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/auth`;

		return this.http.post<User>(requestUrl, '', { headers, params, observe: 'response' });
	}

	checkPermissions() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = `${environment.serviceUrl}/dashboard/checkPermissions`;

		return this.http.get<any>(requestUrl, { headers, observe: 'response' });
	}

	getUserInfo(userId: string) {
		if (this.userInfo[userId]) {
			return this.userInfo[userId];
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			let params = new HttpParams();
			params = params.append('userId', userId);

			const requestUrl = `${environment.serviceUrl}/dashboard/userInfo`;

			this.userInfo[userId] = this.http.get(requestUrl, { headers, params, observe: 'response' })
				.pipe(
					shareReplay(1),
					catchError(err => {
						delete this.userInfo[userId];
						return EMPTY;
					})
				);
			return this.userInfo[userId];
		}
	}

	getUserInfoByToken(resetToken: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('resetToken', resetToken);

		const requestUrl = `${environment.serviceUrl}/dashboard/userInfoByToken`;

		return this.http.get(requestUrl, { headers, params, observe: 'response' });
	}

	registerEmail(email: string, contextURL: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('email', email);
		params = params.append('contextURL', contextURL);

		const requestUrl = `${environment.serviceUrl}/dashboard/registerEmail`;

		return this.http.post<User>(requestUrl, '', { headers, params, observe: 'response' });
	}

	register(email: string, password: string, encUserId: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		headers = headers.set('RegisterAuth', `Basic ${btoa(email + ':' + password)}`);

		const jsonData = {
			'email': email,
			'encryptedId': encUserId
		};

		const requestUrl = `${environment.serviceUrl}/dashboard/register`;

		return this.http.post<User>(requestUrl, JSON.stringify(jsonData), { headers, observe: 'response' });
	}

	sendResetPassword(email: string, contextURL: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('email', email);
		params = params.append('contextURL', contextURL);

		const requestUrl = `${environment.serviceUrl}/dashboard/sendResetPassword`;

		return this.http.post<User>(requestUrl, '', { headers, params, observe: 'response' });
	}

	resetPassword(resetToken: string, email: string, password: string, userId: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');
		headers = headers.set('ResetAuth', `Basic ${btoa(email + ':' + password)}`);

		const jsonData = {
			'email': email,
			'resetToken': resetToken,
			'userId': userId
		};

		const requestUrl = `${environment.serviceUrl}/dashboard/resetPassword`;

		return this.http.post<User>(requestUrl, JSON.stringify(jsonData), { headers, observe: 'response' });
	}

	updateWorkflowState(state: any, type: number, id: number, comment: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('state', state + '');
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('comment', comment + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/workflowState`;

		return this.http.post(requestUrl, '', { headers, params });
	}

	updateSingleProperty(propertyDef: number, dataType: number, value: any, type: number, id: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('propertyDef', propertyDef + '');
		params = params.append('dataType', dataType + '');
		params = params.append('value', value + '');
		params = params.append('type', type + '');
		params = params.append('id', id + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/singleProperty`;

		return this.http.post(requestUrl, '', { headers, params });
	}

	/**
	 * Updates a single property on an object. The passed TypedValue will override the value currently on the object.
	 * So if adding to a multi-select property, make sure to include the current values in the TypedValue.
	 * @param property An object resembling { PropertyDef: int, TypedValue: TypedValue (object) }
	 * @param type int Type of the object
	 * @param id int ID of the object
	 * @returns void
	 */
	updateSinglePropertyAdmin(property: any, type: number, id: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('type', type + '');
		params = params.append('id', id + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/singlePropertyAdmin`;

		return this.http.post(requestUrl, JSON.stringify(property), { headers, params });
	}

	getSingleProperty(propertyDef: number, type: number, id: number, version: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('propertyId', propertyDef + '');
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('version', version + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/singleProperty`;

		return this.http.get(requestUrl, { headers, params });
	}

	/**
	 * @param objVers Array with JSON objects that include type, id, and array of properties. JSON parameters for properties are propertyDef, dataType, and value. Value would be lookup ID for multi-select properties.
	 * @returns 
	 */
	updatePropertiesOnMultipleObjs(objVers: any[]) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/mfiles/multiProperty`;

		return this.http.post(requestUrl, JSON.stringify(objVers), { headers, params, responseType: 'text' });
	}

	pageUpdateObject(type: number, id: number, props: Property[], pageId: number, widgetId: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId);

		const requestUrl = `${environment.serviceUrl}/dashboard/page-update`;

		return this.http.post(requestUrl, JSON.stringify(props), { headers: headers, params: params, observe: 'response' });
	}

	updateObject(type: number, id: number, props: Property[]) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('type', type + '');
		params = params.append('id', id + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/update`;

		return this.http.post(requestUrl, JSON.stringify(props), { headers: headers, params: params, observe: 'response' });
	}

	deleteObject(type: number, id: number) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('type', type + '');
		params = params.append('id', id + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/delete`;

		return this.http.delete(requestUrl, { headers, params, observe: 'response' });
	}

	pageDeleteObject(type: number, id: number, destroy: boolean, pageId: number, widgetId: string) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('destroy', destroy + '');
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId);

		const requestUrl = `${environment.serviceUrl}/dashboard/page-delete`;

		return this.http.delete(requestUrl, { headers, params, observe: 'response' });
	}

	getPreviewId(itemId: number, version: number, fileIndex: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('parentType', 'Document');
		params = params.append('parentId', itemId + '');
		params = params.append('id', itemId + '');
		params = params.append('version', version + '');
		params = params.append('fileIndex', fileIndex + '');

		const requestUrl = `${environment.serviceUrl}/prizm/getPreviewId`;

		return this.http.get(requestUrl, { headers, params, responseType: 'text' });
	}

	getHelloSignPreviewId(id: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('id', id);

		const requestUrl = `${environment.serviceUrl}/prizm/getPreviewId/helloSign`;

		return this.http.get(requestUrl, { headers, params });
	}

	helloSignReject(id: number, comment?: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('id', id);
		params = params.append('comment', comment);

		const requestUrl = `${environment.serviceUrl}/dashboard/helloSignReject`;

		return this.http.post(requestUrl, '', { headers, params });
	}

	helloSignStart(id: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('id', id);

		const requestUrl = `${environment.serviceUrl}/dashboard/helloSignStart`;

		return this.http.post(requestUrl, '', { headers, params, responseType: 'text' });
	}

	prizmRedaction(id: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('id', id);

		const requestUrl = `${environment.serviceUrl}/prizm/redact`;

		return this.http.post(requestUrl, '', { headers, params, responseType: 'text' });
	}

	getPageView(path: string, pageId: number, widgetId: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('path', path);
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId);

		const requestUrl = `${environment.serviceUrl}/dashboard/page-view`;

		return this.http.get(requestUrl, { headers, params });
	}

	getUserViewPath(basePath: string, hasAccessProperties: boolean, rProp?: number, rwProp?: number, rwdProp?: number, contextualViewProp?: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('basePath', basePath);
		params = params.append('hasAccessProperties', hasAccessProperties + '');
		if (hasAccessProperties) {
			params = params.append('rProp', rProp + '');
			params = params.append('rwProp', rwProp + '');
			params = params.append('rwdProp', rwdProp + '');
		} else {
			if (contextualViewProp !== undefined) {
				params = params.append('contextualViewProp', contextualViewProp + '');
			}
		}

		const requestUrl = `${environment.serviceUrl}/dashboard/userViewPath`;

		return this.http.get(requestUrl, { headers, params, responseType: 'text' });
	}

	getComments(id: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('id', id + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/comments`;

		return this.http.get<CommentObject[]>(requestUrl, { headers, params });
	}

	postComment(id: number, parentComment: number, comment: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('id', id + '');
		params = params.append('comment', comment);

		if (parentComment && parentComment != null) {
			params = params.append('parentComment', parentComment + '');
		}
		const requestUrl = `${environment.serviceUrl}/dashboard/comment`;

		return this.http.post<CommentObject[]>(requestUrl, '', { headers, params });
	}

	getPageRelated(pageId: number, widgetId: string, type: number, id: number, version: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId);
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('version', version + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/page-related`;

		return this.http.get(requestUrl, { headers, params });
	}

	finalize(type: number, id: number, version: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('version', version + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/finalize`;

		return this.http.post(requestUrl, '', { headers, params, observe: 'response' });
	}

	unfinalize(type: number, id: number, version: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('type', type + '');
		params = params.append('id', id + '');
		params = params.append('version', version + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/unfinalize`;

		return this.http.post(requestUrl, '', { headers, params, observe: 'response' });
	}

	updateCompleteCriteria(criteria: string, type: number, id: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('type', type + '');
		params = params.append('id', id + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/completedCriteria`;

		return this.http.post(requestUrl, criteria, { headers, params, observe: 'response' });
	}

	updateViewCompleteCriteria(criteria: string, path: string, name: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('path', path + '');
		params = params.append('name', name + '');

		const requestUrl = `${environment.serviceUrl}/mfiles/viewCompletedCriteria`;

		return this.http.post(requestUrl, criteria, { headers, params, observe: 'response' });
	}

	pageUploadRevision(id: number, fileIndex: number, file: File, pageId: number, widgetId: string) {
		let params = new HttpParams();
		params = params.append('id', id + '');
		params = params.append('fileIndex', fileIndex + '');
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId);

		const formData = new FormData();
		formData.append('file', file);

		return this.http.post(`${environment.serviceUrl}/dashboard/page-checkin/revision`, formData, { params });
	}

	preparePageUploadRevision(id: number, fileIndex: number, pageId: number, widgetId: string) {
		let params = new HttpParams();
		params = params.append('id', id + '');
		params = params.append('fileIndex', fileIndex + '');
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId);

		return this.http.post(`${environment.serviceUrl}/dashboard/page-prepare-checkin/revision`, '', { params, responseType: 'text' });
	}

	uploadRevision(id: number, fileIndex: number, file: File) {
		let params = new HttpParams();
		params = params.append('id', id + '');
		params = params.append('fileIndex', fileIndex + '');

		const formData = new FormData();
		formData.append('file', file);

		return this.http.post(`${environment.serviceUrl}/mfiles/checkin/revision`, formData, { params });
	}

	prepareUploadRevision(id: number, fileIndex: number) {
		let params = new HttpParams();
		params = params.append('id', id + '');
		params = params.append('fileIndex', fileIndex + '');

		return this.http.post(`${environment.serviceUrl}/mfiles/prepare-checkin/revision`, '', { params, responseType: 'text' });
	}

	getClasses(type: number) {
		if (this.classesByObjectType[type]) {
			return this.classesByObjectType[type];
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			let params = new HttpParams();
			params = params.append('type', type + '');

			this.classesByObjectType[type] = this.http.get<any[]>(`${environment.serviceUrl}/mfiles/classes`, { headers, params })
				.pipe(
					shareReplay(1),
					catchError(err => {
						delete this.classesByObjectType[type];
						return EMPTY;
					})
				);
			return this.classesByObjectType[type];
		}
	}

	getClass(classId: number) {
		if (this.classes[classId]) {
			return this.classes[classId];
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			let params = new HttpParams();
			params = params.append('classId', classId + '');

			this.classes[classId] = this.http.get<any[]>(`${environment.serviceUrl}/mfiles/class`, { headers, params })
				.pipe(
					shareReplay(1),
					catchError(err => {
						delete this.classes[classId];
						return EMPTY;
					})
				);
			return this.classes[classId];
		}
	}

	getClassProperties(classId: number) {
		if (this.propertiesByClass[classId]) {
			return this.propertiesByClass[classId];
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			let params = new HttpParams();
			params = params.append('classId', classId + '');

			const requestUrl = `${environment.serviceUrl}/mfiles/classProperties`;

			this.propertiesByClass[classId] = this.http.get<Property[]>(requestUrl, { headers, params })
				.pipe(
					shareReplay(1),
					catchError(err => {
						delete this.propertiesByClass[classId];
						return EMPTY;
					})
				);
			return this.propertiesByClass[classId];
		}
	}

	pageCheckinNew(properties: Property[], file: File, pageId: number, widgetId: string, viewPath?: string, overrideClassUsingClassGroupingLevel?: boolean) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('properties', JSON.stringify(properties));

		let params = new HttpParams();
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId);

		if (viewPath && viewPath !== '') {
			params = params.append('viewPath', viewPath);
		}

		if (overrideClassUsingClassGroupingLevel) {
			params = params.append('overrideClassUsingClassGroupingLevel', overrideClassUsingClassGroupingLevel + '');
		}

		return this.http.post(`${environment.serviceUrl}/dashboard/page-checkin`, formData, { params });
	}

	preparePageCheckinNew(properties: Property[], pageId: number, widgetId: string, viewPath?: string, overrideClassUsingClassGroupingLevel?: boolean) {
		let params = new HttpParams();
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId);

		if (viewPath && viewPath !== '') {
			params = params.append('viewPath', viewPath);
		}

		if (overrideClassUsingClassGroupingLevel) {
			params = params.append('overrideClassUsingClassGroupingLevel', overrideClassUsingClassGroupingLevel + '');
		}

		return this.http.post(`${environment.serviceUrl}/dashboard/page-prepare-checkin`, JSON.stringify(properties), { params, responseType: 'text' });
	}

	pageCheckinNewMulti(files: File[], pageId: number, widgetId: string, parentObjType: number, parentObjId: number) {
		const formData = new FormData();
		for (const file of files) {
			formData.append('files', file);
		}

		let params = new HttpParams();
		params = params.append('parentObjType', parentObjType + '');
		params = params.append('parentObjId', parentObjId + '');
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId);

		return this.http.post(`${environment.serviceUrl}/dashboard/page-checkin-multi`, formData, { params });
	}

	// preparePageCheckinNewMulti(pageId: number, widgetId: string, parentObjType: number, parentObjId: number) {
	// 	let params = new HttpParams();
	// 	params = params.append('parentObjType', parentObjType + '');
	// 	params = params.append('parentObjId', parentObjId + '');
	// 	params = params.append('pageId', pageId + '');
	// 	params = params.append('widgetId', widgetId);

	// 	return this.http.post(`${environment.serviceUrl}/dashboard/page-prepare-checkin-multi`, '', { params, responseType: 'text' });
	// }

	pageCheckinNewObject(properties: Property[], type: number, pageId: number, widgetId: string) {
		const formData = new FormData();
		formData.append('properties', JSON.stringify(properties));

		let params = new HttpParams();
		params = params.append('type', type + '');
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId);

		return this.http.post(`${environment.serviceUrl}/dashboard/page-checkinObject`, formData, { params });
	}

	prepareFormCheckinNew() {
		let params = new HttpParams();

		return this.http.post(`${environment.serviceUrl}/dashboard/prepare-form-checkin`, null, { params, responseType: 'text' });
	}

	postFormCheckinNewMultiple(type: number, id: number) {
		let params = new HttpParams();
		params = params.append('formObjType', type + '');
		params = params.append('formObjId', id + '');

		return this.http.post<MFilesItem>(`${environment.serviceUrl}/dashboard/post-form-checkin-multi`, null, { params });
	}

	prepareFormDraftCheckinNew(fileDraftClass: number, filename: string) {
		let params = new HttpParams();
		params = params.append('fileDraftClass', fileDraftClass + '');
		params = params.append('filename', filename);

		return this.http.post(`${environment.serviceUrl}/form-draft/prepare-checkin`, null, { params, responseType: 'text' });
	}

	postFormDraftCheckinNew(parentId: number, draftDocs: any[]) {
		let params = new HttpParams();

		const formData = new FormData();
		formData.append('parentId', parentId + '');
		formData.append('draftDocs', JSON.stringify(draftDocs));

		return this.http.post<MFilesItem[]>(`${environment.serviceUrl}/form-draft/post-checkin`, formData, { params });
	}

	getFormSubmissions(objTypeId: number, classId: number, userObj: string, filterByUser: boolean, matchProperty?: number, formParentObj?: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('objTypeId', objTypeId + '');
		params = params.append('classId', classId + '');

		const data = {
			user: userObj,
			filterByUser: filterByUser
		};

		if (!filterByUser && matchProperty) {
			data['matchProperty'] = matchProperty;
		}

		if (formParentObj) {
			data['formParentObj'] = formParentObj;
		}

		const requestUrl = `${environment.serviceUrl}/dashboard/form-submissions`;

		return this.http.post<any[]>(requestUrl, data, { headers, params, observe: 'response' });
	}

	getFormDraft(parentObjId: number) {
		let headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('parentId', parentObjId + '');

		const requestUrl = `${environment.serviceUrl}/form-draft`;

		return this.http.get(requestUrl, { headers, params, observe: 'response' });
	}

	saveFormDraft(parentObjId: number, data: string) {
		let headers = new HttpHeaders();
		let params = new HttpParams();

		const formData = new FormData();
		formData.append('parentId', parentObjId + '');
		formData.append('formData', data);

		const requestUrl = `${environment.serviceUrl}/form-draft`;

		return this.http.post<any>(requestUrl, formData, { headers, params, observe: 'response' });
	}

	saveFormDraftDocs(parentObjId: number, fileDraftClass: number, files: File[]) {
		let headers = new HttpHeaders();
		let params = new HttpParams();

		const formData = new FormData();
		formData.append('parentId', parentObjId + '');
		formData.append('fileDraftClass', fileDraftClass + '');
		if (files && files.length > 0) {
			for (const file of files) {
				formData.append('files', file);
			}
		}

		const requestUrl = `${environment.serviceUrl}/form-draft/docs`

		return this.http.post<any>(requestUrl, formData, { headers, params, observe: 'response' });
	}

	releaseFormDraft(parentObjId: number) {
		let headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('parentId', parentObjId + '');

		const requestUrl = `${environment.serviceUrl}/form-draft/release`;

		return this.http.put(requestUrl, null, { headers, params, observe: 'response' });
	}

	deleteFormDraft(parentObjId: number) {
		let headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('parentId', parentObjId + '');

		const requestUrl = `${environment.serviceUrl}/form-draft`;

		return this.http.delete(requestUrl, { headers, params, observe: 'response' });
	}

	deleteFormDraftDoc(parentObjId: number, mfilesId: number) {
		let headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('parentId', parentObjId + '');
		params = params.append('mfilesId', mfilesId + '');

		const requestUrl = `${environment.serviceUrl}/form-draft/doc`;

		return this.http.delete(requestUrl, { headers, params, observe: 'response' });
	}

	getPieChart(pageId: number, widgetId: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/page-pieChart`;

		return this.http.get<any[]>(requestUrl, { headers, params, observe: 'response' });
	}

	getBarChart(pageId: number, widgetId: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('pageId', pageId + '');
		params = params.append('widgetId', widgetId + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/page-barChart`;

		return this.http.get<any[]>(requestUrl, { headers, params, observe: 'response' });
	}

	getViewCompletedProperties(view: any) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/mfiles/viewCompletedProperties`;

		return this.http.post<any>(requestUrl, JSON.stringify(view), { headers, params, observe: 'response' });
	}

	getCompletedProperties(item: any) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/mfiles/completedProperties`;

		return this.http.post<any>(requestUrl, JSON.stringify(item), { headers, params, observe: 'response' });
	}

	getCalendarEvents(objectId: number, startProperty: number, endProperty: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('objectId', objectId + '');
		params = params.append('startProperty', startProperty + '');
		params = params.append('endProperty', endProperty + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/calendar-events`;

		return this.http.get(requestUrl, { headers, params, observe: 'response' });
	}

	getNewsfeed(filters: SearchFilter[], objectTypeId: number, titlePropertyId: number, subtitlePropertyId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('objectTypeId', objectTypeId + '');
		params = params.append('titlePropertyId', titlePropertyId + '');
		params = params.append('subtitlePropertyId', subtitlePropertyId + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/newsfeed-items`;

		return this.http.post<any[]>(requestUrl, JSON.stringify(filters), { headers, params, observe: 'response' });
	}

	getTaskList(pageId: number, widgetId: string) {
		if (this.tasklistsContains(pageId, widgetId)) {
			return this.getCachedTasklist(pageId, widgetId);
		} else {
			let headers = new HttpHeaders();
			headers = headers.set('Content-Type', 'application/json');

			let params = new HttpParams();
			params = params.set('pageId', pageId + '');
			params = params.set('widgetId', widgetId);

			const requestUrl = `${environment.serviceUrl}/dashboard/task-list`;

			const response = this.http.get<any[]>(requestUrl, {
				headers,
				params,
				observe: 'response'
			}).pipe(
				shareReplay(1),
				catchError(err => {
					this.removeTasklistFromCachedTasklists(pageId, widgetId);
					return EMPTY;
				})
			);
			this.addResponseToTasklists(response, pageId, widgetId);
			return response;
		}
	}

	deleteTaskListDocument(pageId: number, widgetId: string, taskId: number, fileId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.set('pageId', pageId + '');
		params = params.set('widgetId', widgetId);
		params = params.append('taskId', taskId + '');
		params = params.append('fileId', fileId + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/task-list/document`;

		return this.http.delete<any[]>(requestUrl, { headers, params, observe: 'response' });
	}

	getExternalUser() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const requestUrl = `${environment.serviceUrl}/dashboard/externalUser`;

		return this.http.get(requestUrl, { headers, observe: 'response' });
	}


	// ---------------------------
	//          Caching
	// ---------------------------

	/* Tasklist - Helper Methods */
	tasklistsContains(pageId: number, widgetId: string): boolean {
		return this.tasklists[pageId] && this.tasklists[pageId][widgetId];
	}

	getCachedTasklist(pageId: number, widgetId: string) {
		return this.tasklists[pageId][widgetId];
	}

	removeTasklistFromCachedTasklists(pageId: number, widgetId: string) {
		delete this.tasklists[pageId][widgetId];
	}

	addResponseToTasklists(response: Observable<HttpResponse<any[]>>, pageId: number, widgetId: string) {
		if (this.tasklists[pageId]) {
			this.tasklists[pageId][widgetId] = response;
		} else {
			this.tasklists[pageId] = {};
			this.tasklists[pageId][widgetId] = response;
		}
	}

	updateTasklist(pageId: number, widgetId: string) {
		this.removeTasklistFromCachedTasklists(pageId, widgetId);
		this.getTaskList(pageId, widgetId);
	}

	private containsAllClassAliases(aliases: string[]) {
		if (Object.keys(this.classesByAlias).length > 0) {
			for (let i = 0; i < aliases.length; i++) {
				const alias = aliases[i];
				if (!this.classesByAlias[alias]) {
					return false;
				}
			}

			return true;
		} else {
			return false;
		}
	}

	private containsAllWorkflowAliases(aliases: string[]) {
		if (Object.keys(this.workflowsByAlias).length > 0) {
			for (let i = 0; i < aliases.length; i++) {
				const alias = aliases[i];
				if (!this.workflowsByAlias[alias]) {
					return false;
				}
			}

			return true;
		} else {
			return false;
		}
	}

	private containsAllStateAliases(aliases: string[]) {
		if (Object.keys(this.statesByAlias).length > 0) {
			for (let i = 0; i < aliases.length; i++) {
				const alias = aliases[i];
				if (!this.statesByAlias[alias]) {
					return false;
				}
			}

			return true;
		} else {
			return false;
		}
	}

	private containsAllPropertyDefAliases(aliases: string[]) {
		if (Object.keys(this.propertyDefsByAlias).length > 0) {
			for (let i = 0; i < aliases.length; i++) {
				const alias = aliases[i];
				if (!this.propertyDefsByAlias[alias]) {
					return false;
				}
			}

			return true;
		} else {
			return false;
		}
	}

	/* Item Properties - Helper Methods */
	itemsPropertiesContains(type: number, id: number, version: number): boolean {
		return this.itemsProperties[type] && this.itemsProperties[type][id] && this.itemsProperties[type][id][version];
	}

	getCachedItemsProperties(type: number, id: number, version: number) {
		return this.itemsProperties[type][id][version];
	}

	removeItemsProperties(type: number, id: number, version: number) {
		delete this.itemsProperties[type][id][version];
	}

	addResponseToItemsProperties(response: Observable<HttpResponse<Object>>, type: number, id: number, version: number) {
		if (this.itemsProperties[type]) {
			if (this.itemsProperties[type][id]) {
				this.itemsProperties[type][id][version] = response;
			} else {
				this.itemsProperties[type][id] = {};
				this.itemsProperties[type][id][version] = response;
			}
		} else {
			this.itemsProperties[type] = {};
			this.itemsProperties[type][id] = {};
			this.itemsProperties[type][id][version] = response;
		}
	}

	itemFilesContains(type: number, id: number, version: number): boolean {
		return this.itemFiles[type] && this.itemFiles[type][id] && this.itemFiles[type][id][version];
	}

	getCachedItemFiles(type: number, id: number, version: number) {
		return this.itemFiles[type][id][version];
	}

	removeItemFiles(type: number, id: number, version: number) {
		delete this.itemFiles[type][id][version];
	}

	addResponseToItemFiles(response: Observable<HttpResponse<Object>>, type: number, id: number, version: number) {
		if (this.itemFiles[type]) {
			if (this.itemFiles[type][id]) {
				this.itemFiles[type][id][version] = response;
			} else {
				this.itemFiles[type][id] = {};
				this.itemFiles[type][id][version] = response;
			}
		} else {
			this.itemFiles[type] = {};
			this.itemFiles[type][id] = {};
			this.itemFiles[type][id][version] = response;
		}
	}

	startCoauthoring(id: number) {
		let workspace = this.authService.getToken('workspace');
		if (!workspace) {
			workspace = '1';
		}
		window.open(`${environment.serviceUrl}/o365/start-session?id=` + id + '&workspace=' + workspace);
	}

	continueCoauthoring(id: number) {
		let workspace = this.authService.getToken('workspace');
		if (!workspace) {
			workspace = '1';
		}
		window.open(`${environment.serviceUrl}/o365/continue-session?id=` + id + '&workspace=' + workspace);
	}

	undoCoauthoring(id: number) {
		let workspace = this.authService.getToken('workspace');
		if (!workspace) {
			workspace = '1';
		}

		return this.http.get<any>(`${environment.serviceUrl}/o365/undo-session?id=` + id + '&workspace=' + workspace);
	}

	undoCoauthoringLink(id: number) {
		let workspace = this.authService.getToken('workspace');
		if (!workspace) {
			workspace = '1';
		}

		window.open(`${environment.serviceUrl}/o365/undo-session?id=` + id + '&workspace=' + workspace);
	}

	endCoauthoring(id: number) {
		let workspace = this.authService.getToken('workspace');
		if (!workspace) {
			workspace = '1';
		}

		return this.http.get<any>(`${environment.serviceUrl}/o365/end-session?id=` + id + '&workspace=' + workspace);
	}

	endCoauthoringLink(id: number) {
		let workspace = this.authService.getToken('workspace');
		if (!workspace) {
			workspace = '1';
		}

		window.open(`${environment.serviceUrl}/o365/end-session?id=` + id + '&workspace=' + workspace);
	}

	taskSignatureRequest(id: number, pageId: number, widgetId: string) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.set('id', id + '');
		params = params.set('pageId', pageId + '');
		params = params.set('widgetId', widgetId);

		const requestUrl = `${environment.serviceUrl}/dashboard/taskSignatureRequest`;

		return this.http.post(requestUrl, '', { headers, params });
	}

	exportWorkspace() {
		const headers = new HttpHeaders();

		const requestUrl = `${environment.serviceUrl}/workspace/export`;

		return this.http.get(requestUrl, { responseType: 'blob', headers, observe: 'response' });
	}

	exportWorkspaceSecurity() {
		const headers = new HttpHeaders();

		const requestUrl = `${environment.serviceUrl}/workspace/export/security`;

		return this.http.get(requestUrl, { responseType: 'blob', headers, observe: 'response' });
	}

	importWorkspace(file: File, overwriteWorkspace: boolean, importSecurity: boolean, importUserSettings: boolean) {
		let params = new HttpParams();
		params = params.append('overwriteWorkspace', overwriteWorkspace + '');
		params = params.append('importSecurity', importSecurity + '');
		params = params.append('importUserSettings', importUserSettings + '');

		const formData = new FormData();
		formData.append('file', file);

		return this.http.post(`${environment.serviceUrl}/workspace/import`, formData, { params });
	}

	importWorkspaceSecurity(file: File) {
		const formData = new FormData();
		formData.append('file', file);

		return this.http.post(`${environment.serviceUrl}/workspace/import/security`, formData);
	}

	// Tender Component Services
	getBidRequestInfo(id: number) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('id', id + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/bid-request-info`;

		return this.http.get(requestUrl, { params: params, headers: headers });
	}

	getBidDocs(bidRequest: MFilesItem, bid: MFilesItem) {
		const headers = new HttpHeaders();

		let params = new HttpParams();

		const formData = new FormData();
		formData.append('bidRequest', JSON.stringify(bidRequest));
		formData.append('bid', JSON.stringify(bid));

		const requestUrl = `${environment.serviceUrl}/dashboard/bid-docs`;

		return this.http.post(requestUrl, formData, { params: params, headers: headers });
	}

	getBidCommunications(bidRequest: MFilesItem, bid: MFilesItem) {
		const headers = new HttpHeaders();

		let params = new HttpParams();

		const formData = new FormData();
		formData.append('bidRequest', JSON.stringify(bidRequest));
		formData.append('bid', JSON.stringify(bid));

		const requestUrl = `${environment.serviceUrl}/dashboard/bid-communications`;

		return this.http.post(requestUrl, formData, { params: params, headers: headers });
	}

	getBidResponseDocs(bidRequest: MFilesItem, bid: MFilesItem) {
		const headers = new HttpHeaders();

		let params = new HttpParams();

		const formData = new FormData();
		formData.append('bidRequest', JSON.stringify(bidRequest));
		formData.append('bid', JSON.stringify(bid));

		const requestUrl = `${environment.serviceUrl}/dashboard/bid-response-docs`;

		return this.http.post(requestUrl, formData, { params: params, headers: headers });
	}

	getBidProject(bidRequest: MFilesItem, bid: MFilesItem) {
		const headers = new HttpHeaders();

		let params = new HttpParams();

		const formData = new FormData();
		formData.append('bidRequest', JSON.stringify(bidRequest));
		formData.append('bid', JSON.stringify(bid));

		const requestUrl = `${environment.serviceUrl}/dashboard/bid-project`;

		return this.http.post(requestUrl, formData, { params: params, headers: headers });
	}

	prepareBidResponseCheckin(bidRequestId: number, props: Property[]) {
		let params = new HttpParams();
		params = params.append('bidRequestId', bidRequestId + '');

		return this.http.post(`${environment.serviceUrl}/dashboard/prepare-bid-response-upload`, JSON.stringify(props), { params, responseType: 'text' });
	}

	/**
	 * Properties in the MFilesItem are filtered based on the content profiles/filters for the current user.
	 * @param items The IDs of the new objects in M-Files
	 * @returns The full MFilesItem with the passed ID.
	 */
	postBidResponsesCheckin(items: string[]) {
		let params = new HttpParams();

		return this.http.post<MFilesItem[]>(`${environment.serviceUrl}/dashboard/post-bid-response-upload`, JSON.stringify(items), { params });
	}

	uploadBidResponseDoc(properties: Property[], file: File, bidRequestId: number) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('properties', JSON.stringify(properties));

		let params = new HttpParams();
		params = params.append('bidRequestId', bidRequestId + '');

		return this.http.post(`${environment.serviceUrl}/dashboard/bid-response`, formData, { params });
	}

	prepareBidCommunicationAttachmentCheckin(bidRequestId: number, props: Property[]) {
		let params = new HttpParams();
		params = params.append('bidRequestId', bidRequestId + '');

		return this.http.post(`${environment.serviceUrl}/dashboard/prepare-bid-communication-attachment-upload`, JSON.stringify(props), { params, responseType: 'text' });
	}

	postBidCommunicationAttachmentCheckin(items: string[]) {
		let params = new HttpParams();

		return this.http.post<MFilesItem[]>(`${environment.serviceUrl}/dashboard/post-bid-communication-attachment-upload`, JSON.stringify(items), { params });
	}

	createBidCommunication(communicationProperties: Property[], bidRequestId: number) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('bidRequestId', bidRequestId + '');

		const requestUrl = `${environment.serviceUrl}/dashboard/bid-communication`;

		return this.http.post(requestUrl, JSON.stringify(communicationProperties), { params: params, headers: headers });
	}

	createBidCommunicationWithAttachments(communicationProperties: Property[], attachments: File[], attachmentsProperties: Property[][], bidRequestId: number) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('bidRequestId', bidRequestId + '');

		const formData = new FormData();
		formData.append('communicationProperties', JSON.stringify(communicationProperties));
		formData.append('attachmentsProperties', JSON.stringify(attachmentsProperties));
		for (const attachment of attachments) {
			formData.append('attachments', attachment);
		}

		const requestUrl = `${environment.serviceUrl}/dashboard/bid-communication/attachments`;

		return this.http.post(requestUrl, formData, { params: params, headers: headers });
	}

	getExternalShares() {
		const headers = new HttpHeaders();

		return this.http.get(`${environment.serviceUrl}/dashboard/externalShares`, { headers });
	}

	getExternalShareContents(shareId: any) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('shareId', shareId + '');

		return this.http.get<any>(`${environment.serviceUrl}/dashboard/externalShareContents`, { headers, params });
	}

	createExternalShare(shareInfo: any) {
		const headers = new HttpHeaders();

		return this.http.post(`${environment.serviceUrl}/dashboard/createExternalShare`, JSON.stringify(shareInfo), { headers });
	}

	updateExternalShare(shareInfo: any) {
		const headers = new HttpHeaders();

		return this.http.post(`${environment.serviceUrl}/dashboard/createExternalShare`, JSON.stringify(shareInfo), { headers });
	}

	deleteExternalShare(shareId: any) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('shareId', shareId + '');

		return this.http.delete(`${environment.serviceUrl}/dashboard/deleteExternalShare`, { headers, params });
	}

	shareDocSearch(searchTerm: string) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.append('searchTerm', searchTerm);

		return this.http.get(`${environment.serviceUrl}/dashboard/shareDocSearch`, { params, headers });
	}

	pageDelete(type: number, id: number, pageId: number, widgetId: string) {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.set('type', type + '');
		params = params.set('id', id + '');
		params = params.set('destroy', 'false');
		params = params.set('pageId', pageId + '');
		params = params.set('widgetId', widgetId);
		params = params.set('workspace', this.authService.getToken('workspace'));

		const requestUrl = `${environment.serviceUrl}/dashboard/page-deleteObject`;

		return this.http.delete(requestUrl, { headers, params });
	}

	deleteFormFiles(type: number, id: number, fileIds: string[]): Observable<any> {
		const headers = new HttpHeaders();

		let params = new HttpParams();
		params = params.set('type', type + '');
		params = params.set('id', id + '');
		params = params.set('fileIds', JSON.stringify(fileIds))
		params = params.set('workspace', this.authService.getToken('workspace'));

		const requestUrl = `${environment.serviceUrl}/mfiles/deleteFormFiles`;

		return this.http.delete(requestUrl, { headers, params });
	}

	getAnnotations(id: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('id', id);

		const requestUrl = `${environment.serviceUrl}/dashboard/annotations`;

		return this.http.get(requestUrl, { headers, params });
	}

	updateAnnotations(id: number, annotations: any) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('id', id);

		const requestUrl = `${environment.serviceUrl}/dashboard/annotations`;

		return this.http.post(requestUrl, JSON.stringify(annotations), { headers, params });
	}

	isMicroserviceAvailable() {
		let params = new HttpParams();

		return this.http.get(`${environment.uploadUrl}/available`, { params, responseType: 'text' }).pipe(
			catchError((err) => {
				return of(false);
			})
		);
	}

	async microserviceUpload(uploadKey: string, file: File, isRevision?: boolean, addToMultiFile?: boolean, parentObjType?: number, parentObjId?: number, setProgressTo100?: boolean) {
		this.microserviceUploadCanceled = false;
		this.cancelMicroserviceUpload.subscribe(() => {
			this.microserviceUploadCanceled = true;
			return;
		});

		const fileTitle = file.name.substring(0, file.name.lastIndexOf('.'));
		const fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
		const fileSize = file.size;
		const chunkSize = 1024 * 1024 * 4; // 4MB
		const totalChunks = Math.ceil(fileSize / chunkSize);
		let chunksUploaded = 0;
		let resumableChunkNumber = 1;
		let offset = 0;

		this.emitUploadProgress(file.name, 0);

		let params = new HttpParams();
		params = params.append('uploadKey', uploadKey);
		params = params.append('fileSize', fileSize);
		params = params.append('fileTitle', fileTitle);
		params = params.append('fileExtension', fileExtension);

		let uploadResponse: any;
		let uploadId: number;
		try {
			uploadResponse = await lastValueFrom(this.http.post(`${environment.uploadUrl}/session`, null, { params, responseType: 'text' }));
			uploadResponse = JSON.parse(uploadResponse);
			uploadId = uploadResponse.uploadId;
		} catch (err) {
			err.file = file;
			throw err;
		}

		if (this.microserviceUploadCanceled) return;

		this.emitUploadProgress(file.name, 1);

		while (offset < fileSize) {
			let blob = file.slice(offset, offset + chunkSize);

			const formData = new FormData();
			formData.append('file', blob);

			let params = new HttpParams();
			params = params.append('uploadKey', uploadKey);
			params = params.append('uploadId', uploadId);
			params = params.append('fileSize', file.size);
			params = params.append('resumableChunkNumber', resumableChunkNumber);
			params = params.append('resumableChunkSize', chunkSize);
			params = params.append('resumableCurrentChunkSize', blob.size);
			params = params.append('resumableTotalChunks', totalChunks);

			try {
				await lastValueFrom(this.http.post(`${environment.uploadUrl}/block`, formData, { params, responseType: 'text' }));
			} catch (err) {
				err.file = file;
				throw err;
			}

			if (this.microserviceUploadCanceled) return;

			offset += blob.size;
			resumableChunkNumber += 1;
			chunksUploaded += 1;

			let progress = Math.ceil(((chunksUploaded / totalChunks) * 100) - 1)   // subtracting 1 here to leave the progress at 99% even when all blocks have been uploaded. The progress will be moved to 100% when everything has been fully committed to M-Files
			if (progress > 99) {
				progress = 99;
			} else if (progress < 1) {
				progress = 1;
			}
			this.emitUploadProgress(file.name, progress);
		}

		params = new HttpParams();
		params = params.append('uploadKey', uploadKey);
		params = params.append('uploadId', uploadId);
		params = params.append('fileSize', fileSize);
		params = params.append('fileTitle', fileTitle);
		params = params.append('fileExtension', fileExtension);

		let response: any;
		try {
			if (isRevision) {
				response = await lastValueFrom(this.http.post(`${environment.uploadUrl}/revise`, null, { params, responseType: 'text' }))
			} else if (addToMultiFile) {
				if (parentObjType !== null && parentObjType !== undefined && parentObjId !== null && parentObjId !== undefined) {
					params = params.append('type', parentObjType + '');
					params = params.append('id', parentObjId + '');
				}
				response = await lastValueFrom(this.http.post(`${environment.uploadUrl}/concat`, null, { params, responseType: 'text' }));
			} else {
				response = await lastValueFrom(this.http.post(`${environment.uploadUrl}/create`, null, { params, responseType: 'text' }));
			}

			if (setProgressTo100) {
				this.emitUploadProgress(file.name, 100);
			}
		} catch (err) {
			err.file = file;
			throw err;
		}
		return of(response);
	}

	/**
	 * Updates the progress for all files currently being uploaded to the passed progress number. If a file upload failed and has an ERROR status, the progress won't be updated.
	 */
	emitUploadProgress(fileName: string, progress: number) {
		if (fileName === 'all') {
			for (let key in this.microserviceUploadProgress) {
				if (!isNaN(this.microserviceUploadProgress[key])) {
					this.microserviceUploadProgress[key] = progress;
				}
			}
		} else {
			this.microserviceUploadProgress[fileName] = progress;
		}
		this.uploadProgressUpdated.next(this.microserviceUploadProgress);
	}

	init() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/dashboard/init`;

		return this.http.get(requestUrl, { headers, params });
	}

	getAlertTriggers() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/alert/alert-triggers`;

		return this.http.get(requestUrl, { headers, params });
	}

	getAlertFrequencies() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/alert/alert-frequencies`;

		return this.http.get(requestUrl, { headers, params });
	}

	getAlertDays() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/alert/alert-days`;

		return this.http.get(requestUrl, { headers, params });
	}

	createAlertConfig(alertConfig: any, item: any, url: string) {
		let headers = new HttpHeaders();
		let params = new HttpParams();

		const formData = new FormData();
		formData.append('alertConfigStr', JSON.stringify(alertConfig));
		formData.append('itemStr', JSON.stringify(item));
		formData.append('url', url);

		const requestUrl = `${environment.serviceUrl}/alert/alert-config`;

		return this.http.post(requestUrl, formData, { headers, params });
	}

	getAlertConfig(viewPath: string) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('viewPath', viewPath);

		const requestUrl = `${environment.serviceUrl}/alert/alert-config`;

		return this.http.get(requestUrl, { headers, params });
	}

	updateAlertConfig(alertConfig: any, item: any) {
		let headers = new HttpHeaders();
		let params = new HttpParams();

		const formData = new FormData();
		formData.append('alertConfigStr', JSON.stringify(alertConfig));
		formData.append('itemStr', JSON.stringify(item));

		const requestUrl = `${environment.serviceUrl}/alert/alert-config`;

		return this.http.put(requestUrl, formData, { headers, params });
	}

	deleteAlertConfig(alertConfigMfilesId: number) {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();
		params = params.append('mfilesId', alertConfigMfilesId + '');

		const requestUrl = `${environment.serviceUrl}/alert/alert-config`;

		return this.http.delete(requestUrl, { headers, params });
	}

	getExternalRoles() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		const params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/dashboard/external-roles`;

		return this.http.get(requestUrl, { headers, params });
	}

	validateSecretKey() {
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json');

		let params = new HttpParams();

		const requestUrl = `${environment.serviceUrl}/dashboard/validate-key`;

		return this.http.get(requestUrl, { headers, params });
	}
}
