import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FileComponent } from './file/file.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { MessagesComponent } from './messages/messages.component';
import { RecentItemsComponent } from './recent/recent.component';
import { ShareComponent } from './share/share.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { RecentGuard } from './guards/recent.guard';
import { FavoriteGuard } from './guards/favorite.guard';
import { UserSettingsComponent } from './user/user-settings.component';
import { ExternalShareComponent } from './external-share/external-share.component';
import { AzureComponent } from './landing/azure/azure.component';
import { BidRequestComponent } from './bid-request/bid-request.component';
import { SharesComponent } from './shares/shares.component';
import { SharesGuard } from './guards/shares.guard';
import { DocumentReviewComponent } from './document-review/document-review.component';

const appRoutes: Routes = [
	{ path: '', redirectTo: '/page/welcome', pathMatch: 'full' },
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
	},
	{
		path: 'page',
		loadChildren: () => import('./page/page.module').then(m => m.PageModule),
		runGuardsAndResolvers: 'always'
	},
	{ path: 'favorites', component: FavoritesComponent, canActivate: [AuthGuard, FavoriteGuard] },
	{ path: 'shares', component: SharesComponent, canActivate: [AuthGuard, SharesGuard] },
	{ path: 'file/:file/:fileIndex', component: FileComponent },
	{ path: 'file/:file/:fileIndex/:viewerType', component: FileComponent },
	{ path: 'messages', component: MessagesComponent, canActivate: [AuthGuard] },
	{
		path: 'admin',
		canActivate: [AuthGuard, AdminGuard],
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	},
	{ path: 'recent', component: RecentItemsComponent, canActivate: [AuthGuard, RecentGuard] },
	{ path: 'share/:token', component: ShareComponent },
	{ path: 'share/:token/:viewerType', component: ShareComponent },
	{ path: 'external', component: ExternalShareComponent },
	{ path: 'user', component: UserSettingsComponent, canActivate: [AuthGuard] },
	{ path: 'landing/azure', component: AzureComponent },
	{ path: 'bidrequest/:id', component: BidRequestComponent, canActivate: [AuthGuard] },
	{ path: 'opportunity/:id', component: BidRequestComponent, canActivate: [AuthGuard] },
	{ path: 'review/:id', component: DocumentReviewComponent, canActivate: [AuthGuard] },
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule]
})
export class AppRoutingModule {

}
