import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertConfig } from 'src/app/models/alert-config.model';
import { MFilesService } from 'src/app/services/mfiles.service';

@Component({
	selector: '[view-folder-row]',
	templateUrl: './view-folder-row.component.html'
})
export class ViewFolderRowComponent implements OnInit, OnDestroy {
	modalRef: BsModalRef;
	@ViewChild('alertConfigDialog') private alertConfigDialog: TemplateRef<any>;

	loading: boolean = false;

	favorites: any[] = [];

	@Input() item: any;
	@Input() inGrid = false;
	@Input() completedEnabled: boolean;
	@Input() alertsEnabled: boolean;
	@Input() alertTriggers: any[];
	@Input() alertFrequencies: any[];
	@Input() alertDays: any[];

	@Input() vaultProperties: any[];
	@Input() objectTypes: any;
	@Input() objectLists: any;

	@Output() openView = new EventEmitter<string>();

	completeCriteria = [];

	scrollListener: Function;

	alertConfig: AlertConfig = new AlertConfig();
	backupAlertConfig: AlertConfig = new AlertConfig();
	alertConfigExists: boolean = false;

	constructor(
		private modalService: BsModalService,
		private mfilesService: MFilesService,
		private myElement: ElementRef,
		private renderer: Renderer2
	) { }

	ngOnInit() {
		if (this.completedEnabled) {
			if (this.isElementInViewport(this.myElement.nativeElement)) {
				this.loadCompleted();
			} else {
				if (this.inGrid) {
					this.scrollListener = this.renderer.listen(this.myElement.nativeElement.closest('gridster-item'), 'scroll', (event) => {
						if (this.isElementInViewport(this.myElement.nativeElement)) {
							this.loadCompleted();
							this.scrollListener(); // destroys the scroll listener
						}
					});
				} else {
					this.scrollListener = this.renderer.listen(window, 'scroll', (event) => {
						if (this.isElementInViewport(this.myElement.nativeElement)) {
							this.loadCompleted();
							this.scrollListener(); // destroys the scroll listener
						}
					});
				}
			}
		}

		if (this.alertsEnabled) {
			const viewPath: string = this.item.path;
			this.getAlertConfig(viewPath);

			const defaultFrequency = this.alertFrequencies.find(f => f.name === 'Immediately');
			if (defaultFrequency) {
				this.alertConfig.alertFrequency = { name: defaultFrequency.name, id: defaultFrequency.id };
			}
		}
	}

	ngOnDestroy() {
		if (this.scrollListener) {
			this.scrollListener();
		}
	}

	loadCompleted() {
		this.item.loaded = false;
		this.mfilesService.getViewCompletedProperties(this.item).subscribe((response) => {
			this.item = response.body;

			this.completeCriteria = JSON.parse(this.item.completeCriteria);

			for (const criterion of this.completeCriteria) {
				this.checkCriteriaField(criterion.id);
				const dataType = this.objectTypes[criterion.id];
				if (dataType === 5 || dataType === 6 || dataType === 7) {
					criterion.value = new Date(criterion.value);
				}
			}
			this.checkCriteriaField(-1);

			this.item.loaded = true;
			this.item.error = false;
		}, (err: any) => {
			this.item.loaded = true;
			this.item.error = true;
		});
	}

	openCompleteModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static' });

		for (const criterion of this.completeCriteria) {
			this.checkCriteriaField(criterion.id);
			const dataType = this.objectTypes[criterion.id];
			if (dataType === 5 || dataType === 6 || dataType === 7) {
				criterion.value = new Date(criterion.value);
			}
		}
	}

	setCriteria() {
		this.modalRef.hide();
		for (const criteria of this.completeCriteria) {
			const dataType = this.objectTypes[criteria.id];
			if (dataType === 5 || dataType === 6 || dataType === 7) {
				criteria.value = criteria.value.getTime();
			}
		}

		this.item.loaded = false;

		this.mfilesService.updateViewCompleteCriteria(JSON.stringify(this.completeCriteria), this.item.path, this.item.name).subscribe((response) => {
			const result: any = response.body;
			this.item = result;

			this.item.loaded = true;
			this.item.error = false;
		}, (err: any) => {
			this.item.loaded = true;
			this.item.error = true;
		});
	}

	reloadCompleted() {
		this.item.loaded = false;
		this.mfilesService.getViewCompletedProperties(this.item).subscribe((response) => {
			this.item = response.body;
			this.item.loaded = true;
			this.item.error = false;
		}, (err: any) => {
			this.item.loaded = true;
			this.item.error = true;
		});
	}

	addCriteria() {
		this.completeCriteria.push({ id: -1, comparison: 'eq', value: '' });
	}

	removeCriteria(i: number) {
		this.completeCriteria.splice(i, 1);
	}

	criteriaFieldChanged(e: any, i: number) {
		const fieldId = e.id;
		this.checkCriteriaField(fieldId);

		this.completeCriteria[i].value = '';
	}

	checkCriteriaField(fieldId: number) {
		if (this.vaultProperties && !this.objectTypes[fieldId]) {
			for (const property of this.vaultProperties) {
				if (property.id === fieldId) {
					if (!this.objectTypes[fieldId]) {
						this.objectTypes[fieldId] = property.dataType;
					}
					if (property.dataType === 9 || property.dataType === 10) {
						this.mfilesService.getValueListOptions(property.valueList).subscribe((result) => {
							this.objectLists[fieldId] = result.body['Items'];
						});
					}
					break;
				}
			}
		}
	}

	isElementInViewport(el: HTMLElement) {
		const rect = el.getBoundingClientRect();

		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom - 100 <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	loadPath(path: string) {
		this.openView.emit(path);
	}

	/**
	 * ALERTS
	 */

	openAlertConfigModal() {
		this.modalRef = this.modalService.show(this.alertConfigDialog, { class: 'modal-md', backdrop: 'static' });
	}

	closeAlertConfigModal() {
		this.modalRef.hide();

		setTimeout(() => {
			this.modalRef = null;
			this.resetAlertConfigForm();
		}, 500);
	}

	resetAlertConfigForm() {
		this.alertConfig = JSON.parse(JSON.stringify(this.backupAlertConfig));
	}

	handleAlertTriggerChange(checked: boolean, trigger: any) {
		if (checked) {
			if (this.alertConfig.alertTriggers.findIndex(t => t.id === trigger.id) === -1) {
				this.alertConfig.alertTriggers.push({ name: trigger.name, id: trigger.id });
			}
		} else {
			const triggerIndex: number = this.alertConfig.alertTriggers.findIndex(t => t.id === trigger.id);
			if (triggerIndex > -1) {
				this.alertConfig.alertTriggers.splice(triggerIndex, 1);
			}
		}
	}

	handleAlertFrequencyChange(freq: any) {
		this.alertConfig.alertFrequency = { name: freq.name, id: freq.id };
	}

	handleAlertDayChange(checked: boolean, day: any) {
		if (checked) {
			if (this.alertConfig.alertDays.findIndex(d => d.id === day.id) === -1) {
				this.alertConfig.alertDays.push({ name: day.name, id: day.id });
			}
		} else {
			const dayIndex: number = this.alertConfig.alertDays.findIndex(d => d.id === day.id);
			if (dayIndex > -1) {
				this.alertConfig.alertDays.splice(dayIndex, 1);
			}
		}
	}

	handleAlertTimeChange(time: string) {
		const hours: number = parseInt(time.split(':')[0]);
		const minutes: number = parseInt(time.split(':')[1]);
		let d = new Date();
		d.setHours(hours, minutes, 0, 0);
		this.alertConfig.alertTimeUTC = `${d.getUTCHours().toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${d.getUTCMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 })}`;
	}

	// GET
	getAlertConfig(viewPath: string) {
		this.mfilesService.getAlertConfig(viewPath).subscribe((response: AlertConfig) => {
			if (response !== null) {
				this.alertConfigExists = true;
				this.alertConfig = response;
				this.alertConfig.alertTime = this.convertUTCTimetoLocalTime(this.alertConfig.alertTimeUTC);
				this.backupAlertConfig = JSON.parse(JSON.stringify(response));
			}
		});
	}

	// CREATE
	createAlertConfig() {
		this.loading = true;

		// Validate the form
		var { alertTriggerSelected, alertTimeSet, alertDaySet } = this.validateForm();

		// Create the alert config
		if (alertTriggerSelected && alertTimeSet && alertDaySet) {
			this.mfilesService.createAlertConfig(this.alertConfig, this.item, location.href).subscribe({
				next: (response: AlertConfig) => {
					if (response !== null) {
						this.alertConfigExists = true;
						this.alertConfig = response;
						this.alertConfig.alertTime = this.convertUTCTimetoLocalTime(this.alertConfig.alertTimeUTC);
						this.backupAlertConfig = JSON.parse(JSON.stringify(response));
					}
					this.loading = false;
					this.closeAlertConfigModal();
				},
				error: (err) => {
					this.loading = false;
					console.error(err);
					this.closeAlertConfigModal();
				}
			});
		} else {
			this.loading = false;
		}
	}

	// UPDATE
	updateAlertConfig() {
		this.loading = true;

		// Validate the form
		var { alertTriggerSelected, alertTimeSet, alertDaySet } = this.validateForm();

		// Create the alert config
		if (alertTriggerSelected && alertTimeSet && alertDaySet) {
			this.mfilesService.updateAlertConfig(this.alertConfig, this.item).subscribe({
				next: (response: AlertConfig) => {
					if (response !== null) {
						this.alertConfig = response;
						this.alertConfig.alertTime = this.convertUTCTimetoLocalTime(this.alertConfig.alertTimeUTC);
						this.backupAlertConfig = JSON.parse(JSON.stringify(response));
					}
					this.loading = false;
					this.closeAlertConfigModal();
				},
				error: (err) => {
					this.loading = false;
					console.error(err);
					this.closeAlertConfigModal();
				}
			});
		} else {
			this.loading = false;
		}
	}

	// DELETE
	deleteAlertConfig() {
		this.loading = true;

		this.mfilesService.deleteAlertConfig(this.alertConfig.mfilesId).subscribe({
			next: () => {
				this.loading = false;
				this.closeAlertConfigModal();

				setTimeout(() => {
					this.alertConfigExists = false;
					this.alertConfig = new AlertConfig();
					this.backupAlertConfig = new AlertConfig();
				}, 500);
			},
			error: (err) => {
				this.loading = false;
				console.error(err);
				this.closeAlertConfigModal();
			}
		});
	}

	private validateForm() {
		const alertTriggerSelected = this.alertConfig.alertTriggers.length > 0;
		let alertTimeSet = true;
		let alertDaySet = true;

		if (this.alertConfig.alertFrequency.name === '' && this.alertConfig.alertFrequency.id === 0) {
			const defaultFreq = this.alertFrequencies.find(f => f.name === 'Immediately');
			this.alertConfig.alertFrequency = { name: defaultFreq.name, id: defaultFreq.id };
		}

		if ((this.alertConfig.alertFrequency.name === 'Daily' || this.alertConfig.alertFrequency.name === 'Weekly') && this.alertConfig.alertTime === '') {
			alertTimeSet = false;
		}

		if (this.alertConfig.alertFrequency.name === 'Weekly' && this.alertConfig.alertDays.length === 0) {
			alertDaySet = false;
		}

		return { alertTriggerSelected, alertTimeSet, alertDaySet };
	}

	configNotValid() {
		var { alertTriggerSelected, alertTimeSet, alertDaySet } = this.validateForm();
		return !(alertTriggerSelected && alertTimeSet && alertDaySet);
	}

	configHasTrigger(trigger: any) {
		return this.alertConfig.alertTriggers.findIndex(t => t.id === trigger.id) > -1
	}

	configHasFrequency(freq: any) {
		return this.alertConfig.alertFrequency.id === freq.id;
	}

	configHasDay(day: any) {
		return this.alertConfig.alertDays.findIndex(d => d.id === day.id) > -1;
	}

	convertUTCTimetoLocalTime(utc: string) {
		const hours: number = parseInt(utc.split(':')[0]);
		const minutes: number = parseInt(utc.split(':')[1]);
		let d = new Date();
		d.setUTCHours(hours, minutes, 0, 0);
		return `${d.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${d.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 })}`;
	}
}
